import StepOneToggleVisual from "./step-one-toggle-visual/StepOneToggleVisual"
import StepTwoTestimonialVisual from "./step-two-testimonial-visual/StepTwoTestimonialVisual"
import StepThreeListingsVisual from "./step-three-listings-visual/StepThreeListingsVisual"

const StepVisuals = ({ stepNumber, loadingStyles }) => {
  return (
    <div
      className={loadingStyles + ' flex justify-center items-center bg-[#F7F6F2] tablet-onboarding:hidden'}
    >
      {stepNumber === 1 && (
        <StepOneToggleVisual/>
      )}
      {stepNumber === 2 && (
        <StepTwoTestimonialVisual/>
      )}
      {stepNumber === 3 && (
        <StepThreeListingsVisual/>
      )}
    </div>
  )
}

export default StepVisuals
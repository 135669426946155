import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MapboxMap from './mapbox-map/MapboxMap';
import MobileMapBackButton from './mobile-map-back-button/MobileMapBackButton';
import MapViewButton from './map-view-button/MapViewButton';
import ClaimHomeButton from './claim-home-button/ClaimHomeButton';
import SubmitOfferButton from './submit-offer-button/SubmitOfferButton';
import UserAddressInput from '../../components/customComponents/userAddressInput/UserAddressInput';
import ShareButton from './share-button/ShareButton';
import ValuationChart from '../../components/widgets/valuationChart/ValuationChart';
import { usePropertyData } from '../../context/propertyDataContext';
import { useNavigate } from 'react-router-dom';
import fetchPropertyId from '../../utils/api/fetchPropertyId';
import fetchPropertyValue from '../../utils/api/fetchPropertyValue';
import addPropertyToPropertyTable from '../../utils/api/addPropertyToPropertyTable';
import createPropertyValue from '../../utils/api/createPropertyValue';

const PropertyView = () => {
  const [address, setAddress] = useState<string | undefined>('')
  const [propertyValue, setPropertyValue] = useState(0)
  const [isMobileMapActive, setIsMobileMapActive] = useState(false)
  const { propertyLat, propertyLong } = usePropertyData()
  const activeMapStyles = isMobileMapActive ? 'tablet-property-view:opacity-1 tablet-property-view:pointer-events-auto' : 'tablet-property-view:opacity-0'

  const location = useLocation();
  const navigate = useNavigate()

  const handleSearchClick = (address: string | undefined) => {
    if (address && propertyLat && propertyLong) {
      navigate(`/property-view?address=${encodeURIComponent(address)}&lat=${propertyLat}&lng=${propertyLong}`)
      navigate(0) // TEMPORARY - whole page refresh
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const addressParam = searchParams.get('address');

    if (addressParam) {
      setAddress(decodeURIComponent(addressParam));
    }
  }, [location.search, address]);

  useEffect(() => {
    if (address && address !== '') {
      // TODO: refactor
      const callFetchFunctions = async (address) => {
        const propertyId = await fetchPropertyId(address)

        if (propertyId) {
          const propertyValueObject = await fetchPropertyValue(propertyId)
          console.log(propertyValueObject)
          // check if estimated value exists
          if (propertyValueObject.estimatedvalue) {
            console.log('estimated value exists: ', propertyValueObject.estimatedvalue)
            setPropertyValue(propertyValueObject.estimatedvalue)
            return
          }

          // check if AVM value exists
          if (propertyValueObject.automatedvaluationmodelvalue) {
            console.log('avm value exists: ', propertyValueObject.automatedvaluationmodelvalue)
            setPropertyValue(propertyValueObject.automatedvaluationmodelvalue)
            return
          }

          // check if tax assessed value exists
          if (propertyValueObject.taxassessedvalue) {
            console.log('tax assessed value exists: ', propertyValueObject.taxassessedvalue)
            setPropertyValue(propertyValueObject.taxassessedvalue)
            return
          }

          /*
            If no property value exists:
            1. Ping the create_property/{property_id} <-- this pings reapi to add a property to the Property table
            2. Ping the create_property_value/{property_id} <-- this pings reapi to add a property value to the PropertyValue table
            3. Use fetchPropertyValue again to get the newly created property value
          */
          await addPropertyToPropertyTable(propertyId)
          const newPropertyData = await createPropertyValue(propertyId)
          const propertyValueData = await fetchPropertyValue(propertyId)

          console.log(propertyValueData)
          // TODO: refactor
          // check if estimated value exists
          if (newPropertyData.estimatedvalue) {
            console.log('estimated value exists: ', propertyValueObject.estimatedvalue)
            setPropertyValue(propertyValueObject.estimatedvalue)
            return
          }

          // check if AVM value exists
          if (newPropertyData.automatedvaluationmodelvalue) {
            console.log('avm value exists: ', propertyValueObject.automatedvaluationmodelvalue)
            setPropertyValue(propertyValueObject.automatedvaluationmodelvalue)
            return
          }

          // check if tax assessed value exists
          if (newPropertyData.taxassessedvalue) {
            console.log('tax assessed value exists: ', propertyValueObject.taxassessedvalue)
            setPropertyValue(propertyValueObject.taxassessedvalue)
            return
          }          
        } else console.log('property id not found for inputted address')
      }
      callFetchFunctions(address)
    }
  }, [address])

  return (
    <div className="relative grid grid-cols-2 gap-[57px] w-full my-[-50px]
      tablet-property-view:grid-cols-1 tablet-property-view:mb-0"
    >
      {/* my-[-50px] to cancel out HomeLayout's "my-[50px]" */}

      <div className="flex flex-col pt-[60px] pl-[45px] pb-[100px]
        tablet-property-view:px-4 tablet-property-view:pt-[15px] tablet-property-view:pb-6"
      >
        <div className="flex items-end gap-[15px] w-full
          tablet-property-view:grid tablet-property-view:grid-rows-[(1fr,_1fr)] tablet-property-view:gap-3"
        >
          <UserAddressInput
            searchButtonExists={true}
            address={address}
            setAddress={setAddress}
            searchButtonClickHandler={handleSearchClick}
            height={'68px'}
          />
          <div className="h-[68px]
            tablet-property-view:grid tablet-property-view:grid-cols-2 tablet-property-view:gap-4 tablet-property-view:h-max"
          >
            <MapViewButton setIsMobileMapActive={setIsMobileMapActive}/>
            <ShareButton address={address}/>
          </div>
        </div>
        <div className="flex-auto mt-[22px] mb-[20px]
          tablet-property-view:mt-4"
        >
          <ValuationChart
            rawPropertyValue={propertyValue}
          />
        </div>
        <div className="grid grid-cols-2 gap-[15px]
          tablet-property-view:grid-cols-1"
        >
          <ClaimHomeButton
            address={address}
            setAddress={setAddress}
          />
          <SubmitOfferButton address={address}/>
        </div>
      </div>

      <div className={activeMapStyles + " tablet-property-view:absolute tablet-property-view:top-0 tablet-property-view:z-50 tablet-property-view:w-full tablet-property-view:h-full tablet-property-view:pointer-events-none"}>
        <MobileMapBackButton
          isMobileMapActive={isMobileMapActive}
          setIsMobileMapActive={setIsMobileMapActive}
        />
        <MapboxMap />
      </div>

    </div>
  );
};

export default PropertyView;

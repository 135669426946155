import ContinueButton from "../continue-button/ContinueButton"
import BackButton from "../back-button/BackButton"
import CalendlyEmbed from "../calendly-embed/CalendlyEmbed"
import ProgressBar from "../progress-bar/ProgressBar"

const Content = ({
  stepNumber,
  handleContinueClick,
  handleBackButtonClick
}) => {
  return (
    <div className="py-[85px] pl-[64px] pr-[52px] h-full
      mobile-dashboard:px-6 mobile-dashboard:pt-[60px] mobile-dashboard:pb-[30px]"
    >

      <p className="text-[28px] text-[#6B7773] leading-[120%] mb-1
        mobile-dashboard:text-[20px]">
        {stepNumber === 1 && <>Locked</>}
        {stepNumber === 2 && <>Let's get started</>}
      </p>

      <p className="font-roboto text-[32px] text-pending-green font-bold leading-8
        mobile-dashboard:text-[24px] mobile-dashboard:leading-[24px]">
        {stepNumber === 1 && <>Finish Onboarding</>}
        {stepNumber === 2 && <>Here's how to finalize your registration</>}
      </p>

      {stepNumber === 1 && (
        <div className="flex flex-col gap-5 text-[#6B7773] mt-6 mb-10 font-lato leading-[120%]
          mobile-dashboard:text-[14px] mobile-dashboard:max-h-[221px] mobile-dashboard:overflow-y-auto mobile-dashboard:mt-4"
        >
          <p>Pending is a private property marketplace. To safeguard homeowners' privacy, onboarding is mandatory for full property listings.</p>
          <p>Onboarding includes a brief chat with our concierge team and a qualification assessment. Full property listings include photos, floor plans and a complete property description.</p>
          <p>Our service excels at understanding buyer preferences, enabling us to curate unparalleled real estate opportunities tailored to your specific requirements.</p>
        </div>
      )}

      {stepNumber === 2 && (
        <div className="flex max-h-[292px] mt-10 mb-[87px]
          mobile-dashboard:flex-col mobile-dashboard:gap-[23px] mobile-dashboard:overflow-y-auto mobile-dashboard:mt-[22px] mobile-dashboard:mb-[21px]"
        >
          <div className="flex flex-col gap-4 max-w-[321px] h-max font-lato text-white bg-[#3A6056] rounded-[8px] p-6
            mobile-dashboard:max-w-full"
          >
            <p className="font-bold text-[18px]">
              Schedule a call with us
            </p>
            <p className="tracking-tight leading-[120%]">
              Our concierge team will reach out for a quick chat and qualification assessment and get you on the way to viewing your future potential opportunities.
            </p>
          </div>
          <div className='hidden mobile-dashboard:block'>
            <CalendlyEmbed
              minWidth={0}
            />
          </div>
        </div>
      )}

      {/* BUTTONS */}
      <div>

        {stepNumber === 1 && (
          <div className='mobile-dashboard:flex mobile-dashboard:flex-col mobile-dashboard:items-center'>
            <ContinueButton
              handleContinueClick={handleContinueClick}
            />
          </div>
        )}

        {stepNumber === 2 && (
          <BackButton
            stepNumber={stepNumber}
            handleBackButtonClick={handleBackButtonClick}
          />
        )}
      </div>

      {/* PROGRESS INDICATOR */}
      <div className='hidden mobile-dashboard:block mobile-dashboard:mt-6'>
        <ProgressBar stepNumber={stepNumber}/>
      </div>
    </div>
  )
}

export default Content
import { useState, useEffect } from 'react';
import CodeInputs from './code-inputs/CodeInputs';
import ErrorMessage from './error-message/ErrorMessage';
import VerifyButton from './verify-button/VerifyButton';
// import SendCodeButtons from './send-code-button/SendCodeButtons';
import ResendCodeButton from './resend-code-button/ResendCodeButton';
import { useSignUp } from '@clerk/clerk-react';

import { User } from '../RegistrationContent';

interface VerificationContentProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  hasCodeSent: boolean;
  setHasCodeSent: React.Dispatch<React.SetStateAction<boolean>>;
}

const VerificationContent = ({
  user,
  setUser,
  hasCodeSent,
  setHasCodeSent,
}: VerificationContentProps) => {
  const [userInputtedCode, setUserInputtedCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [userCode, setUserCode] = useState('');
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
  // const [isValidResponse, setIsValidResponse] = useState<boolean | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { signUp } = useSignUp();

  const SMS_INPUT_ID_ONE = 'sms-num-1';
  const SMS_INPUT_ID_TWO = 'sms-num-2';
  const SMS_INPUT_ID_THREE = 'sms-num-3';
  const SMS_INPUT_ID_FOUR = 'sms-num-4';
  const SMS_INPUT_ID_FIVE = 'sms-num-5';
  const SMS_INPUT_ID_SIX = 'sms-num-6';

  const checkAllInputsPopulated = () => !userInputtedCode.includes('');

  const enableVerifyButton = () => setIsVerifyButtonDisabled(false);

  const disableVerifyButton = () => setIsVerifyButtonDisabled(true);

  const focusNextInput = (e) => {
    // Focus next input if current value is not empty string
    if (e.target.value !== '') {
      if (e.target.id === SMS_INPUT_ID_ONE) {
        const secondInput = document.getElementById(SMS_INPUT_ID_TWO);
        secondInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_TWO) {
        const thirdInput = document.getElementById(SMS_INPUT_ID_THREE);
        thirdInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_THREE) {
        const fourthInput = document.getElementById(SMS_INPUT_ID_FOUR);
        fourthInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_FOUR) {
        const fifthInput = document.getElementById(SMS_INPUT_ID_FIVE);
        fifthInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_FIVE) {
        const sixthInput = document.getElementById(SMS_INPUT_ID_SIX);
        sixthInput?.focus();
      }
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target && e.target.id && (e.target.value || e.target.value === '')) {
      // SMS set code 1
      if (e.target.id === SMS_INPUT_ID_ONE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[0] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 2
      if (e.target.id === SMS_INPUT_ID_TWO) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[1] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 3
      if (e.target.id === SMS_INPUT_ID_THREE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[2] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 4
      if (e.target.id === SMS_INPUT_ID_FOUR) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[3] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 5
      if (e.target.id === SMS_INPUT_ID_FIVE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[4] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 6
      if (e.target.id === SMS_INPUT_ID_SIX) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[5] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }
    }
  };

  const resendCode = async () => {
    try {
      await signUp?.create({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
      });

      await signUp?.preparePhoneNumberVerification();

      setUser((prevUser) => ({
        ...prevUser,
        isUserSignedUp: true,
      }));
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      if (err.status === 422) alert(err.errors[0].message);
    }
  };

  // Checks if all inputs are populated - if so, enable the verify button
  useEffect(() => {
    const isTrue = checkAllInputsPopulated();
    if (isTrue) enableVerifyButton();
    else disableVerifyButton();
  }, [userInputtedCode]);

  // If response is valid, navigate user to next screen - else display error UI
  // useEffect(() => {
  //   if (isValidResponse) {
  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       isUserVerified: true,
  //     }));
  //   }
  //   if (isValidResponse === false) setHasError(true);
  // }, [isValidResponse]);

  // Concatenate userInputtedCode array into a single string
  useEffect(() => {
    setUserCode(userInputtedCode.join(''));
  }, [userInputtedCode]);

  return (
    <div>
      <CodeInputs
        error={hasError}
        userInputtedCode={userInputtedCode}
        setUserInputtedCode={setUserInputtedCode}
        onChangeHandler={onChangeHandler}
        SMS_INPUT_ID_ONE={SMS_INPUT_ID_ONE}
        SMS_INPUT_ID_TWO={SMS_INPUT_ID_TWO}
        SMS_INPUT_ID_THREE={SMS_INPUT_ID_THREE}
        SMS_INPUT_ID_FOUR={SMS_INPUT_ID_FOUR}
        SMS_INPUT_ID_FIVE={SMS_INPUT_ID_FIVE}
        SMS_INPUT_ID_SIX={SMS_INPUT_ID_SIX}
      />
      <div className="my-[14px]">
        <ErrorMessage error={hasError} />
      </div>
      <div className="grid grid-rows-2 gap-[21px] max-w-[288px]">
        <VerifyButton
          userCode={userCode}
          isVerifyButtonDisabled={isVerifyButtonDisabled}
          loading={isLoading}
          setLoading={setIsLoading}
          setHasError={setHasError}
          user={user}
          setUser={setUser}
        />
        <ResendCodeButton resendCode={resendCode} />
        {/* TODO: bring back if allowing user to select either mail or sms for code delivery*/}
        {/* {!hasCodeSent ? (
          <SendCodeButtons
            user={user}
            setUser={setUser}
            setHasCodeSent={setHasCodeSent}
          />
        ) : (
          <>
            <VerifyButton
              userCode={userCode}
              isVerifyButtonDisabled={isVerifyButtonDisabled}
              loading={isLoading}
              setLoading={setIsLoading}
              // setIsValidResponse={setIsValidResponse}
              setHasError={setHasError}
              user={user}
              setUser={setUser}
            />
            <ResendCodeButton resendCode={resendCode} />
          </>
        )} */}
      </div>
    </div>
  );
};

export default VerificationContent;

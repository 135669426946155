import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useLoading from '../../../hooks/useLoading';
import StepContent from './step-content/StepContent';
import StepVisuals from './step-visuals/StepVisuals';
import { useUserData } from '../../../context/UserDataProvider';

const HomebuyerView = ({ setUserOnboarded }) => {
  const { loadingStyles, firstLoadStyles, setLoading } = useLoading();
  const navigate = useNavigate();

  // Shared states between all steps
  const [stepNumber, setStepNumber] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Step 1 state
  const [stepOneOption, setStepOneOption] = useState<string | null>(null);

  // Step 2 state
  const [stepTwoOption, setStepTwoOption] = useState<string | null>(null);

  // Step 3 state
  const [stepThreeOption, setStepThreeOption] = useState<string | null>(null);

  const { setUserObject } = useUserData();

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (stepNumber === 1) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }
    if (stepNumber === 2) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }
    // TODO: navigate to dashboard after dashboard has been created
    if (stepNumber === 3) {
      setUserOnboarded(true);
      setUserObject((prev) => ({ ...prev, isOnboarded: true }));
    }
  };

  const toggleSubmitButtonDisable = () => {
    // Step 1 validation
    if (stepNumber === 1) {
      // Ensure an option has been chosen
      if (stepOneOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    // Step 2 validation
    if (stepNumber === 2) {
      // Ensure an option has been chosen
      if (stepTwoOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    if (stepNumber === 3) {
      // Ensure an option has been chosen
      if (stepThreeOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    toggleSubmitButtonDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneOption, stepTwoOption, stepThreeOption]);

  return (
    <>
      <StepContent
        stepNumber={stepNumber}
        stepOneOption={stepOneOption}
        setStepOneOption={setStepOneOption}
        stepTwoOption={stepTwoOption}
        setStepTwoOption={setStepTwoOption}
        stepThreeOption={stepThreeOption}
        setStepThreeOption={setStepThreeOption}
        isButtonDisabled={isButtonDisabled}
        handleButtonClick={handleButtonClick}
        loadingStyles={loadingStyles}
        firstLoadStyles={firstLoadStyles}
      />
      <StepVisuals loadingStyles={loadingStyles} stepNumber={stepNumber} />
    </>
  );
};

export default HomebuyerView;

import { Link } from 'react-router-dom';
import { useState } from 'react';
import SelectionCards from './selection-cards/SelectionCards';
import { useUserData } from '../../../context/UserDataProvider';
import updateUser from '../../../utils/api/updateUser';

interface FormattedUser {
  clerkuserid: string | null;
  userid: number | null;
  emailaddress: string | null;
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
  role: 'buyer' | 'seller' | 'buy_sell' | null;
  sessionid: string | null;
}

interface SelectionOptionsProps {
  HOMEOWNER: string;
  BUYER: string;
  BUY_AND_SELL: string;
  setUserType: React.Dispatch<React.SetStateAction<string | null>>;
}

const SelectionOptions = ({
  HOMEOWNER,
  BUYER,
  BUY_AND_SELL,
  setUserType,
}: SelectionOptionsProps) => {
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { userObject, setUserObject } = useUserData();

  const formattedUser: FormattedUser = {
    clerkuserid: userObject.clerkUserId,
    userid: userObject.userId,
    emailaddress: userObject.emailAddress,
    firstname: userObject.firstName,
    lastname: userObject.lastName,
    phonenumber: userObject.phoneNumber,
    role: userObject.role,
    sessionid: userObject.sessionId,
  };

  const buttonColor = isButtonDisabled ? 'bg-[#1a3a3280]' : 'bg-[#1A3A32]';
  const disabledCursorStyles = isButtonDisabled ? ' cursor-not-allowed' : '';

  function assignRole(selectCard) {
    let role: 'seller' | 'buyer' | 'buy_sell' | null;

    if (selectCard === HOMEOWNER) {
      role = 'seller';
    } else if (selectCard === BUYER) {
      role = 'buyer';
    } else if (selectCard === BUY_AND_SELL) {
      role = 'buy_sell';
    } else {
      // Default role if selectCard does not match any expected values
      role = null;
    }
    formattedUser.role = role;
    return role;
  }

  const handleContinueClick = () => {
    const role = assignRole(selectedCard);
    setUserType(selectedCard);
    setUserObject((prev) => ({ ...prev, role }));
    updateUser(formattedUser);
  };

  return (
    <div className="flex flex-col gap-[60px] items-center text-pending-green text-[2rem] font-lato font-bold min-h-[800px] pt-10 px-6
      tablet-selection:gap-6 border"
    >
      <h1 className="text-center text-[32px] mobile-selection:text-[20px]">
        Make a selection
      </h1>
      <div className="flex w-full justify-center">
        <div
          className={
            'grid grid-cols-[repeat(3,_1fr)] gap-6 max-w-[1167px] ' +
            'tablet-selection:grid-cols-[minmax(0px,_489px)] tablet-selection:grid-rows-[repeat(3,_minmax(0px,_114px))] tablet-selection:gap-4 ' +
            'mobile-selection:grid-cols-[minmax(0px,_303px)] mobile-selection:grid-rows-[repeat(3,_minmax(0px,_94px))]'
          }
        >
          <SelectionCards
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            setIsButtonDisabled={setIsButtonDisabled}
            HOMEOWNER={HOMEOWNER}
            BUYER={BUYER}
            BUY_AND_SELL={BUY_AND_SELL}
          />
        </div>
      </div>

      {/* CONTINUE AND CANCEL BUTTONS */}
      <div className="flex flex-col items-center min-w-0 max-w-[311px] w-full tablet-selection:max-w-[489px] mobile-selection:max-w-[303px]">
        <div className="flex w-full">
          <button
            className={
              buttonColor +
              disabledCursorStyles +
              ' flex-auto text-base text-white py-[14px] rounded-lg drop-shadow-[2px_2px_6px_rgba(34,36,39,0.19)]'
            }
            disabled={isButtonDisabled}
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
        <Link to="/" className="flex min-w-0 max-w-[311px] w-full">
          <div className="flex-auto text-center text-base py-[16px] rounded-lg">
            Cancel
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SelectionOptions;

const getTodaysDate = () => {
  const now = new Date();

  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayOfWeek = daysOfWeek[now.getDay()];
  const month = now.toLocaleString('default', { month: 'long' });
  const date = now.getDate(); // Get day of the month (local time)

  return `${dayOfWeek}, ${month} ${date}`;
};

export default getTodaysDate;

import PendingLogo from '../../../components/customComponents/pendingLogo/PendingLogo';
import LogInContent from './login-content/LogInContent';

const SignInView = () => {
  return (
    <div className="flex flex-col gap-[121px] p-[85px] h-full
      mobile-signup:min-h-screen mobile-signup:w-full mobile-signup:items-center mobile-signup:px-4 mobile-signup:py-6 mobile-signup:gap-12"
    >
      <div className="mobile-signup:mr-auto">
        <PendingLogo
          variant='lg'
        />
      </div>
      <LogInContent />
    </div>
  );
};

export default SignInView;

import { useState } from 'react';
import HomeownerView from './homeowner-view/HomeownerView';
import HomebuyerView from './homebuyer-view/HomebuyerView';
import BuyAndSellView from './buyandsell-view/BuyAndSellView';
import SelectionOptions from './selection-options/SelectionOptions';
//TODO: extract Heading components to be used here, on signUp and login
import Heading from '../sign-up/registration-view/registration-content/heading/Heading';
import SubHeading from '../sign-up/registration-view/registration-content/subheading/SubHeading';
import AccountCreatedContent from './account-created-view/AccountCreatedContent';
import ImageDisplay from '../sign-up/image-display/ImageDisplay';
import PendingLogo from '../../components/customComponents/pendingLogo/PendingLogo';

import { useUserData } from '../../context/UserDataProvider';
import { useUser } from '@clerk/clerk-react';

const HOMEOWNER = 'Homeowner';
const BUYER = 'Buyer';
const BUY_AND_SELL = 'Buy & Sell';

const ACCOUNT_CREATED_SUBHEADING =
  ' Your account has been created successfully. Pending is the first online real estate platform dedicated to privacy, building a 100% vetted marketplace that delivers trust at scale.';

const Onboarding = () => {
  const [isUserAuthenticated, setUserAuthenticated] = useState(true); // Temporary auth solution for development until actual auth implemented
  const [userType, setUserType] = useState<string | null>(null); // Temporary until homebuyer view + auth are implemented
  const [userOnboarded, setUserOnboarded] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  return (
    <>
      {isUserAuthenticated && userType === null && (
        <SelectionOptions
          HOMEOWNER={HOMEOWNER}
          BUYER={BUYER}
          BUY_AND_SELL={BUY_AND_SELL}
          setUserType={setUserType}
        />
      )}
      {isUserAuthenticated && userType && !userOnboarded && (
        <div className="grid grid-cols-2 min-h-screen w-screen tablet-onboarding:flex tablet-onboarding:justify-center">
          {userType === HOMEOWNER && (
            <HomeownerView setUserOnboarded={setUserOnboarded} />
          )}
          {userType === BUYER && (
            <HomebuyerView setUserOnboarded={setUserOnboarded} />
          )}
          {userType === BUY_AND_SELL && (
            <BuyAndSellView setUserOnboarded={setUserOnboarded} />
          )}
        </div>
      )}
      {/* TODO: abstract components */}
      {userOnboarded && (
        <div className="SPLITSCREENLAYOUT grid grid-cols-2 min-h-screen w-screen tablet-onboarding:flex tablet-onboarding:justify-center">
          <div className="SIGNUP grid grid-cols-2 min-h-screen w-screen tablet-signup:flex">
            <div className="flex flex-col gap-[121px] p-[85px] h-full tablet-signup:min-h-screen tablet-signup:w-[39rem] tablet-signup:items-center mobile-signup:px-[34px] mobile-signup:gap-[68px]">
              <div className="tablet-signup:mr-auto">
                <PendingLogo />
              </div>
              <div>
                <Heading>{'Account Created'}</Heading>
                <SubHeading>
                  <div className="max-w-[486px]">
                    {ACCOUNT_CREATED_SUBHEADING}
                  </div>
                </SubHeading>
                <SubHeading>
                  <div className="max-w-[486px] my-6">
                    {'Press continue to begin using Pending'}
                  </div>
                </SubHeading>
                <AccountCreatedContent
                  setIsAccountCreated={setIsAccountCreated}
                />
              </div>
            </div>
            <ImageDisplay />
          </div>
        </div>
      )}
    </>
  );
};

export default Onboarding;

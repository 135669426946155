import DashboardToggle from './dashboard-toggle/DashboardToggle';
import getTodaysDate from '../../../../utils/getTodaysDate';
import HamburgerMenuIcon from '../../../customComponents/hamburger-menu-icon/HamburgerMenuIcon';
import PendingLogo from '../../../customComponents/pendingLogo/PendingLogo';
import { useUserData } from '../../../../context/UserDataProvider';
import { useEffect, useState } from 'react';
import { SignOutButton, useUser } from '@clerk/clerk-react';

const Header = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [userInitials, setUserInitials] = useState<string>('');
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const todaysDate = getTodaysDate();
  const { userObject } = useUserData();
  const { user } = useUser();

  const borderBottomMobileNavStyles = 
    isMobileNavMenuOpen 
      ? ' mobile-dashboard:border-b-none'
      : ''

  // Sets user initials
  useEffect(() => {
    if (userObject.firstName) setFirstName(userObject.firstName);
    if (userObject.firstName && userObject.lastName) {
      const initials =
        userObject.firstName.charAt(0) + userObject.lastName.charAt(0);
      setUserInitials(initials);
    }
  }, [userObject]);

  return (
    <header
      className="flex justify-between items-center py-[26px] px-[64px] gap-4
      mobile-dashboard:flex-col mobile-dashboard:py-0 mobile-dashboard:px-0 mobile-dashboard:gap-0 mobile-dashboard:pb-6"
    >
      <div className="flex mobile-dashboard:w-full mobile-dashboard:flex-col">
        <div className={borderBottomMobileNavStyles + " mobile-dashboard:z-50 mobile-dashboard:bg-[#FFFCF9] mobile-dashboard:fixed mobile-dashboard:w-full mobile-dashboard:flex mobile-dashboard:justify-between mobile-dashboard:pl-4 mobile-dashboard:pr-6 mobile-dashboard:py-[22px]"}>
          <PendingLogo url='/dashboard' />
          <div className="flex relative z-[100]">
            <button
              className="hidden mobile-dashboard:block"
              onClick={() => setIsMobileNavMenuOpen((prev) => !prev)}
            >
              <HamburgerMenuIcon isMobileNavMenuOpen={isMobileNavMenuOpen} />
            </button>
          </div>
        </div>
        <div className="font-lato ml-[54px] flex flex-col gap-3
          mobile-dashboard:mx-4 mobile-dashboard:mb-[21px] mobile-dashboard:mt-[110px]"
        >
          <p className="font-semibold text-[24px] leading-[22px]">
            Hello, {firstName}
          </p>
          <p className="font-[600] text-[#6B7773]">{todaysDate}</p>
        </div>
      </div>
      <div
        className="flex h-[54px] w-full max-w-[327px]
        mobile-dashboard:px-4 mobile-dashboard:max-w-full mobile-dashboard:justify-center"
      >
        <DashboardToggle />
      </div>
      <div className="flex h-[48px] mobile-dashboard:hidden">
        <SignOutButton>
          <button className="bg-[#1A3A32] px-8 rounded-[30px] font-jakarta-sans font-semibold text-[16px] text-white hover:bg-[#242424] transition-all whitespace-nowrap mobile-navbar:hidden">
            Sign Out
          </button>
        </SignOutButton>
      </div>

      {isMobileNavMenuOpen && (
        <nav className="hidden fixed top-0 left-0 w-full h-full bg-[#FFFCF9] p-8 pt-[74px] z-40 pointer-events-auto
          mobile-dashboard:block"
        >
          <ul className="flex flex-col items-center h-full">
            <li className="w-full mt-auto">
              <SignOutButton>
                <div className="w-screen h-[52px]">
                  <button className="flex justify-center items-center mx-auto font-lato font-semibold max-h-[52px] max-w-[240px] w-full h-full bg-white border border-black rounded-[8px]">
                    Log Out
                  </button>
                </div>
              </SignOutButton>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;

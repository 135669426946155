import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxMarkerSVG from '../../../assets/icons/mapboxMarker.svg'

const MapboxMap = () => {
  const [latitude, setLatitude] = useState<number | null>(null)
  const [longitude, setLongitude] = useState<number | null>(null)
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const latParam = searchParams.get('lat') 
    const longParam = searchParams.get('lng')

    if (latParam && longParam) {
      const latNum = parseFloat(latParam)
      const longNum = parseFloat(longParam)
      setLatitude(latNum)
      setLongitude(longNum)
    }
  }, [location.search]);

  return (
    <>
    {(latitude && longitude) && (
      <Map
        mapboxAccessToken={`${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
        style={{
          width: '100%',
          height: '100%',
        }}
        initialViewState={{
          longitude: longitude ?? undefined,
          latitude: latitude ?? undefined,
          zoom: 13
        }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapLib={import('mapbox-gl')}
      >
        {(latitude && longitude) && (
          <Marker
            longitude={longitude}
            latitude={latitude}
          >
          <div className='w-14'>
          <img src={MapboxMarkerSVG} alt=''/>
          </div>
          </Marker>
        )}
      </Map>
      )}
    </>
  )
}

export default MapboxMap
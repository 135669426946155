import { useEffect, useState } from 'react'
import blurredChart from '../../../../assets/images/valuationChartBlurred.png'
import { useClaimHomeModalError } from '../../../../context/ClaimHomeModalErrorProvider'

const BlurredEmptyState = () => {
  const [currentPage, setCurrentPage] = useState<string | null>(null)
  const { setOpen } = useClaimHomeModalError()
  /*
    TEMPORARY until auth is integrated - checks path:
    - If path is /dashboard = set currentPage state to 'dashboard'
    - If path is /property-view = set currentPage state to 'propertyView
  */
  useEffect(() => {
    if (window.location.pathname) {
      setCurrentPage(window.location.pathname)
    }
  }, [])

  const handleAddAddressClick = () => setOpen(true)

  return (
    <div className='relative h-full overflow-hidden min-h-[275px]'>
      <img
        className='absolute h-full w-full'
        src={blurredChart}
        alt=''
      />
      <div className='relative z-10 font-jakarta-sans flex flex-col gap-5 justify-center items-center h-full
        mobile-valuation-chart:gap-2'
      >
          {currentPage === '/property-view' && (
            <>
              <p className='font-semibold text-[20px] mx-4 text-center
                mobile-valuation-chart:text-[16px]'
              >
                We're having trouble pricing that address
                <br/>
                We may need some additional information
              </p>
              <a
                className='text-[#596780] underline underline-offset-[3.4px] decoration-1 cursor-pointer
                mobile-valuation-chart:text-[14px]'
                href='mailto:hello@trypending.com'
              >
                Contact Us
              </a>
            </>
          )}
          {currentPage === '/dashboard' && (
            <>
              <p className='font-semibold text-[26px]'>
                See real-time home value
              </p>
              <p
                className='text-[#596780] underline underline-offset-[3.4px] decoration-1 cursor-pointer'
                onClick={handleAddAddressClick}
              >
                Add your home
              </p>
            </>
          )}
      </div>

    </div>
  )
}

export default BlurredEmptyState
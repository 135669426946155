import { useState } from "react"

interface StepTwoOptionsProps {
  stepTwoOption: string | null
  setStepTwoOption: React.Dispatch<React.SetStateAction<string | null>>
  loadingStyles: string
}

const StepTwoOptions = ({
  stepTwoOption,
  setStepTwoOption,
  loadingStyles
} : StepTwoOptionsProps) => {
  const [otherLocation, setOtherLocation] = useState('')

  const HAMPTONS_NY = 'Hamptons, NY'
  const PALM_BEACH_FL = 'Palm Beach, FL'
  const LOS_ANGELES_CA = 'Los Angeles, CA'
  const MIAMI_FL = 'Miami, FL'

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value
    if (userInput) {
      setOtherLocation(userInput)
      setStepTwoOption(userInput)
    }
    if (userInput === '') {
      setOtherLocation('')
      setStepTwoOption(null)
    }
  }

  return (
    <div className={loadingStyles + ' text-pending-green font-lato max-w-[388px] grid grid-cols-2 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 mt-[18px] mb-10'}>
      <div>
        <button
          className={`${stepTwoOption === HAMPTONS_NY && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepTwoOption(HAMPTONS_NY)
            setOtherLocation('')
          }}
        >
          {HAMPTONS_NY}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === PALM_BEACH_FL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepTwoOption(PALM_BEACH_FL)
            setOtherLocation('')
          }}
        >
          {PALM_BEACH_FL}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === LOS_ANGELES_CA && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepTwoOption(LOS_ANGELES_CA)
            setOtherLocation('')
          }}
        >
          {LOS_ANGELES_CA}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === MIAMI_FL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepTwoOption(MIAMI_FL)
            setOtherLocation('')
          }}
        >
          {MIAMI_FL}
        </button>
      </div>
      <div>
        <input
          className="text-[#979797] font-light italic py-2 h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all placeholder:text-[#979797]"
          type="text"
          placeholder="Other (enter)"
          onFocus={otherLocation === '' ? () => setStepTwoOption(null) : undefined}
          onChange={handleOnChange}
          value={otherLocation}
        />
      </div>
    </div>
  )
}

export default StepTwoOptions
import personImageThree from '../../../../assets/images/onboardingPersonImage3.png'
import personImageFour from '../../../../assets/images/onboardingPersonImage4.png'
import StepOneOptions from './step-one-options/StepOneOptions'
import StepTwoForm from './step-two-form/StepTwoForm'
import NextButton from './next-button/NextButton'
import ProgressBar from './progress-bar/ProgressBar'
import PendingLogo from '../../../../components/customComponents/pendingLogo/PendingLogo'

const StepContent = ({
  stepNumber,
  stepOneOption,
  setStepOneOption,
  address,
  setAddress,
  toggleSubmitButtonDisable,
  handleButtonClick,
  isButtonDisabled,
  loadingStyles,
  firstLoadStyles
}) => {
  return (
    <div className={firstLoadStyles + ' text-pending-green flex flex-col gap-[91px] p-[85px] h-full tablet-onboarding:min-h-screen tablet-onboarding:w-[39rem] tablet-onboarding:items-center mobile-onboarding:px-[27px]'}>

        {/* LOGO */}
        <div className='tablet-onboarding:hidden'>
          <PendingLogo />
        </div>

        <div>
          {/* PERSON ICON */}
          <div className={loadingStyles + ' flex max-w-[103px] max-h-[103px] mb-4'}>
            {stepNumber === 1 && <img src={personImageThree} alt='Adult woman smiling with a light beige background'/>}
            {stepNumber === 2 && <img src={personImageFour} alt='Middle-aged man smile with a dark tan background'/>}
          </div>

          {/* HEADING */}
          <h1 className={loadingStyles + ' font-lato font-bold text-[36px] leading-[140%] tracking-[-1px] max-w-[461px] mb-1 mobile-onboarding:text-[32px]'}>
            {stepNumber === 1 && <>When do you hope to sell?</>}
            {stepNumber === 2 && <>What's the address of the home you may want to sell?</>}
          </h1>
                    
          {/* STEP 1: "When do you hope to sell?" button options */}
          {stepNumber === 1 && (
            <StepOneOptions
              stepOneOption={stepOneOption}
              setStepOneOption={setStepOneOption}
              toggleSubmitButtonDisable={toggleSubmitButtonDisable}
              loadingStyles={loadingStyles}
            />
          )}
          {/* STEP 2: Confirm your details */}
          {stepNumber === 2 && (
            <StepTwoForm
              toggleSubmitButtonDisable={toggleSubmitButtonDisable}
              loadingStyles={loadingStyles}
              address={address}
              setAddress={setAddress}
            />
          )}
          <div className='flex justify-between gap-2 max-w-[448px]'>
            <NextButton
              handleButtonClick={handleButtonClick}
              isButtonDisabled={isButtonDisabled}
            />
            <ProgressBar stepNumber={stepNumber}/>
          </div>
        </div>

      </div>
  )
}

export default StepContent
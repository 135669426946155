
const addPropertyToPropertyTable = async (propertyId: number) => {
  try {
    const response = await fetch(`https://pending-backend-1.onrender.com/create_property/${Number(propertyId)}`, { method: 'POST' })
    const data = await response.json()
    console.log(data)
    console.log('successfully added property to Property table!')
  } catch (err) {
    console.log(err)
  }
}

export default addPropertyToPropertyTable
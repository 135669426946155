const HeroSection = () => {
  return (
    <div className="px-16 pt-[142px] pb-[94px] bg-[#FFFCF9]
      mobile-how-it-works:px-5 mobile-how-it-works:pt-[80px] mobile-how-it-works:pb-[54px]"
    >
      <h1 className="leading-[94px] mb-[29px]
        mobile-how-it-works:leading-[58px] mobile-how-it-works:mb-[25px]"
      >
        <span className="block text-[#222222] text-[95px]
          mobile-how-it-works:text-[56px]"
        >
          Off-Market
        </span>
        <span className="block text-[#096E21] text-[95px]
          mobile-how-it-works:text-[56px]"
        >
          On Demand
        </span>
      </h1>
      <p className="text-[34px] text-[#474747] max-w-[668px]
        mobile-how-it-works:text-[22px]"
      >
        Understand asset values and explore private transactions. Professionally organized, secured, and instantly accessible.
      </p>
    </div>
  )
}

export default HeroSection
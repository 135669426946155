import Header from './header/Header';
import { Outlet } from 'react-router';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn } = useUser();
  
  useEffect(() => {
    if (!isSignedIn && isLoaded) navigate('/');
    //TODO: might want to include retrieving user from DB here
  }, [isSignedIn, isLoaded, navigate]);

  if (!isSignedIn || !isLoaded) return <div className="min-h-screen"></div>

  return (
    <div className="flex flex-col min-h-screen min-w-screen bg-[#FFFCF9]">
      <Header />
      <main className="flex flex-auto">
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;

interface HomeValuePropProps {
  propTitle: string
  propDescription: string
  setShowHomeValue?: React.Dispatch<React.SetStateAction<boolean>>
  showHomeValue?: boolean
}

const HomeValueProp = ({
  propTitle,
  propDescription,
  setShowHomeValue,
  showHomeValue
} : HomeValuePropProps) => {
  const stateExists = (showHomeValue === true || showHomeValue === false) ? true : false

  return (
    <div
      onClick={setShowHomeValue ? () => setShowHomeValue(true) : undefined}
      className={stateExists ? (showHomeValue ? 'activeProp' : 'inactiveProp') : undefined}
    >
      <h2 className='text-[#1A3A32] text-2xl font-semibold font-jakarta-sans tracking-[-0.48px]
        mobile-dashboard:text-xl'
      >
        {propTitle}
      </h2>
      <p className='text-[#6B7773] font-jakarta-sans tracking-[-0.32px]
        mobile-dashboard:text-[14px]'
      >
        {propDescription}
      </p>
    </div>
  )
}

export default HomeValueProp
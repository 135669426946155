interface NextButtonProps {
  handleButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isButtonDisabled: boolean
}

const NextButton = ({ handleButtonClick, isButtonDisabled } : NextButtonProps) => {
  const buttonColor = isButtonDisabled ? 'bg-opacity-50' : ''
  
  return (
    <div className='flex max-w-[125px] h-[52px]'>
      <button
        className={buttonColor + ' bg-pending-green flex-auto px-[44px] py-[14px] text-white rounded-[8px] transition-all'}
        onClick={(e) => handleButtonClick(e)}
        disabled={isButtonDisabled}
      >
        Next
      </button>
    </div>
  )
}

export default NextButton
const HamburgerMenuIcon = ({ isMobileNavMenuOpen }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={
          isMobileNavMenuOpen
            ? 'transition-transform ease-in-out transform rotate-45 translate-x-2'
            : 'transition-transform transform ease-in-out'
        }
        d="M4,8 h16"
        stroke="#1A3A32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        className={
          isMobileNavMenuOpen
            ? 'transition-transform ease-in-out transform -rotate-45 translate-y-[11px] -translate-x-[9px]'
            : 'transition-transform transform ease-in-out'
        }
        d="M4,16 h16"
        stroke="#1A3A32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default HamburgerMenuIcon
import { useEffect, useRef, useState } from 'react';
import { AddressAutofill } from '@mapbox/search-js-react';
import { usePropertyData } from '../../../context/propertyDataContext';

interface UserAddressInputProps {
  address?: string | undefined;
  setAddress?: React.Dispatch<React.SetStateAction<string | undefined>>;
  placeholderText?: string;
  searchButtonExists?: boolean;
  height?: string;
  searchButtonClickHandler?: (address: string | undefined) => void | undefined;
  onFocusHandler?: () => void;
  onBlurHandler?: () => void;
  isAddressAutofillOn?: boolean;
}

const UserAddressInput = ({
  address = '',
  setAddress,
  placeholderText = 'Enter any address',
  searchButtonExists = false,
  height = '60px',
  searchButtonClickHandler = undefined,
  onFocusHandler = undefined,
  onBlurHandler = undefined,
  isAddressAutofillOn = true,
}: UserAddressInputProps) => {
  const [tempAddress, setTempAddress] = useState('');
  const addressBoxRef = useRef<HTMLDivElement>(null);
  const { setPropertyLat, setPropertyLong } = usePropertyData();

  const additionalInputStyles = searchButtonExists
    ? ''
    : undefined;

  // On first render, populate address input if address exists
  useEffect(() => {
    if (address && address !== '') setTempAddress(address);
  }, [address]);

  return (
    <div
      ref={addressBoxRef}
      className="mt-3 h-[40rem] w-full"
      style={{
        height: height,
      }}
    >
      <form
        className="relative flex text-[#000000] text-[18px] font-jakarta-sans rounded-lg h-full min-w-[230px] border border-[#222222] focus-within:border-blue-500 focus-within:border focus-within:outline focus-within:outline-blue-500"
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(tempAddress);
          if (searchButtonClickHandler && setAddress && tempAddress) {
            setAddress(tempAddress);
            searchButtonClickHandler(tempAddress);
          }
        }}
      >
        <div className='absolute left-[15px] flex items-center h-full'>
          <LocationIcon/>
        </div>
        {/* ---COMMENTED OUT TO PREVENT ACCRUING MAPBOX API CHARGES--- */}
        {/* Need to enforce rule to only accept MapBox AutoFilled addresses*/}

        {isAddressAutofillOn && (
          <AddressAutofill
            accessToken={`${process.env.REACT_APP_MAPBOX_SEARCH_ACCESS_TOKEN}`}
            popoverOptions={{
              offset: 5,
            }}
            onRetrieve={(res) => {
              const inputAddress = res?.features[0]?.properties?.full_address;
              const addressLong = res.features[0].geometry.coordinates[0];
              const addressLat = res.features[0].geometry.coordinates[1];
              // console.log(inputAddress);
              // console.log(addressLat, addressLong)

              // If search button is present, update internal temp address state (for "/" and "/property-view")
              if (searchButtonExists) {
                if (inputAddress) setTempAddress?.(inputAddress);
                if (addressLat && addressLong) {
                  setPropertyLat(addressLat);
                  setPropertyLong(addressLong);
                }
              }

              // If search button is not present, update address props that is passed in (for /onboarding screens only)
              else {
                if (inputAddress) setAddress?.(inputAddress);
              }
            }}
            options={{
              country: 'us',
              language: 'en',
              streets: false,
            }}
          >
            <input
              className={`flex w-full font-jakarta-sans bg-[#FFFCF9] placeholder-[#6B7773] placeholder:text-[17px] pl-12 pr-4 py-[26px] rounded-[10px] text-[16px] tracking-[-0.32px] focus:outline-none ${additionalInputStyles}`}
              required
              name="hidden"
              type="text"
              placeholder={placeholderText}
              autoComplete="off" //toggling autoComplete to off allows for entire address to populate input
              value={searchButtonExists ? tempAddress : address}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (searchButtonExists) {
                  if (setTempAddress) {
                    setTempAddress(e.target.value);
                    return;
                  }
                }

                if (!searchButtonExists) {
                  if (setAddress) setAddress(e.target.value);
                }
              }}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
            />
          </AddressAutofill>
        )}

        {!isAddressAutofillOn && (
          <input
            className={`font-jakarta-sans bg-[#FFFCF9] placeholder-[#6B7773] px-5 py-[18px] border border-[#A7A7A7] rounded-[10px] text-[16px] tracking-[-0.32px] ${additionalInputStyles}`}
            required
            name="hidden"
            type="text"
            placeholder={placeholderText}
            autoComplete="off" //toggling autoComplete to off allows for entire address to populate input
            value={tempAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // TODO: add similar onChange handler here like above when autofill is on
              if (setTempAddress) setTempAddress(e.target.value);
            }}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
        )}

        {searchButtonExists && (
          <button className="relative right-[3px] top-[3px] flex justify-center font-jakarta-sans font-bold bg-pending-green text-white items-center h-[62px] px-[20px] border-[3px] border-[#DEE1DE] rounded-[6px] max-w-[149px] w-full">
            Find Home
          </button>
        )}
      </form>
    </div>
  );
};

const LocationIcon = () => {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9732 29.0279C14.0566 27.891 23.0927 22.424 23.0927 13.1257C23.0927 6.19908 17.9241 0.583984 11.5483 0.583984C5.17251 0.583984 0.00390625 6.19908 0.00390625 13.1257C0.00390625 22.424 9.04 27.891 11.1234 29.0279C11.3929 29.175 11.7037 29.175 11.9732 29.0279ZM11.5483 18.5007C14.2808 18.5007 16.4959 16.0942 16.4959 13.1257C16.4959 10.1571 14.2808 7.75065 11.5483 7.75065C8.81581 7.75065 6.6007 10.1571 6.6007 13.1257C6.6007 16.0942 8.81581 18.5007 11.5483 18.5007Z" fill="#1A3A32"/>
    </svg>
  )
}

export default UserAddressInput;

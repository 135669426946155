import { useEffect, useState } from "react"

// ******************************* //
// Purpose of custom hook: To prevent repaint flickering from conditionally rendering components
// ******************************* //
const useLoading = () => {
  const [loading, setLoading] = useState(true) // Use this state for conditionally rendering components after the first load. 
  const [firstLoad, setFirstLoad] = useState(false) // Use this state to prevent component from flickering on the first load

  const firstLoadStyles = !firstLoad ? 'invisible' : ''
  const loadingStyles = loading ? 'invisible' : '' // Tailwind loading styles

  // 100ms delay to change loading state
  useEffect(() => {
    if (loading) {
      const loadingTimeout = setTimeout(() => {
        setLoading(false)
      }, 150)

      return () => clearTimeout(loadingTimeout)
    }
  }, [loading])

  // 100ms delay on changing first load state
  useEffect(() => {
    if (!firstLoad) {
      const firstLoadTimeout = setTimeout(() => {
        setFirstLoad(true)
      }, 150)

      return () => clearTimeout(firstLoadTimeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loadingStyles,
    firstLoadStyles,
    loading,
    setLoading
  }
}

export default useLoading
const SelectCarets = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 9.00098L11.9716 5.00023L8.00011 9.00098" stroke="#A2A5AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 14.333L12.0284 18.3338L15.9999 14.333" stroke="#A2A5AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

interface ChosenOptionContainerProps {
  selectMenuClickHandler: () => void
  currentAddress?: string
  currentLocation?: string | null
  ariaLabel: string
  isProperties: boolean
}

const ChosenOptionContainer = ({
  selectMenuClickHandler,
  currentAddress,
  currentLocation,
  ariaLabel,
  isProperties
} : ChosenOptionContainerProps) => {
  return (
    <div
      className="flex items-center justify-between max-w-[210px] min-w-0 w-full py-[11px] pl-[9px] pr-[7px] rounded-[8px] border border-[#E0E0E0] border-b-2"
      //ex. "Your Homes" section, aria-labelledby = "your-homes-label" (NOTE: must match DashboardMenuLabel complement)
      aria-labelledby={ariaLabel} // <DashboardMenuLabel /> label tag connects back to this div - makes it accessible since we're not using a form control
      onClick={selectMenuClickHandler}
    >
      <span className="selectedHome h-max text-[#474747] truncate pointer-events-none">
        {isProperties && 
          <>
            {currentAddress !== '' && currentAddress}
            {currentAddress === '' && 'Add address'}
          </>
        }
        {!isProperties && currentLocation}
      </span>
      <div className="pointer-events-none">
        <SelectCarets/>
      </div>
    </div>
  )
}

export default ChosenOptionContainer
const ENDPOINT = 'https://pending-backend-1.onrender.com/user/';

interface FormattedUser {
  clerkuserid: string | null;
  emailaddress: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  role: 'buyer' | 'seller' | 'buy_sell' | null;
  sessionid: string | null;
}

const createUser = async (formattedUser: FormattedUser) => {
  try {
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(formattedUser),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    //TODO: handle unique responses
    //post can fail without causing an error...response.ok === false
    console.log(response.status);
    console.log(response.ok);
    const json = await response.json();
    console.log('BE user created: ', json);
    return json;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export default createUser;

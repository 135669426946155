import { useState } from "react"
import greenBulletPoint from '../../assets/icons/greenBulletPoint.svg'
import redBulletPoint from '../../assets/icons/redBulletPoint.svg'

const ToggleVisual = ({
  // Default text is for homeowner
  withPendingText = 'Pending lets off-market sellers explore transactions with qualified buyers, privately, using the power of data and automation.',
  withoutPendingText = 'Traditional listings and open houses are full of challenges.',
  withPendingBulletPoints = {
    pointOne: 'Test the market',
    pointTwo: 'Privacy controls',
    pointThree: 'Only qualified buyers'
  },
  withoutPendingBulletPoints = {
    pointOne: 'No privacy',
    pointTwo: 'Impromptu showings',
    pointThree: 'Higher fees'
  }
}) => {
  
  // Toggle states
  const WITH_PENDING = 'With Pending'
  const WITHOUT_PENDING = 'Without Pending'
  const [currentToggleView, setCurrentToggleView] = useState<'With Pending' | 'Without Pending'>(WITH_PENDING)

  // Toggle component view
  const toggleViewOnClick = (): void => {
    if (currentToggleView === WITH_PENDING) {
      setCurrentToggleView(WITHOUT_PENDING)
      return
    }
    if (currentToggleView === WITHOUT_PENDING) {
      setCurrentToggleView(WITH_PENDING)
      return
    }
  }

  return (
    <div className='flex flex-col items-center font-lato text-[#596780] text-[20px] w-full h-full max-w-[540px] max-h-[405px] bg-white rounded-[25px]'>
      <label
        className="relative inline-block w-[351px] h-[54px] mt-[31px] cursor-pointer"
        onChange={toggleViewOnClick}
      >
        <input type="checkbox" className='toggleWithPendingInput hidden'/>
        <span className="slider round"></span>
        <div className='absolute flex justify-between items-center top-0 w-[351px] h-[54px] text-[#474747] font-bold text-[16px]'>
          <span className={`${currentToggleView === WITH_PENDING && 'text-black'} font-jakarta-sans px-[36px]`}>With Pending</span>
          <span className={`${currentToggleView === WITHOUT_PENDING && 'text-black'} font-jakarta-sans pr-[26px]`}>Without Pending</span>
        </div>
      </label>

      {currentToggleView === WITH_PENDING && (
        <>
          <div className='pl-[56px] pr-[84px] pt-[24px] pb-[20px]'>
            <p>{withPendingText}</p>
          </div>
          
          <div className='flex flex-col gap-[21px] w-full px-[42px]'>
            <div className='flex gap-[22px]'>
              <img src={greenBulletPoint} alt=''/>
              <p>{withPendingBulletPoints.pointOne}</p>
            </div>
            <div className='flex gap-[22px]'>
              <img src={greenBulletPoint} alt=''/>
              <p>{withPendingBulletPoints.pointTwo}</p>
            </div>
            <div className='flex gap-[22px]'>
              <img src={greenBulletPoint} alt=''/>
              <p>{withPendingBulletPoints.pointThree}</p>
            </div>
          </div>
        </>
      )}

      {currentToggleView === WITHOUT_PENDING && (
        <>
          <div className='pl-[56px] pr-[84px] pt-[24px] pb-[20px]'>
            <p>{withoutPendingText}</p>
          </div>
          
          <div className='flex flex-col gap-[21px] w-full px-[42px]'>
            <div className='flex gap-[22px]'>
              <img src={redBulletPoint} alt=''/>
              <p>{withoutPendingBulletPoints.pointOne}</p>
            </div>
            <div className='flex gap-[22px]'>
              <img src={redBulletPoint} alt=''/>
              <p>{withoutPendingBulletPoints.pointTwo}</p>
            </div>
            <div className='flex gap-[22px]'>
              <img src={redBulletPoint} alt=''/>
              <p>{withoutPendingBulletPoints.pointThree}</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ToggleVisual
import StepOneOptions from './step-one-options/StepOneOptions'
import StepTwoOptions from './step-two-options/StepTwoOptions'
import StepThreeOptions from './step-three-options/StepThreeOptions'
import NextButton from './next-button/NextButton'
import ProgressBar from './progress-bar/ProgressBar'
import personImageThree from '../../../../assets/images/onboardingPersonImage3.png'
import personImageFour from '../../../../assets/images/onboardingPersonImage4.png'
import personImageFive from '../../../../assets/images/onboardingPersonImage5.png'
import PendingLogo from "../../../../components/customComponents/pendingLogo/PendingLogo"

const StepContent = ({
  stepNumber,
  stepOneOption,
  setStepOneOption,
  stepTwoOption,
  setStepTwoOption,
  stepThreeOption,
  setStepThreeOption,
  isButtonDisabled,
  handleButtonClick,
  loadingStyles,
  firstLoadStyles
}) => {

  return (
    <div className={firstLoadStyles + ' flex flex-col gap-[91px] p-[85px] h-full tablet-onboarding:min-h-screen tablet-onboarding:w-[39rem] tablet-onboarding:items-center mobile-onboarding:px-[27px]'}>
      
      {/* LOGO */}
      <div className='min-w-[126px] min-h-[34px] tablet-onboarding:hidden'>
        <PendingLogo />
      </div>

      <div>
        {/* PERSON ICON */}
        <div className={loadingStyles + ' flex min-w-[103px] min-h-[103px] max-w-[103px] max-h-[103px] mb-4'}>
          {stepNumber === 1 && <img src={personImageThree} alt='Adult woman smiling with a light beige background'/>}
          {stepNumber === 2 && <img src={personImageFour} alt='Middle-aged man smile with a dark tan background'/>}
          {stepNumber === 3 && <img src={personImageFive} alt='Older man in a suit smiling with a light gray background' />}
        </div>

        {/* HEADING */}
        <h1 className={loadingStyles + ' text-pending-green font-lato font-bold text-[36px] leading-[140%] tracking-[-1px] mb-1 mobile-onboarding:text-[32px]'}>
          {stepNumber === 1 && <>Where are you looking?</>}
          {stepNumber === 2 && <>When are you looking to buy?</>}
          {stepNumber === 3 && <>What's your price range?</>}
        </h1>

        {/* STEP 1: "Where are you looking?" button options */}
        {stepNumber === 1 && (
          <StepOneOptions
            stepOneOption={stepOneOption}
            setStepOneOption={setStepOneOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 2: "When are you looking to buy?" button options */}
        {stepNumber === 2 && (
          <StepTwoOptions
            stepTwoOption={stepTwoOption}
            setStepTwoOption={setStepTwoOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 3: "What's your price range" button options */}
        {stepNumber === 3 && (
          <StepThreeOptions
            stepThreeOption={stepThreeOption}
            setStepThreeOption={setStepThreeOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* NEXT BUTTON + PROGRESS BAR */}
        <div className='flex justify-between gap-2 max-w-[448px]'>
          <NextButton
            handleButtonClick={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
          />
          <ProgressBar stepNumber={stepNumber}/>
        </div> 
      </div> 
    </div>
  )
}

export default StepContent
import homeownerSVG from '../../../../assets/images/selectionHomeowner.svg'
import buyerSVG from '../../../../assets/images/selectionBuyer.svg'
import buyAndSellSVG from '../../../../assets/images/selectionBuyAndSell.svg'
import homeownerSelectedSVG from '../../../../assets/images/selectionSelectedHomeowner.svg'
import buyerSelectedSVG from '../../../../assets/images/selectionSelectedBuyer.svg'
import buyAndSellSelectedSVG from '../../../../assets/images/selectionSelectedBuyAndSell.svg'
import { useEffect, useState } from 'react';

interface SelectionCardsProps {
  selectedCard: string | null
  setSelectedCard: React.Dispatch<React.SetStateAction<string | null>>
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  HOMEOWNER: string
  BUYER: string
  BUY_AND_SELL: string
}

const SelectionCards = ({
  selectedCard,
  setSelectedCard,
  setIsButtonDisabled,
  HOMEOWNER,
  BUYER,
  BUY_AND_SELL
} : SelectionCardsProps) => {
  const [isResizing, setIsResizing] = useState(false)
  const [isTablet, setIsTablet] = useState<boolean | null>(null)
  const selectedCardStyles = isTablet ? 'bg-pending-green text-white border-b-[2px] border-b-[#0D2922]' : 'outline outline-2 outline-pending-green '

  // Resize handler
  useEffect(() => {
    const resizeHandler = () => {
      // Set resizing state - this prevents a transition flicker when resizing window
      let timer;
      setIsResizing(true);
      timer = setTimeout(() => {
        setIsResizing(false);
        clearTimeout(timer)
      }, 100);
      
      // Set state if screen size is tablet
      if (window.innerWidth <= 1000) setIsTablet(true)
      else setIsTablet(false)
    }
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  // On initial load - if tablet size, set isTablet state
  useEffect(() => {
    if (window.innerWidth <= 1000) setIsTablet(true)
  }, [])

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const selectionElement = e.target as HTMLElement  
    const paragraphElement = selectionElement?.querySelector('p') as HTMLParagraphElement
    const selectionType = paragraphElement?.textContent as string

    if (selectionType) {
      setIsButtonDisabled(false)
      if (selectionType === HOMEOWNER) {
        setSelectedCard(HOMEOWNER)
        return
      }
      if (selectionType === BUYER) {
        setSelectedCard(BUYER)
        return
      }
      if (selectionType === BUY_AND_SELL) {
        setSelectedCard(BUY_AND_SELL)
        return
      }
    }
  }

  return (
    <>
      {/* HOMEOWNER CARD */}
      <div
        style={{ transition: isResizing ? 'none' : '' }}
        className={(selectedCard === HOMEOWNER ? selectedCardStyles : 'outline-1 outline-[#D3D2D2]') +
        " flex flex-col max-w-[389px] min-w-0 w-full h-full bg-[#F1F0ED] outline rounded-lg overflow-hidden cursor-pointer ease-linear transition-all duration-75 " + 
        " tablet-selection:grid tablet-selection:grid-cols-2 tablet-selection:max-w-full tablet-selection:outline-none tablet-selection:border-b-[2px]"}
        onClick={(e) => handleCardClick(e)}
      >
        <div className={"flex max-h-[290px] min-h-0 h-full w-full pointer-events-none " +
          " tablet-selection:max-w-fit tablet-selection:max-h-[303px] tablet-selection:order-last"
        }>
          <img
            src={(selectedCard === HOMEOWNER && isTablet) ? homeownerSelectedSVG : homeownerSVG}
            alt="Abstract continuous line illustration of a luxury home"
            className={"object-cover max-h-[290px] min-h-0 h-full w-full pt-[100px] px-[21px] pb-[40px]" +
              " tablet-selection:max-h-[303px] tablet-selection:p-4 " +
              " mobile-selection:pt-6"
            }
          />
        </div>
        <div className={"flex justify-center items-center min-h-[94px] bg-pending-green text-white text-2xl py-7 " +
          " tablet-selection:text-base tablet-selection:bg-inherit tablet-selection:text-inherit tablet-selection:w-full tablet-selection:pl-2"}
        >
          <p className={'pointer-events-none ' +
            " tablet-selection:w-full tablet-selection:text-center tablet-selection:text-[20px] " +
            " mobile-selection:text-base"
          }>
            {HOMEOWNER}
          </p>
        </div>
      </div>

      {/* BUYER CARD */}
      <div
        style={{ transition: isResizing ? 'none' : '' }}
        className={(selectedCard === BUYER ? selectedCardStyles : 'outline-1 outline-[#D3D2D2]') +
        " flex flex-col justify-between max-w-[389px] min-w-0 w-full bg-[#F1F0ED] outline rounded-lg overflow-hidden cursor-pointer ease-linear transition-all duration-75 " + 
        " tablet-selection:grid tablet-selection:grid-cols-2 tablet-selection:max-w-full tablet-selection:outline-none tablet-selection:border-b-[2px] tablet-selection:h-full"}
        onClick={(e) => handleCardClick(e)}
      >
        <div className={"flex max-h-[290px] min-h-0 h-full w-full pointer-events-none " +
          " tablet-selection:max-w-fit tablet-selection:max-h-[303px] tablet-selection:order-last"
        }>
          <img
            src={(selectedCard === BUYER && isTablet) ? buyerSelectedSVG : buyerSVG}
            alt="Abstract continuous line illustration a couple looking at paperwork"
            className="object-cover max-h-[290px] min-h-0 h-full relative bottom-1 scale-[65%] tablet-selection:max-h-[303px] tablet-selection:scale-75"
          />
        </div>
        <div className={"flex justify-center items-center min-h-[94px] bg-pending-green text-white text-2xl py-7 " +
          " tablet-selection:text-base tablet-selection:bg-inherit tablet-selection:text-inherit tablet-selection:w-full tablet-selection:pl-2"}
        >
          <p className={'pointer-events-none ' +
            " tablet-selection:w-full tablet-selection:text-center tablet-selection:text-[20px] " +
            " mobile-selection:text-base"
          }>
            {BUYER}
          </p>
        </div>
      </div>

      {/* BUY & SELL CARD */}
      <div
        style={{ transition: isResizing ? 'none' : '' }}
        className={(selectedCard === BUY_AND_SELL ? selectedCardStyles : 'outline-1 outline-[#D3D2D2]') +
        " flex flex-col justify-between max-w-[389px] min-w-0 w-full bg-[#F1F0ED] outline rounded-lg overflow-hidden cursor-pointer ease-linear transition-all duration-75 " + 
        " tablet-selection:grid tablet-selection:grid-cols-2 tablet-selection:max-w-full tablet-selection:outline-none tablet-selection:border-b-[2px] tablet-selection:h-full"}
        onClick={(e) => handleCardClick(e)}
      >
        <div className={"flex max-h-[290px] min-h-0 h-full w-full pointer-events-none " +
          " tablet-selection:max-w-fit tablet-selection:max-h-[303px] tablet-selection:order-last"
        }>
          <img
            src={(selectedCard === BUY_AND_SELL && isTablet) ? buyAndSellSelectedSVG : buyAndSellSVG}
            alt="Abstract continuous line illustration of a hand passing a house to another hand holding a bag of money"
            className="object-cover max-h-[290px] min-h-0 h-full scale-[65%] tablet-selection:max-h-[303px] tablet-selection:scale-75"
          />
        </div>
        <div className={"flex justify-center items-center min-h-[94px] bg-pending-green text-white text-2xl py-7 " +
          " tablet-selection:text-base tablet-selection:bg-inherit tablet-selection:text-inherit tablet-selection:w-full tablet-selection:pl-2"}
        >
          <p className={'pointer-events-none ' +
            " tablet-selection:w-full tablet-selection:text-center tablet-selection:text-[20px] " +
            " mobile-selection:text-base"
          }>
            {BUY_AND_SELL}
          </p>
        </div>
      </div>
    </>
  )
}

export default SelectionCards
import StaticLoader from '../../../../../../components/customComponents/staticLoader/StaticLoader';
import { User } from '../../LogInContent';
import { useNavigate } from 'react-router-dom';
import { useSignIn } from '@clerk/clerk-react';

type UserInfo = Omit<User, 'fullName' | 'address'>;

interface VerifyButtonProps {
  userCode: string;
  isVerifyButtonDisabled: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserInfo;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const VerifyButton = ({
  userCode,
  isVerifyButtonDisabled,
  loading,
  setLoading,
  setHasError,
  user,
  setUser,
}: VerifyButtonProps) => {
  const { isLoaded, signIn, setActive } = useSignIn();

  const navigate = useNavigate();

  const VERIFY_TEXT = 'Verify';
  const VERIFY_LOADING_TEXT = 'Verifying...';

  const verifyCode = async () => {
    setLoading(true);
    if (!isLoaded) {
      return;
    }

    try {
      let result;
      //determine if login should be attempted through email or phone verification
      if (user.sendCodeViaSMS) {
        result = await signIn!.attemptFirstFactor({
          strategy: 'phone_code',
          code: userCode,
        });
      } else if (!user.sendCodeViaSMS) {
        result = await signIn!.attemptFirstFactor({
          strategy: 'email_code',
          code: userCode,
        });
      }

      if (result.status === 'complete') {
        //TODO: migrate to context
        setUser((prevUser) => ({
          ...prevUser,
          isUserVerified: true,
        }));
        await setActive({ session: signIn.createdSessionId });
        navigate('/dashboard');
      }
    } catch (err: any) {
      console.error(JSON.stringify(err));
      setLoading(false);
      setHasError(true);
      return;
    }
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      await verifyCode();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button
      className="flex justify-center align-center gap-[5px] text-white font-jakarta-sans font-semibold bg-[#1A3A32] rounded-lg py-[14px] disabled:opacity-50"
      onClick={handleClick}
      disabled={isVerifyButtonDisabled}
    >
      {loading && (
        <>
          <span className="h-full">
            <StaticLoader />
          </span>
          <span>{VERIFY_LOADING_TEXT}</span>
        </>
      )}
      {!loading && VERIFY_TEXT}
    </button>
  );
};

export default VerifyButton;

const LeftArrowIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.0852 15.1117C4.71736 15.48 4.51074 15.9793 4.51074 16.4998C4.51074 17.0204 4.71736 17.5196 5.0852 17.8879L12.4919 25.2985C12.8604 25.667 13.3601 25.874 13.8813 25.874C14.4024 25.874 14.9022 25.667 15.2707 25.2985C15.6392 24.93 15.8462 24.4302 15.8462 23.9091C15.8462 23.388 15.6392 22.8882 15.2707 22.5197L11.2164 18.4641L26.3218 18.4641C26.8427 18.4641 27.3423 18.2572 27.7107 17.8888C28.0791 17.5204 28.286 17.0208 28.286 16.4998C28.286 15.9789 28.0791 15.4792 27.7107 15.1109C27.3423 14.7425 26.8427 14.5355 26.3218 14.5355L11.2164 14.5355L15.2707 10.4812C15.4531 10.2988 15.5979 10.0822 15.6966 9.84379C15.7954 9.60539 15.8462 9.34988 15.8462 9.09185C15.8462 8.83381 15.7954 8.5783 15.6966 8.33991C15.5979 8.10151 15.4531 7.8849 15.2707 7.70244C15.0882 7.51998 14.8716 7.37525 14.6332 7.2765C14.3948 7.17775 14.1393 7.12693 13.8813 7.12693C13.6232 7.12693 13.3677 7.17775 13.1293 7.2765C12.8909 7.37525 12.6743 7.51998 12.4919 7.70244L5.0852 15.1117Z" fill="white"/>
    </svg>
  )
}

const RightArrowIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.9158 17.8883C28.2836 17.52 28.4902 17.0207 28.4902 16.5002C28.4902 15.9796 28.2836 15.4804 27.9158 15.1121L20.5091 7.70149C20.1406 7.33299 19.6408 7.12598 19.1197 7.12598C18.5986 7.12598 18.0988 7.33299 17.7303 7.70149C17.3618 8.06998 17.1548 8.56976 17.1548 9.09089C17.1548 9.61202 17.3618 10.1118 17.7303 10.4803L21.7846 14.5359L6.67923 14.5359C6.15826 14.5359 5.65864 14.7428 5.29027 15.1112C4.92189 15.4796 4.71494 15.9792 4.71494 16.5002C4.71494 17.0211 4.92189 17.5208 5.29027 17.8891C5.65864 18.2575 6.15826 18.4645 6.67923 18.4645L21.7846 18.4645L17.7303 22.5187C17.5478 22.7012 17.4031 22.9178 17.3044 23.1562C17.2056 23.3946 17.1548 23.6501 17.1548 23.9082C17.1548 24.1662 17.2056 24.4217 17.3044 24.6601C17.4031 24.8985 17.5478 25.1151 17.7303 25.2976C17.9128 25.48 18.1294 25.6248 18.3678 25.7235C18.6062 25.8222 18.8617 25.8731 19.1197 25.8731C19.3777 25.8731 19.6332 25.8222 19.8716 25.7235C20.11 25.6248 20.3266 25.48 20.5091 25.2976L27.9158 17.8883Z" fill="white"/>
    </svg>
  )
}

const CarouselButtons = ({
  currentCarouselIndex,
  activeProperties,
  leftCarouselButtonClickHandler,
  rightCarouselButtonClickHandler
}) => {
  const hiddenLeftButtonStyles = currentCarouselIndex === 0 ? "invisible" : ""
  const hiddenRightButtonStyles = currentCarouselIndex === activeProperties.length - 1 ? "invisible" : ""

  return (
    <div className="absolute left-0 bottom-[45%] flex justify-between w-full pointer-events-none">

      {/* LEFT BUTTON */}
      <div
        className={hiddenLeftButtonStyles + " relative z-20 flex justify-center items-center w-[55px] h-[55px] bg-pending-green rounded-full ml-2 cursor-pointer pointer-events-auto"}
        onClick={leftCarouselButtonClickHandler}
      >
        <LeftArrowIcon />
      </div>

      {/* RIGHT BUTTON */}
      <div
        className={hiddenRightButtonStyles + " flex z-20 justify-center items-center w-[55px] h-[55px] bg-pending-green rounded-full mr-2 cursor-pointer pointer-events-auto"}
        onClick={rightCarouselButtonClickHandler}
      >
        <RightArrowIcon/>
      </div>
    </div>
  )
}

export default CarouselButtons
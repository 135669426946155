import DashboardMenuLabel from '../../common/dashboard-menu-label/DashboardMenuLabel';
import PropertiesCarousel from './properties-carousel/PropertiesCarousel';
import { useUserData } from '../../../../context/UserDataProvider';
import DashboardMenu from '../../common/dashboard-menu/DashboardMenu';

const DiscoverHomesSection = () => {
  const { userObject } = useUserData();

  // TODO: logic to keep homes locked/unlocked based on if user is verified today
  return (
    <div className="flex flex-col w-full max-w-[1400px] mt-[66px] h-full
      mobile-dashboard:mt-0"
    >
      <div className="w-full ml-[74px]
        mobile-dashboard:ml-0"
      >
        <div className="flex gap-[11px] h-max max-w-[400px] font-lato mobile-dashboard:px-4">
          <DashboardMenuLabel
            id='discover-homes-label'
            label='Discover Homes'
          >
            Discover Homes
          </DashboardMenuLabel>
          {(userObject.role === 'buy_sell' || userObject.role === 'buyer') && (
            <div className="relative w-full">
              <DashboardMenu isProperties={false} />
            </div>
          )}
        </div>
      </div>
      <PropertiesCarousel />
    </div>
  );
};

export default DiscoverHomesSection;

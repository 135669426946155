const Heading = ({ children }) => {
  return (
    <h1 className="font-lato text-[35px] text-pending-green leading-[140%] font-bold
      mobile-signup:text-[32px]"
    >
      {children}
    </h1>
  );
};

export default Heading;

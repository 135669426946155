import { useEffect, useState } from "react"
import HomeOptionsContainer from "./home-options-container/HomeOptionsContainer"
import LocationOptionsContainer from "./location-options-container/LocationOptionsContainer"
import ChosenOptionContainer from "./chosen-option-container/ChosenOptionContainer"

interface MenuPropertyObject {
  id: number
  fullAddress: string
  shortAddress: string
  propertyValue: null | number
}

interface DashboardMenuProps {
  isProperties: boolean
  userProperties?: MenuPropertyObject[] | []
  setCurrentPropertyValue?: React.Dispatch<React.SetStateAction<number>>
  setCurrentCompsAddress?: React.Dispatch<React.SetStateAction<string>>
}

/* MAIN COMPONENT */ 
const DashboardMenu = ({
  isProperties,
  userProperties,
  setCurrentPropertyValue,
  setCurrentCompsAddress
} : DashboardMenuProps) => {
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentLocation, setCurrentLocation] = useState<string | null>(null)

  const allAvailableLocations = [
    'The Hamptons',
  ];

  const selectMenuClickHandler = () => setIsSelectMenuOpen((prev) => !prev);

  /*
    Currently using 'allAvailableLocations' array as a temp solution.
    Update logic to fetch from API to get available locations when API is available

    useEffect logic: On initial render, set currentLocation state to first location from array
  */
    useEffect(() => {
      if (!currentLocation && allAvailableLocations) {
        setCurrentLocation(allAvailableLocations[0]);
      }
    }, []);

  /* 
    Listens for any change on local userProperties state:
    - updates current address and current property value
  */
  useEffect(() => {
    if (userProperties && userProperties.length > 0 && setCurrentPropertyValue) {
      // Set current address and property value
      setCurrentAddress(userProperties[0].shortAddress)
      setCurrentPropertyValue(userProperties[0].propertyValue ?? 0)
      if (setCurrentCompsAddress) setCurrentCompsAddress(userProperties[0].fullAddress)
    }
  }, [userProperties])

  console.log(userProperties)

  /* 
    IF HomeOptionsContainer is being used = side effects
    - If user clicks outside of select menu options container, close menu
    - If user clicks a menu option -- update chosen options container and valuation chart
  */
  useEffect(() => {
    const handleUserPropertyClick = (e: MouseEvent) => {
      const isElementAnOption = (e.target as HTMLElement)?.classList.contains(
        'homeOptionContainer'
      );

      if (isElementAnOption && e.target && userProperties && setCurrentPropertyValue) {
        const addressSelected = (e.target as HTMLElement).querySelector(
          '.homeOption span'
        );
        const parsedAddress = addressSelected?.textContent?.split(',')[0];
        const addAddressSelected = (e.target as HTMLElement).querySelector(
          '.addAddress span'
        );
        const addAddressText = addAddressSelected?.textContent;

        // If another address is selected, set currentAddress state
        if (addressSelected && parsedAddress) {
          let newPropertyValue = 0

          // Find property value of matching property
          for (let i = 0; i < userProperties.length; i++) {
            if (userProperties[i].shortAddress === parsedAddress) {
              newPropertyValue = userProperties[i].propertyValue ?? 0
              if (setCurrentCompsAddress) setCurrentCompsAddress(userProperties[i].fullAddress)
              break
            }
          }
          // Update chosen option container text and property value
          setCurrentPropertyValue(newPropertyValue)
          setCurrentAddress(parsedAddress);
          setIsSelectMenuOpen(false);
        }

        // If "Add address" is selected:
        // - open modal handled by HomeOptionsContainer component
        // - close select menu
        if (addAddressSelected && addAddressText === 'Add address') {
          setIsSelectMenuOpen(false);
        }
      } else {
        setIsSelectMenuOpen(false);
      }
    };

    const handleMarketLocationClick = (e: MouseEvent) => {
      const isElementAnOption = (e.target as HTMLElement)?.classList.contains(
        'locationOptionContainer'
      );

      if (isElementAnOption && e.target) {
        const locationSelected = (e.target as HTMLElement).querySelector(
          '.locationOption span'
        );
        const parsedLocation = locationSelected?.textContent?.split(',')[0];

        // If another address is selected, set currentAddress state
        if (parsedLocation) {
          setCurrentLocation(parsedLocation);
          setIsSelectMenuOpen(false);
        }
      } else {
        setIsSelectMenuOpen(false);
      }
    }
    const body = document.querySelector('body');

    if (isProperties && body) {
      isSelectMenuOpen && body?.addEventListener('click', handleUserPropertyClick);
      !isSelectMenuOpen && body?.removeEventListener('click', handleUserPropertyClick);
    }
    if (!isProperties && body) {
      isSelectMenuOpen && body?.addEventListener('click', handleMarketLocationClick)
      !isSelectMenuOpen && body?.removeEventListener('click', handleMarketLocationClick)
    }
    return () => {
      body?.removeEventListener('click', handleUserPropertyClick);
      body?.removeEventListener('click', handleMarketLocationClick)
    }
  }, [isSelectMenuOpen]);

  return (
    <>
      {isProperties && (
        <>
          <ChosenOptionContainer
            selectMenuClickHandler={selectMenuClickHandler}
            currentAddress={currentAddress}
            ariaLabel='your-homes-label'
            isProperties={isProperties}
          />
          <HomeOptionsContainer
            isSelectMenuOpen={isSelectMenuOpen}
            userProperties={userProperties}
          />
        </>
      )}
      {!isProperties && (
        <>
          <ChosenOptionContainer
            selectMenuClickHandler={selectMenuClickHandler}
            ariaLabel='discover-homes-label'
            currentLocation={currentLocation}
            isProperties={isProperties}
          />
          <LocationOptionsContainer
            isSelectMenuOpen={isSelectMenuOpen}
            allAvailableLocations={allAvailableLocations}
          />
        </>
      )}
    </>
  )
}

export default DashboardMenu
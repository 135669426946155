import { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import LogInView from './login-view/LogInView';
import ImageDisplay from './image-display/ImageDisplay';

import { useNavigate } from 'react-router-dom';

const LogIn = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
  });
  return (
    <div className="grid grid-cols-2 min-h-screen w-screen mobile-signup:flex">
      <LogInView />
      <ImageDisplay />
    </div>
  );
};

export default LogIn;

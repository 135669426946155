import { useEffect, useState } from 'react';
import TermsAndConditionsModal from './terms-and-conditions-modal/TermsAndConditionsModal';
import TermsAndConditionsAcknowledgement from './terms-and-conditions-acknowledgement/TermsAndConditionsAcknowledgement';
import { Link } from 'react-router-dom';
import { useUserData } from '../../../context/UserDataProvider';

//TODO: ADD TYPES
const AccountCreatedContent = ({ setIsAccountCreated }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { setUserObject } = useUserData();

  const handleClick = () => {
    setIsAccountCreated(true);
    setUserObject((prev) => ({ ...prev, accountCreated: true }));
    //TODO: set accountcreated prop on DB user to true...may not be needd
  };

  return (
    <div className="grid grid-rows-2 gap-[21px] max-w-[288px]">
      <button onClick={handleClick}>
        <Link
          className="flex justify-center align-center gap-[5px] text-white font-jakarta-sans font-semibold bg-[#1A3A32] rounded-lg py-[14px] disabled:opacity-50"
          to="/dashboard"
        >
          Continue
        </Link>
      </button>
      <TermsAndConditionsModal open={open} handleClose={handleClose} />
      <TermsAndConditionsAcknowledgement handleOpen={handleOpen} />
    </div>
  );
};

export default AccountCreatedContent;

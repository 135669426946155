import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import HowItWorks from './pages/how-it-works/HowItWorks';
import Contact from './pages/contact/Contact';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TermsOfUse from './pages/terms-of-use/TermsOfUse';
import HomeLayout from './components/layouts/home/HomeLayout';
import Onboarding from './pages/onboarding/Onboarding';
import PropertyView from './pages/property-view/PropertyView';
import LogIn from './pages/log-in/LogIn';
import SignUp from './pages/sign-up/SignUp';
import SplitScreenLayout from './components/layouts/split-screen/SplitScreenLayout';
import GlobalFooter from './components/layouts/global-footer/GlobalFooter';

import Dashboard from './pages/dashboard/Dashboard';
import DashboardLayout from './components/layouts/dashboard/DashboardLayout';

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation()
  const stringifiedLocationObject = JSON.stringify(location)

  // GA4 initialization
  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');
      console.log('GA4 initialized!')
    }
  }, [])

  // Tracks pageviews and paths
  useEffect(() => {
    if (ReactGA.isInitialized && location.pathname) {
      const path = location.pathname
      ReactGA.send({
        hitType: "pageview",
        page: path,
        title: path
      });
    }
  }, [stringifiedLocationObject])

  return (
    <Routes>
      <Route element={<GlobalFooter/>}>
        {/* TODO: Look-up Clerk SignedIn/SignedOut wrappers */}
        {/* ACCESSIBLE WHILE SIGNED OUT */}
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/property-view" element={<PropertyView />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-use" element={<TermsOfUse/>}/>
        </Route>

        {/* ACCESSIBLE WHILE SIGNED OUT */}
        <Route path="/login" element={<LogIn />} />
        <Route path="/sign-up" element={<SplitScreenLayout />}>
          <Route index element={<SignUp />} />
        </Route>

        {/* ACCESSIBLE ONLY WHILE SIGNED IN */}
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

import HeroSection from "./hero-section/HeroSection";
import Content from "./content/Content";

const About = () => {
  return (
    <div className='font-lato w-full'>
      <HeroSection />
      <Content />
    </div>
  )
};

export default About;

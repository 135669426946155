const faqsCopy = [
  {
    heading: 'What is Pending?',
    paragraph: 'Pending is a private asset management and brokerage platform for individuals and families with luxury homes. It was built for the new generation of high-net-worth individuals who are globally connected, and digitally-native.'
  },
  {
    heading: 'How does the closed marketplace work?',
    paragraph: 'Pending facilitates connections between homeowners who are contemplating selling and buyers who are struggling to find suitable properties on the traditional market. Each property listing is unique to Pending, meaning you won\'t encounter them through local agents or on platforms like Zillow. For off-market residences, buyers can request a viewing by submitting an indicative offer. In cases where owners aren\'t yet prepared to sell or arrange viewings, interested buyers can opt to join a waitlist, positioning themselves at the forefront for when the property becomes available for offers.'
  },
  {
    heading: 'How can buyers schedule a property tour?',
    paragraph: 'Properties on Pending are classified as either off-market or pre-market, depending on the owner\'s timeline. Buyers can request viewings for off-market homes by offering an indicative price. If the owner is interested, we\'ll coordinate the viewing with the buyer.'
  },
  {
    heading: 'Why must buyers sign up to see property details?',
    paragraph: 'The privacy of all users is paramount. We require buyers to register before they can view full property details within our closed marketplace. Full property details include photos, floor plans and a complete property description.'
  },
  {
    heading: 'What is an indicative offer?',
    paragraph: 'To aid owners in considering off-market viewings, buyers are required to submit an indicative offer. While indicative offers aren\'t legally binding, they help owners gauge buyers\' price alignment. Following a property viewing, buyers can update and finalize their offer if they wish to proceed.'
  }
]

const FaqsSection = () => {
  const faqsCards = faqsCopy.map((obj, index) => {
    const borderStyles = index !== faqsCopy.length - 1 ? "border-b border-[#BABBBA]" : ""
    const cardPaddingTop = index !== 0 ? " pt-[44px]" : ""

    return (
      <div
        key={`${obj.heading}-how-it-works-faqs`}
        className={borderStyles + cardPaddingTop + " pr-[49px] pb-[41px]"}
      >
        <h3 className="text-[30px] font-bold mb-3
          mobile-how-it-works:text-[24px]"
        >
          {obj.heading}
        </h3>
        <p className="text-[20px]
          mobile-how-it-works:text-[18px]"
        >
          {obj.paragraph}
        </p>
      </div>
    )
  })

  return (
    <div className="grid grid-cols-2 bg-[#FFFCF9] text-[#474747] pt-[97px] pb-[129px] mb-[-50px] pl-[79px] pr-[70px]
      mobile-how-it-works:flex mobile-how-it-works:flex-col mobile-how-it-works:px-5 mobile-how-it-works:pt-20 mobile-how-it-works:pb-30"
    >
      <div className="max-w-[480px]
        mobile-how-it-works:max-w-[322px] mobile-how-it-works:mb-[88px]"
      >
        <h2 className="text-[54px] font-bold leading-[110%] mb-6
          mobile-how-it-works:text-[38px]"
        >
          Frequently Asked Questions
        </h2>
        <p className="text-[26px]
          mobile-how-it-works:text-[22px]"
        >
          Still have questions?
        </p>
        <a
          className="text-[26px] underline underline-offset-4 decoration-1
            mobile-how-it-works:text-[22px]
          "
          href="mailto:hello@trypending.com"
        >
          Contact Us
        </a>
      </div>
      <div>
        {faqsCards}
      </div>
    </div>
  )
}

export default FaqsSection
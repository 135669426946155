const StepTwoOptions = ({
  stepTwoOption,
  setStepTwoOption,
  loadingStyles
}) => {
  const IMMEDIATELY = 'Immediately'
  const ONE_TO_THREE_MONTHS = '1 - 3 months'
  const THREE_TO_SIX_MONTHS = '3 - 6 months'
  const SIX_TO_TWELVE_MONTHS = '6 - 12 months'
  const TWELVE_PLUS_MONTHS = '12+ months'

  return (
    <div className={loadingStyles + ' text-pending-green font-lato max-w-[388px] grid grid-cols-2 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 mt-[18px] mb-10'}>
      <div>
        <button
          className={`${stepTwoOption === IMMEDIATELY && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepTwoOption(IMMEDIATELY)
          }}
        >
          {IMMEDIATELY}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === ONE_TO_THREE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepTwoOption(ONE_TO_THREE_MONTHS)}
        >
          {ONE_TO_THREE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === THREE_TO_SIX_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepTwoOption(THREE_TO_SIX_MONTHS)}
        >
          {THREE_TO_SIX_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === SIX_TO_TWELVE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepTwoOption(SIX_TO_TWELVE_MONTHS)}
        >
          {SIX_TO_TWELVE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepTwoOption === TWELVE_PLUS_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepTwoOption(TWELVE_PLUS_MONTHS)}
        >
          {TWELVE_PLUS_MONTHS}
        </button>
      </div>
    </div>
  )
}

export default StepTwoOptions
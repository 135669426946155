import listingsVisual from '../../../../../assets/images/onboardingChartListings.png'

const StepThreeListingsVisual = () => {
  return (
    <div className="flex max-h-[322px] h-full min-h-0">
      <img
        className="object-cover"
        src={listingsVisual}
        alt="Graphic of a chart behind three listings"
        width="532"
        height="322"
      />
    </div>
  )
}

export default StepThreeListingsVisual
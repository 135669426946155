import { useState } from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenuIcon from '../../../customComponents/hamburger-menu-icon/HamburgerMenuIcon';
import { SignOutButton, useUser } from '@clerk/clerk-react';
import PendingLogo from '../../../customComponents/pendingLogo/PendingLogo';

const Navbar = () => {
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const fixedMobileNavMenuStyles = 
    isMobileNavMenuOpen
      ? 'mobile-navbar:fixed mobile-navbar:z-[100]'
      : ''

  const { isLoaded, isSignedIn } = useUser()

  return (
    <header className="bg-[#FFFCF9]">
      <div className={fixedMobileNavMenuStyles + " flex w-full px-12 py-6 border-[#D6D6D6] gap-16 mobile-navbar:gap-2 mobile-navbar:px-4"}>
        <PendingLogo variant='lg' className='mobile-home:hidden block'/>
        <PendingLogo variant='sm' className='mobile-home:block hidden'/>

        {/* Nav Links (Desktop) */}
        <nav className="flex ml-auto whitespace-nowrap">
          <ul className="flex gap-16 items-center justify-between font-semibold
            mobile-navbar:hidden"
          >
            <li>
              <Link to="/about">
                About
              </Link>
            </li>
            <li>
              <Link to="/how-it-works">
                How It Works
              </Link>
            </li>
            <li>
              <Link to="/contact">
                Contact
              </Link>
            </li>
            {!isSignedIn && (
              <li>
                <Link to="/login">
                  Log in
                </Link>
              </li>
            )}
          </ul>
        </nav>

        {/* Login Button (Desktop) */}
        {(!isSignedIn && isLoaded) && (
          <Link to="/sign-up">
            <button className="bg-[#1A3A32] -ml-3 px-10 py-3 rounded-[30px] font-jakarta-sans font-semibold text-[16px] text-white hover:bg-[#242424] transition-all whitespace-nowrap mobile-navbar:hidden">
              Sign up
            </button>
          </Link>
        )}

        {/* Log Out Button (Desktop) */}
        {(isSignedIn && isLoaded) && (
          <div className="flex h-[48px]">
            <SignOutButton>
              <button className="bg-[#1A3A32] px-8 rounded-[30px] font-jakarta-sans font-semibold text-[16px] text-white hover:bg-[#242424] transition-all whitespace-nowrap mobile-navbar:hidden">
                Sign Out
              </button>
            </SignOutButton>
          </div>
        )}

        {/* Login Button (Mobile) */}
        {(!isSignedIn && isLoaded) && ( 
          <Link to="/login">
            <button className='mobile-home:block hidden text-pending-green font-lato text-lg mr-6'>
              Login
            </button>
          </Link>
        )}

        {/* Hamburger Menu (Mobile) */}
        <button
          className="hidden relative z-[100]
            mobile-home:block"
          aria-label="Navigation menu button"
          onClick={() => setIsMobileNavMenuOpen(prev => !prev)}
        >
          <HamburgerMenuIcon
            isMobileNavMenuOpen={isMobileNavMenuOpen}
          />
        </button>
      </div>
      
      {/* Fixed Mobile Nav Menu Items */}
      {isMobileNavMenuOpen && (
        <nav className="fixed hidden z-50 w-full h-full bg-white px-4 pt-[82px] pb-10 font-lato font-semibold
          mobile-home:block" // pt-[82px] = height of <header></header>
        >
          <ul className="flex flex-col h-full text-2xl">
            <li>
              <Link
                className="flex py-6 border-b border-[#8B8B8B]"
                onClick={() => setIsMobileNavMenuOpen(false)}
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className="flex py-6 border-b border-[#8B8B8B]"
                onClick={() => setIsMobileNavMenuOpen(false)}
                to="/how-it-works"
              >
                How It Works
              </Link>
            </li>
            <li>
              <Link
                className="flex py-6 border-b border-[#8B8B8B]"
                onClick={() => setIsMobileNavMenuOpen(false)}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="mt-auto">
              {(isSignedIn && isLoaded) && (
                <li className="w-full">
                <SignOutButton>
                  <div className="w-screen h-[52px] text-base">
                    <button className="flex justify-center items-center mx-auto font-lato font-semibold max-h-[52px] max-w-[240px] w-full h-full bg-white border border-black rounded-[8px]">
                      Log Out
                    </button>
                  </div>
                </SignOutButton>
              </li>
              )}

              {(!isSignedIn && isLoaded) && (
                <ul className="grid grid-cols-[minmax(0px,_248px)_minmax(0px,_248px)] gap-4  justify-center text-base">
                  <li className="w-full">
                    <Link
                      className="flex justify-center py-[14px] px-4 border border-black rounded-[8px]"
                      to="/login"
                    >
                      Log In
                    </Link>
                  </li>
                  <li className="w-full">
                    <Link
                      className="flex justify-center py-[14px] px-4 border border-black bg-pending-green text-white rounded-[8px]"
                      to="/sign-up"
                    >
                      Sign Up
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Navbar;

import { useDashboardPage } from "../../../../../context/DashboardPageProvider"

const DashboardToggle = () => {
  const { setCurrentPage } = useDashboardPage()

  const handleToggleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const toggleSection = e.target as HTMLInputElement | HTMLDivElement | HTMLLabelElement
    const parentDiv = toggleSection.parentElement

    if (toggleSection && parentDiv) {
      const slider = parentDiv.querySelector('.dashboard-slider') as HTMLDivElement
      const labels = parentDiv.querySelectorAll('label')

      if (labels.length !== 0 && slider) {
        const translatePixels = toggleSection.querySelector('input')?.dataset.location
        slider.style.transform = `translateX(${translatePixels})`
        labels.forEach(label => {
          if (label === toggleSection) {
            label.style.color = 'white'
            label.textContent && setCurrentPage(label.textContent)
          } else label.style.color ='black'
        })
      }
    }
  }
  
  return (
    <div
      className="w-full bg-[#E0E0E0] box-border text-center relative rounded-[40px] max-h-[54px] max-w-[357px]
        after:content-none after:block after:clear-both mobile-dashboard:max-w-[328px]" 
      onClick={(e) => handleToggleClick(e)}
    >
      <div className="dashboard-slider white absolute top-[4px] left-[6px] bottom-0 max-h-[46px] w-[calc(31.65%)] bg-pending-green shadow-md z-10 pointer-events-none transition-all duration-500 rounded-[30px]"></div>
      <label className="text-white font-lato font-bold flex items-center float-left w-[calc(33.33%_-_1px)] relative overflow-hidden cursor-pointer h-full transition-color duration-500">
        <input
          className="absolute top-[-200%] pointer-events-none"
          type="radio"
          name="searchtype"
          data-location="0"
          value="one"
        />
        <div className="absolute z-10 w-full pointer-events-none">
          Home
        </div>
      </label>
      <label className="font-lato font-bold flex items-center float-left w-[calc(33.33%_-_1px)] relative overflow-hidden cursor-pointer h-full transition-color duration-500">
        <input
          className="absolute top-[-200%] pointer-events-none"
          type="radio"
          name="searchtype"
          data-location="calc(100% + 2px)"
          value="two"
        />
        <div className="absolute z-10 w-full pointer-events-none">
          Comps
        </div>
      </label>
      <label className="font-lato font-bold flex items-center float-left w-[calc(33.33%_-_1px)] relative overflow-hidden cursor-pointer h-full transition-color duration-500">
        <input
          className="absolute top-[-200%] pointer-events-none"
          type="radio"
          name="searchtype"
          data-location="calc(200% + 4px)"
          value="three"
        />
        <div className="absolute z-10 w-full pointer-events-none">
          Offers
        </div>
      </label>
    </div>
  )
}

export default DashboardToggle
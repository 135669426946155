interface ResendCodeButtonProps {
  resendCode: () => void;
}

const ResendCodeButton = ({ resendCode }: ResendCodeButtonProps) => {
  return (
    <button
      className="text-[#1A3A32] font-bold text-[13px] py-[14px] rounded-lg transition-all hover:bg-[#F1F0ED]"
      onClick={resendCode}
    >
      RESEND CODE
    </button>
  );
};

export default ResendCodeButton;

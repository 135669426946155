import { useEffect } from 'react';
import { Modal } from '@mui/material';
import ExitIcon from '../../../../components/customComponents/exitIcon/ExitIcon';
import termsAndConditionsCopy from '../../../../copy/TermsAndConditionsCopy';

interface TermsAndConditionsModalProps {
  open: boolean;
  handleClose: () => void;
}

const TermsAndConditionsModal = ({
  open,
  handleClose,
}: TermsAndConditionsModalProps) => {
  // If user's scroll position is near bottom of container - hide overlay, else show overlay
  const handleScrollChange = () => {
    const scrollContainer = document.querySelector(
      '.scrollContainer'
    ) as HTMLElement;

    if (open && scrollContainer) {
      scrollContainer.addEventListener('scroll', () => {
        const fadeOverlay = document.querySelector(
          '.fadeOverlay'
        ) as HTMLElement;
        const isNearBottom =
          scrollContainer.scrollHeight -
            scrollContainer.scrollTop -
            scrollContainer.clientHeight <
          1; // reference: https://www.google.com/search?q=what+is+clientHeight&oq=what+is+clientHeight&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQLhhA0gEIMTkwMmowajGoAgCwAgA&sourceid=chrome&ie=UTF-8

        if (isNearBottom && fadeOverlay) {
          const fadeOverlay = document.querySelector(
            '.fadeOverlay'
          ) as HTMLElement;
          if (fadeOverlay) fadeOverlay.style.display = 'none';
        } else fadeOverlay.style.display = 'block';
      });
    }
  };

  const delayScrollChange = setTimeout(handleScrollChange, 200);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (open) {
      timeoutId = delayScrollChange;
      return () => clearTimeout(timeoutId as NodeJS.Timeout);
    } else if (!open && timeoutId) clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Terms and conditions agreement"
      aria-describedby="Agree to our terms and conditions"
    >
      <div className="relative top-[2%] flex justify-center pointer-events-none">
        <div className="relative max-w-[700px] max-h-[600px] w-full h-full bg-white py-[59px] rounded-[20px] flex justify-center pointer-events-auto">
          {' '}
          {/* pointer-events-auto: allow modal to be clickable */}
          <div className="flex flex-col items-center max-w-[570px] w-full mb-[58px]">
            <button
              className="absolute top-0 right-0 mt-4 mr-4 font-jakarta-sans font-semibold rounded-[30px] max-w-[314px]"
              onClick={handleClose}
            >
              <ExitIcon />
            </button>
            <h2 className="text-[#1A3A32] font-poppins font-bold text-[32px] mb-[59px]">
              Terms & Conditions
            </h2>
            <div className="relative overflow-hidden overflow-y-auto scrollContainer">
              <p className="font-lato font-medium text-[15px] whitespace-pre-line">
                {termsAndConditionsCopy}
              </p>
            </div>
            <div className="absolute w-full min-h-0 h-full max-h-fit bottom-0 bg-gradient-to-b from-transparent via-transparent to-white pointer-events-none rounded-[20px] fadeOverlay"></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;

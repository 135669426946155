import { useState } from "react"
import CarouselCard from "./carousel-card/CarouselCard"
import CarouselButtons from "./carousel-buttons/CarouselButtons"
import LeftOverlay from "./left-overlay/LeftOverlay"
import RightOverlay from "./right-overlay/RightOverlay"
import FinishOnboardingModal from "./finish-onboarding-modal/FinishOnboardingModal"
import carouselImage1 from "../../../../../assets/images/carouselImage1.webp"
import carouselImage2 from "../../../../../assets/images/carouselImage2.webp"
import carouselImage3 from "../../../../../assets/images/carouselImage3.webp"
import carouselImage4 from "../../../../../assets/images/carouselImage4.webp"
import carouselImage5 from "../../../../../assets/images/carouselImage5.webp"
import carouselImage6 from "../../../../../assets/images/carouselImage6.webp"
import carouselImage7 from "../../../../../assets/images/carouselImage7.webp"
import carouselImage8 from "../../../../../assets/images/carouselImage8.webp"
import carouselImage9 from "../../../../../assets/images/carouselImage9.webp"
import carouselImage10 from "../../../../../assets/images/carouselImage10.webp"
import carouselImage11 from "../../../../../assets/images/carouselImage11.webp"
import carouselImage12 from "../../../../../assets/images/carouselImage12.webp"
import carouselImage13 from "../../../../../assets/images/carouselImage13.webp"
import carouselImage14 from "../../../../../assets/images/carouselImage14.webp"
import carouselImage15 from "../../../../../assets/images/carouselImage15.webp"

const PropertiesCarousel = () => {
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  // TODO: remove once carousel endpoint has been created
  const activeProperties = [
    {
      affectionateTitle: 'Bayside Bliss on Douglas',
      guidePrice: '1,500,000',
      image: carouselImage1,
      location: 'Hampton Bays, NY'
    },
    {
      affectionateTitle: 'Cliffside with Helipad',
      guidePrice: '8,500,000',
      image: carouselImage2,
      location: 'Port Jefferson, NY'
    },
    {
      affectionateTitle: 'Dayton Escape',
      guidePrice: '3,950,000',
      image: carouselImage3,
      location: 'East Hampton, NY'
    },
    {
      affectionateTitle: 'Designer\'s Sanctuary',
      guidePrice: '5,000,000',
      image: carouselImage4,
      location: 'Amagansett, NY'
    },
    {
      affectionateTitle: 'East Hampton Elegance',
      guidePrice: '2,595,000',
      image: carouselImage5,
      location: 'East Hampton, NY'
    },
    {
      affectionateTitle: 'Breathtaking Views',
      guidePrice: '4,500,000',
      image: carouselImage6,
      location: 'Water Mill, NY'
    },
    {
      affectionateTitle: 'Jericho Retreat',
      guidePrice: '7,500,000',
      image: carouselImage7,
      location: 'East Hampton, NY'
    },
    {
      affectionateTitle: 'Lee Avenue Oasis',
      guidePrice: '6,000,000',
      image: carouselImage8,
      location: 'East Hampton, NY'
    },
    {
      affectionateTitle: 'Millside Manor',
      guidePrice: '4,200,000',
      image: carouselImage9,
      location: 'Water Mill, NY'
    },
    {
      affectionateTitle: 'Sagaponack Serenity',
      guidePrice: '9,000,000',
      image: carouselImage10,
      location: 'Sagaponack, NY'
    },
    {
      affectionateTitle: 'Sagaponack Sanctuary',
      guidePrice: '10,000,000',
      image: carouselImage11,
      location: 'Sagaponack, NY'
    },
    {
      affectionateTitle: 'Serene Retreat in Springs',
      guidePrice: '2,250,000',
      image: carouselImage12,
      location: 'East Hampton, NY'
    },
    {
      affectionateTitle: 'The Bridgehampton Estate',
      guidePrice: '13,500,000',
      image: carouselImage13,
      location: 'Water Mill, NY'
    },
    {
      affectionateTitle: 'Waterfront Haven',
      guidePrice: '3,250,000',
      image: carouselImage14,
      location: 'Hampton Bays, NY'
    },
    {
      affectionateTitle: 'Whispering Pines',
      guidePrice: '3,250,000',
      image: carouselImage15,
      location: 'East Hampton, NY'
    },
  ]

  const handleModalClose = () => setModalOpen(false)
  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const rightCarouselButtonClickHandler = () => {
    setCurrentCarouselIndex(prevIndex => {
      const previousIndex = Number(prevIndex)
      const activePropertiesMaxIndex = Number(activeProperties && activeProperties.length - 1)
      const increaseIndex = prevIndex + 1

      return previousIndex === activePropertiesMaxIndex ? 0 : increaseIndex
    })
  }

  const leftCarouselButtonClickHandler = () => {
    setCurrentCarouselIndex(prevIndex => {
      const previousIndex = Number(prevIndex)
      const activePropertiesMaxIndex = Number(activeProperties && activeProperties.length - 1)
      const decreaseIndex = prevIndex - 1

      return previousIndex === 0 ? activePropertiesMaxIndex : decreaseIndex
    })
  }

  const carouselCards = activeProperties.map((property, index) => {
    return (
      <CarouselCard
        key={`${index}-${property.affectionateTitle}-property-carousel-card`}
        handleModalOpen={handleModalOpen}
        property={property}
        index={index}
        lastCardIndex={activeProperties.length - 1}
      />
    )
  })

  return (
    <>
      {/* DESKTOP */}
      <div className="grid w-full h-full pb-8 pt-0 mobile-dashboard:hidden">
        <div
          className="relative flex mx-auto pl-[70px] pb-10 pt-6 overflow-x-hidden w-[100%]
          mobile-dashboard:pl-0"
        >
          <div
            className="relative flex transition-transform duration-300"
            style={{ transform: `translate3d(${-currentCarouselIndex * 650}px, 0, 0)` }} // may need to adjust number if width of carousel cards CHANGE
          >
            {carouselCards}
          </div>
          <LeftOverlay currentCarouselIndex={currentCarouselIndex}/>
          <RightOverlay
            currentCarouselIndex={currentCarouselIndex}
            activeProperties={activeProperties}
          />
          <CarouselButtons
            currentCarouselIndex={currentCarouselIndex}
            activeProperties={activeProperties}
            leftCarouselButtonClickHandler={leftCarouselButtonClickHandler}
            rightCarouselButtonClickHandler={rightCarouselButtonClickHandler}
          />
        </div>
      </div>

      {/* MOBILE */}
      <div className="hidden mobile-dashboard:flex w-screen h-full overflow-hidden"> {/* viewport */}
        <div className="flex gap-4 overflow-x-auto overflow-y-hidden w-full snap-x snap-mandatory pt-[18px] pb-8">
          {carouselCards}
        </div>
      </div>
      <FinishOnboardingModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  )
}

export default PropertiesCarousel

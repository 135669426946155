const StaticLoader = () => {
  return (
    <svg
      className='w-4 h-full'
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 1.938L5.2 3.15005M8 1V2.4V1ZM11.5 1.938L10.8 3.15005L11.5 1.938ZM14.062 4.5L12.8496 5.2L14.062 4.5ZM15 8H13.6H15ZM14.062 11.5L12.8496 10.8L14.062 11.5ZM11.5 14.062L10.8 12.8496L11.5 14.062ZM8 15V13.6V15ZM4.5 14.062L5.2 12.8496L4.5 14.062ZM1.938 11.5L3.15005 10.8L1.938 11.5ZM1 8H2.4H1ZM1.938 4.5L3.15005 5.2L1.938 4.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StaticLoader
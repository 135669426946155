import DiscoverHomesSection from "./discover-homes-section/DiscoverHomesSection"
import YourHomeSection from "./your-home-section/YourHomeSection"
import { useUserData } from "../../../context/UserDataProvider"

const Home = ({ userProperties }) => {
  const { userObject } = useUserData()
  return (
    <>
      {(userObject.role === 'buyer' ||
        userObject.role === 'buy_sell') 
        && <DiscoverHomesSection />
      }
      <YourHomeSection userProperties={userProperties} />
    </>
  )
}

export default Home
import PendingLogo from '../../../components/customComponents/pendingLogo/PendingLogo';
import RegistrationContent from './registration-content/RegistrationContent';

const RegistrationView = () => {
  return (
    <div className="flex flex-col gap-[121px] p-[85px] h-full tablet-signup:min-h-screen tablet-signup:w-[39rem] tablet-signup:items-center mobile-signup:px-[34px] mobile-signup:gap-[68px]">
      <div className="tablet-signup:mr-auto">
        <PendingLogo />
      </div>
      <RegistrationContent />
    </div>
  );
};

export default RegistrationView;

const PrivacyPolicy = () => {
  return (
    <div className='p-12 mobile-home:p-4'>
      <h1 className='font-bold'>Privacy Policy</h1>
      <p>Effective as of August 9, 2024</p>
      <br/>
      <p>This Privacy Policy describes the privacy practices of Pending, Inc. (“Pending,” “we”, “us” or “our”) and how we handle personal information that we collect through our digital properties that link to this Privacy Policy, including our website (collectively, the “Service”), as well as through social media, our marketing activities, and other activities described in this Privacy Policy. We are required to collect certain information, including your name and email address, as a condition of our licenses with the multiple real estate listing services (the “MLSs”) in order to allow you to access the full MLS listings. You give us this information when you create your Pending account. When you give us this information at one of our events, or upon your request, we will create a Pending account for you, and you agree that this Privacy Policy and our Terms of Use apply.</p>
      <br/>
      <h2 className='font-bold'>Personal information we collect</h2>
      <br/>
      <p>
        <b>Information you provide to us.</b> Personal information you may provide to us through the Service or otherwise includes:
        <ul className='list-disc list-inside'>
          <li><b>Contact data</b>, such as your first and last name, salutation, email address, billing and mailing addresses, and phone number.</li>
          <li><b>Demographic Information</b>, such as your city, state, country of residence, postal code, and age.</li>
          <li><b>Account data</b>, such as the username and password that you may set to establish an online account with us, date of birth, and any other information that you add to your account profile.</li>
          <li><b>Communications</b> that we exchange with you, including when you contact us with questions or feedback, through the Service, social media, or otherwise.</li>
          <li><b>Marketing data</b>, such as your preferences for receiving our marketing communications and details about your engagement with them.</li>
          <li><b>Government-issued identification numbers</b>, such as national identification number (e.g. Social Security Number, tax identification number, passport number), state or local identification number (e.g., driver’s license or state ID number), and an image of the relevant identification card.</li>
          <li><b>User-generated content</b>, such as photos of your property, comments, questions, and other content or information that you generate, transmit, or otherwise make available on the Service, as well as associated metadata. Metadata includes information on how, when, where and by whom a piece of content was collected and how that content has been formatted or edited. Metadata also includes information that users can add or can have added to their content, such as keywords, geographical or location information, and other similar data.</li>
          <li><b>Property listing data</b>, such as the property’s physical address, square feet, acreage, year built, property type, target price range, number of rooms and bathrooms, photograph(s) of the interior and exterior of the property you are interested in selling, Seller Q&A information and responses, and any other details or description that you choose to provide about the property.</li>
          <li><b>Financial data</b>, such as your stated income, economic standing, financial account numbers or payment card information, and loan pre-approval information.</li>
          <li><b>Real estate transaction data</b>, such as the price you’re willing to pay for a home / sell your home for, contingencies placed on your offer, legal entities you use to sell or purchase real estate, and any information you provide around third parties such as co-buyers or service providers used in connection with a transaction.</li>
          <li><b>Testimonial and survey data</b>, such as the name and content of any voluntary testimonials after using the Service and any information you provide when you complete one of our surveys.</li>
          <li><b>Other data not specifically listed here</b>, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</li>
        </ul>
      </p>
      <br/>
      <p>
        <b>Third-party sources.</b> We may combine information we receive from you with information we obtain from other sources, such as:
        <ul className='list-disc list-inside'>
          <li><b>Public sources</b>, such as government agencies, public records, social media platforms, and other publicly available sources, including reviews from third-party websites.</li>
          <li><b>Data providers</b>, such as information services and data licensors that provide demographic and other information. For example, we use data providers for address data, tax data, as well as third-party services to identify home purchases through LLCs or property status, and may combine this data with personal information we receive from you.</li>
        </ul>
      </p>
      <br/>
      <p>
        <b>Automatic data collection.</b> We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:
        <ul className='list-disc list-inside'>
          <li><b>Device data</b>, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and general location information such as city, state or geographic area.</li>
          <li><b>Email data</b>, such as which emails you view and click.</li>
          <li><b>Online activity data</b>, such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, information you provide Pending in connection with the Service, access times and duration of access, and whether you have opened our marketing emails or clicked links within them.</li>
          <li><b>Precise geolocation data</b> when you authorize the Service to access your device’s location.</li>
        </ul>
      </p>
      <br/>
      <p>
        <b>Cookies and similar technologies.</b> Like many online services, we use the following technologies:
        <ul className='list-disc list-inside'>
          <li><b>Cookies</b>, which are text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating analytics and online advertising. Our sites may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them). Some of these cookies are served by third-party service providers or business partners and can be used by these parties to recognize your computer or mobile device when it visits the Service and other online services.</li>
          <li><b>Local storage technologies</b>, like HTML5 and Flash, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.</li>
          <li><b>Web beacons</b>, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email address was accessed or opened, or that certain content was viewed or clicked.</li>
          <li><b>Application Programming Interfaces (APIs) and Software Development Kits (SDKs)</b>, which are used to incorporate third-party computer code into the Service. We may use third-party APIs and SDKs as part of the functionality of our Services that allows our third-party service providers or advertising partners to collect data directly from the Service for a variety of purposes, including to provide us with analytics regarding the use of the Service, to integrate with social media, add features or functionality to the Service, or to facilitate online advertising.</li>
          <li><b>Session Replay Technologies</b>, we may use third-party services that employ software code to record users’ interactions with the Services in a manner that allows us to watch DVR-like replays of those user sessions. The replays include users’ clicks, mobile app touches, mouse movements, scrolls, and keystrokes during those sessions. These replays help us diagnose usability problems and identify areas for improvement.</li>
        </ul>
      </p>
      <br/>
      <p>
        These cookies and similar technologies may be employed for the following purposes:
        <ul className='list-disc list-inside'>
          <li><b>Analytics.</b> Help us understand how our services are performing, being used, and to provide support. These cookies may work with web beacons included in emails we send to track which emails are opened and which links are clicked by recipients. For example, we use Google Analytics to help us understand user activity on the Service. You can learn more about Google Analytics cookies at <a className='underline text-blue-700 break-all'href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">https://developers.google.com/analytics/resources/concepts/gaConceptsCookies</a> and about how Google protects your data at <a className='underline text-blue-700 break-all' href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a>. You can prevent the use of Google Analytics relating to your use of our sites by downloading and installing a browser plugin available at <a className='underline text-blue-700 break-all' href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</li>
          <li><b>Essential.</b> Necessary to allow the technical operation of our services (e.g., ensuring our users can log-in to secure areas of our services).</li>
          <li><b>Functionality/performance.</b> Enhance the performance and functionality of our services. These are used to improve how our services function.</li>
        </ul>
      </p>
      <br />
      <p>To opt out of interest-based advertising on our Services or to learn more about the use of this information by our advertising partners, please visit the Network Advertising Initiative (NAI) opt-out tool at <a className='underline text-blue-700 break-all' href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a> or the Digital Advertising Alliance’s (DAA) opt-out tool at <a  className='underline text-blue-700 break-all' href="http://www.aboutads.info/">http://www.aboutads.info/</a>. Your device settings may allow you to disable some technologies, such as cookies or local storage. Because these settings vary by device and technology, you may need to consult your device or browser’s help documentation for additional information. Note that if you disable cookies and similar technologies, some features of our websites may not work properly.</p>
      <br />
      <h2 className='font-bold'>How we user your personal information</h2>
      <p>We will never sell your personal information. We may use your personal information for the following purposes:
      </p>
      <br />
      <p>
        <b>Service delivery and operations.</b>
        <ul className='list-disc list-inside'>
          <li>Provide, operate, and improve the Service and our business.</li>
          <li>Establish and maintain your user profile on the Service.</li>
          <li>Communicate with you about the Service, including by sending announcements, updates, security alerts, and support and administrative messages.</li>
          <li>Personalize your experience on the Service.</li>
          <li>Enable security features of the Service, such as by sending you security codes via email or SMS, and remembering devices from which you have previously logged in.</li>
          <li>Understand your needs and interests, and personalize your experience with the Service and our communications.</li>
          <li>Provide support for the Service, and respond to your requests, questions, and feedback.</li>
        </ul>
      </p>
      <br />
      <p><b>Research and development.</b> We may use your personal information for research and development purposes, including to analyze and improve the Service and our business, and to develop new products and services. We may create aggregated, de-identified, or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.</p>
      <br />
      <p><b>Marketing and advertising.</b> We may collect and use your personal information for marketing and advertising purposes:
        <ul className='list-disc list-inside'>
          <li><b>Direct marketing.</b> We may send you Pending-related or other direct marketing communications as permitted by law, including by email, mail, or SMS. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.</li>
        </ul>
      </p>
      <br />
      <p><b>Compliance and protection.</b> We may use your personal information to:
        <ul className='list-disc list-inside'>
          <li>Comply with applicable laws, lawful requests, and legal processes, such as responding to subpoenas or requests from government authorities.</li>
          <li>Protect our, your, or others’ rights, privacy, safety, or property (including by making and defending legal claims).</li>
          <li>Audit our internal processes for compliance with legal and contractual requirements and internal policies.</li>
          <li>Enforce the terms and conditions that govern the Service.</li>
          <li>Prevent, identify, investigate, and deter fraudulent, harmful, unauthorized, unethical, or illegal activity, including cyberattacks and identity theft.</li>
        </ul>
      </p>
      <br />
      <h2 className='font-bold'>How we share your personal information</h2>
      <br />
      <p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection:</p>
      <br />
      <p><b>Affiliates. </b>Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.</p>
      <br />
      <p><b>Service providers. </b>Third parties that provide services on our behalf or help us operate the Service or our business (such as customer support, hosting, payment processing, shipping, email delivery, marketing, and database management services).</p>
      <br />
      <p><b>Real estate partners.</b> We may share your personal information with licensed real estate professionals to provide you with real estate services and for their own purposes.</p>
      <br />
      <p><b>Other users of the Service and the public.</b> We may provide functionality that enables you to disclose personal information to other users of the Service or the public. For example, if you post content in a public area of the Service, such as a comment or blog, or use a feature like direct messages to communicate with another user, the content may be publicly viewable or viewable by the other user. We do not control how other users or third parties use any personal information that you make available to other users or the public.</p>
      <br />
      <p><b>Professional advisors.</b> Professional advisors, such as lawyers, auditors, bankers, and insurers, where necessary in the course of the professional services that they render to us.</p>
      <br />
      <p><b>Authorities and others.</b> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</p>
      <br />
      <p><b>Business transferees.</b>Acquirers and other relevant participants in business transactions (or negotiations) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale, spin-off or dissolution of Pending or other corporate entities or all or a portion of our assets, or other disposition of all or any portion of our business, assets, or stock (including in connection with a bankruptcy or similar proceedings).</p>
      <br />
      <p><b>Third-party platforms and social media networks.</b> If you have enabled features or functionality that connect the Service to a third-party platform or social media network (such as by logging into Pending or our Service using your account with the third party, providing your API key or similar access token for the Service to a third party, or otherwise linking your Pending account to a third party’s services), we may disclose the personal information that you authorized Pending to share. We do not control the third party’s use of your personal information.</p>
      <br />
      <p><b>Other users of the Service and the public.</b> We may provide functionality that enables you to disclose personal information to other users of the Service or the public. For example, if you post content in a public area of the Service, such as a comment or blog, or use a feature like direct messages to communicate with another user, the content may be publicly viewable or viewable by the other user. We do not control how other users or third parties use any personal information that you make available to other users or the public.</p>
      <br />
      <p><b>Third-party services.</b> Third-party services you access via the Service. We do not control how third-party services process the personal information that you make available to them or that we share with them at your direction. Please review each third-party service’s privacy policy before using their services.</p>
      <br />
      <h2 className='font-bold'>Your choices</h2>
      <br />
      <p>In this section, we describe the rights and choices available to all users.</p>
      <br />
      <p><b>Opt-out of marketing communications.</b> You may opt-out of marketing-related communications by following the opt-out or unsubscribe instructions contained in the marketing communication we send you or by contacting us at hello@trypending.com. You may continue to receive service-related and other non-marketing communications.</p>
      <br />
      <p><b>Opt-out of interest-based advertising.</b>You can limit the use of your information for interest-based advertising by blocking third-party cookies in your browser settings (as described above), using browser plug-ins/extensions, or using third-party ad blockers. For more information, see our Cookie Notice. You may also opt out of interest-based advertising through the NAI, the DAA, or the European Interactive Digital Advertising Alliance (EDAA).</p>
      <br />
      <h2 className='font-bold'>Do Not Track</h2>
      <p>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit <a className='underline text-blue-700 break-all' href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.</p>
      <br />
      <h2 className='font-bold'>Declining to provide information</h2>
      <p>We need to collect personal information to provide certain services. If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.</p>
      <br />
      <h2 className='font-bold'>Third-party platforms</h2>
      <p>If you choose to connect to the Service through your social media account or other third-party platform, you may be able to use your settings in your account with that platform to limit the information we receive from it. If you revoke our ability to access information from a third-party platform, that choice will not apply to information that we have already received from that third party.</p>
      <br />
      <h2 className='font-bold'>Delete your content or close your account</h2>
      <p>You can choose to delete certain profile data in your account or you can request to close your account by contacting us.</p>
      <br />
      <h2 className='font-bold'>Other sites and services</h2>
      <p>The Service may contain links to websites, mobile applications, and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites, mobile applications, or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites, mobile applications, and online services you use.</p>
      <br />
      <h2 className='font-bold'>Security</h2>
      <p>We employ a number of technical, organizational, and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies, and we cannot guarantee the security of your personal information.</p>
      <br />
      <h2 className='font-bold'>International data transfer</h2>
      <p>We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.</p>
      <br />
      <h2 className='font-bold'>Children</h2>
      <p>The Service is not intended for use by children under 18 years of age. If we learn that we have collected personal information through the Service from a child under 18 without the consent of the child’s parent or guardian as required by law, we will delete it. We encourage parents or guardians with concerns to contact us.</p>
      <br />
      <h2 className='font-bold'>Changes to the Privacy Policy</h2>
      <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. If required by law, we will also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Service. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>
      <br />
    </div>
  )
}

export default PrivacyPolicy
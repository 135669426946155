import ValuationChart from '../../../../components/widgets/valuationChart/ValuationChart.js';
import { useState } from 'react';
import ClaimHomeModal from './claim-home-modal/ClaimHomeModal';
import DashboardMenuLabel from '../../common/dashboard-menu-label/DashboardMenuLabel';
import DashboardMenu from '../../common/dashboard-menu/DashboardMenu';

const YourHomeSection = ({ userProperties }) => {
  const [currentPropertyValue, setCurrentPropertyValue] = useState(0)

  return (
    <div className="flex flex-col gap-4 max-w-[1400px] w-full h-full mt-[55px] mb-[143px]
      mobile-dashboard:px-4"
    >
      <div className="w-full mx-[74px]
        mobile-dashboard:mx-0"
      >
        <div className="relative flex gap-[11px] h-max max-w-[324px] font-lato">
          <DashboardMenuLabel
            id='your-home-label'
            label='Your Home'
          >
            Your Home
          </DashboardMenuLabel>
          <div className='flex-auto'>
            <DashboardMenu
              isProperties={true}
              userProperties={userProperties}
              setCurrentPropertyValue={setCurrentPropertyValue}
            />
          </div>
        </div>
      </div>

      <div className="h-full mx-[74px]
        mobile-dashboard:mx-0"
      >
        <ValuationChart rawPropertyValue={currentPropertyValue} />
      </div>
      <ClaimHomeModal />
    </div>
  );
};

export default YourHomeSection;

import { useState, useEffect } from 'react';
import Heading from './heading/Heading';
import SubHeading from './subheading/SubHeading';
import LogInForm from './log-in-form/LogInForm';
import VerificationContent from './verification-content/VerificationContent';
import { Link } from 'react-router-dom';

import { useSignIn, useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const LOG_IN_HEADING = 'Log in';
const VERIFY_ACCOUNT_HEADING = 'Verify Account';

const LOG_IN_SUBHEADING = 'Hello, welcome back.';
const VERIFY_ACCOUNT_SUBHEADING = 'Enter the 6-digit code sent to ';
export interface User {
  isUserLoggedIn: boolean;
  isUserVerified: boolean;
  phoneNumber: string;
  email: string;
  sendCodeViaSMS: boolean;
}

const LogInContent = () => {
  const [user, setUser] = useState<User>({
    isUserLoggedIn: false,
    isUserVerified: false,
    phoneNumber: '',
    email: '',
    sendCodeViaSMS: true,
  });

  return (
    <div className="w-full mobile-signup:mx-auto mobile-signup:max-w-[348px]">
      <div>
        <Heading>
          {user.isUserLoggedIn && VERIFY_ACCOUNT_HEADING}
          {!user.isUserLoggedIn && LOG_IN_HEADING}
        </Heading>
        <SubHeading>
          {!user.isUserLoggedIn && LOG_IN_SUBHEADING}
          {user.isUserLoggedIn && (
            <>
              <span>
                {VERIFY_ACCOUNT_SUBHEADING}
              </span>
              <span className="font-bold block">
                {user.sendCodeViaSMS === true ? user.phoneNumber : user.email}
              </span>
            </>
          )}
        </SubHeading>
      </div>
      <div className="mt-[34px] mb-6
        mobile-signup:mt-8"
      >
        {!user.isUserLoggedIn && <LogInForm user={user} setUser={setUser} />}

        {user.isUserLoggedIn && (
          <VerificationContent
            // hasCodeSent={hasCodeSent}
            // setHasCodeSent={setHasCodeSent}
            user={user}
            setUser={setUser}
          />
        )}
      </div>
      {/* TODO: uncomment to reimplement login via email option */}
      {/* {!user.isUserLoggedIn && user.sendCodeViaSMS && (
        <button
          className="mt-[-12px] mb-[8px] px-[8px] text-[#1A3A32] font-bold text-[13px] py-[14px] rounded-lg transition-all hover:bg-[#F1F0ED]"
          onClick={() => {
            setUser((prevUser) => ({
              ...prevUser,
              sendCodeViaSMS: false,
            }));
          }}
        >
          Use Email Instead
        </button>
      )} */}

      {!user.isUserLoggedIn && !user.sendCodeViaSMS && (
        <button
          className="mt-[-12px] mb-[8px] px-[8px] text-[#1A3A32] font-bold text-[13px] py-[14px] rounded-lg transition-all hover:bg-[#F1F0ED]"
          onClick={() => {
            setUser((prevUser) => ({
              ...prevUser,
              sendCodeViaSMS: true,
            }));
          }}
        >
          Use Mobile Instead
        </button>
      )}
      <p className="font-lato font-bold text-[#6B7773]">
        Not registered yet?{' '}
        <Link to="/sign-up" className="text-[#1A3A32]">
          Create an account
        </Link>
      </p>
    </div>
  );
};

export default LogInContent;

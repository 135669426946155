const cardsCopy = [
  {
    title: "NEW TO PENDING?",
    paragraph: "Send us questions, or comments.",
    textCTA: null,
    textSubstituteCTA: "hello@trypending.com"
  },
  {
    title: "HAVE AN ACCOUNT?",
    paragraph: "Message our support team.",
    textCTA: "Email us",
    textSubstituteCTA: null
  },
  {
    title: "WANT TO PARTNER?",
    paragraph: "Send us questions, or comments.",
    textCTA: "Reach out",
    textSubstituteCTA: null
  }
]

const Contact = () => {
  const contactCards = cardsCopy.map(card => {
    return (
      <div
        className="bg-white rounded-[20px] px-8 pt-8 pb-[40px] w-full min-w-0 shadow-md"
        key={`${card.title}-contact-card`}
      >
        <p className="mb-[85px] text-[#6B7773]
          mobile-contact:mb-[55px]"
        >
          {card.title}
        </p>
        <p className="mb-[40px] text-[24px] text-[#476960] max-w-[226px]
          mobile-contact:text-[20px]"
        >
          {card.paragraph}
        </p>
        {card.textCTA && (
          <a
            className="flex justify-center font-jakarta-sans font-semibold text-pending-green text-[20px] py-[11px] w-full max-w-[132px] border border-pending-green rounded-[30px]
              mobile-contact:text-[18px]"
            href="mailto:advisor@trypending.com"
          >
            {card.textCTA}
          </a>
        )}
        {card.textSubstituteCTA && (
          <p>hello@trypending.com</p>
        )}
      </div>
    )
  })
  return (
    <div className="font-lato bg-[#FFFCF9] w-full px-[76px] min-h-min pt-[83px] pb-[114px] my-[-50px]
      mobile-contact:px-5"
    >
      <h1 className="font-medium text-[78px] text-pending-green mb-[58px]
        mobile-contact:text-[56px]"
      >
        Get in touch
      </h1>
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(400px,_404px))] gap-[38px] min-h-min
        mobile-contact:grid-cols-[repeat(auto-fill,_minmax(200px,_400px))] mobile-contact:justify-between"
      >
        {contactCards}
      </div>
    </div>
  )
}

export default Contact
import React from 'react';
import { User } from '../LogInContent';
import { useSignIn, useUser } from '@clerk/clerk-react';

type LogInUser = Omit<User, 'isUserVerified'>;

interface LogInFormProps {
  user: LogInUser;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const LogInForm: React.FC<LogInFormProps> = ({ user, setUser }) => {
  const { isLoaded, signIn } = useSignIn();
  const { user: clerkUser } = useUser();

  const inputStyles =
    'font-lato text-[18px] rounded-lg border border-[#333333] px-4 py-2 placeholder:text-[#A7A7A7] placeholder:font-lato placeholder:text-[18px] mobile-signup:h-[56px] mobile-signup:w-full mobile-signup:py-4';

  const submitFormHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
    try {
      //send code via mobile
      if (user.sendCodeViaSMS) {
        const { supportedFirstFactors } = await signIn!.create({
          identifier: user.phoneNumber,
        });

        const firstPhoneFactor: any = supportedFirstFactors.find((factor) => {
          return factor.strategy === 'phone_code';
        });

        if (!firstPhoneFactor) {
          throw new Error('No phone factor found');
        }

        const { phoneNumberId } = firstPhoneFactor;

        await signIn!.prepareFirstFactor({
          strategy: 'phone_code',
          phoneNumberId,
        });
      }
      //send code via email
      else if (!user.sendCodeViaSMS) {
        const { supportedFirstFactors } = await signIn!.create({
          identifier: user.email,
        });

        const firstEmailFactor: any = supportedFirstFactors.find((factor) => {
          return factor.strategy === 'email_code';
        });

        if (!firstEmailFactor) {
          throw new Error('No phone factor found');
        }

        const { emailAddressId } = firstEmailFactor;

        await signIn!.prepareFirstFactor({
          strategy: 'email_code',
          emailAddressId,
        });
      }

      setUser((prevUser) => ({
        ...prevUser,
        isUserLoggedIn: true,
      }));
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      if (err.status === 422) {
        alert(
          `No account associated with the phone number ${user.phoneNumber} found`
        );
      }
    }
  };

  return (
    <form id="login" onSubmit={(e) => submitFormHandler(e)}>
      <div className="max-w-[248px]
        mobile-signup:max-w-full"
      >
        {user.sendCodeViaSMS && (
          <input
            required
            className={inputStyles}
            type="tel"
            name="phoneNumber"
            placeholder="Mobile number*"
            minLength={10}
            maxLength={12}
            value={user.phoneNumber}
            onChange={(e) => {
              let formattedNumber = e.target.value;

              if (
                !formattedNumber.startsWith('+1') &&
                formattedNumber.length === 10
              ) {
                formattedNumber = '+1' + formattedNumber;
              }
              setUser((prevUser) => ({
                ...prevUser,
                phoneNumber: formattedNumber,
              }));
            }}
          />
        )}
        {!user.sendCodeViaSMS && (
          <input
            required
            className={inputStyles}
            type="text"
            name="email"
            placeholder="Email*"
            value={user.email}
            onChange={(e) =>
              setUser((prevUser) => ({ ...prevUser, email: e.target.value }))
            }
          />
        )}
      </div>
      <div className="mt-6 max-w-[110px]
        mobile-signup:max-w-full"
      >
        <button className="font-jakarta-sans text-white font-semibold w-full px-4 py-[14px] rounded-lg bg-[#1A3A32]">
          Next
        </button>
      </div>
    </form>
  );
};

export default LogInForm;

import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

interface PropertyDataObject {
  propertyAddress: string | undefined;
  setPropertyAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
  propertyLat: number | null;
  propertyLong: number | null;
  setPropertyLat: React.Dispatch<React.SetStateAction<number | null>>;
  setPropertyLong: React.Dispatch<React.SetStateAction<number | null>>;
}

const PropertyDataContext = createContext<PropertyDataObject>(
  {} as PropertyDataObject
);

export const usePropertyData = () => {
  return useContext(PropertyDataContext);
};

export const PropertyDataProvider = ({ children }: { children: ReactNode }) => {
  const [propertyAddress, setPropertyAddress] = useState<string | undefined>(
    undefined
  );
  const [propertyLat, setPropertyLat] = useState<number | null>(null);
  const [propertyLong, setPropertyLong] = useState<number | null>(null);

  const propertyObject = {
    propertyAddress,
    propertyLat,
    propertyLong,
    setPropertyAddress,
    setPropertyLat,
    setPropertyLong,
  };

  useEffect(() => {
    // console.log('property changed to: ', propertyAddress)
  }, [propertyAddress]);

  return (
    <PropertyDataContext.Provider value={propertyObject}>
      {children}
    </PropertyDataContext.Provider>
  );
};

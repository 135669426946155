import { useClaimHomeModalError } from "../../../../../context/ClaimHomeModalErrorProvider"

const SelectOptionHomeIcon = () => {
  return (
    <div className="flex items-center">
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.5" cy="11.5" r="11.5" fill="#1A3A32"/>
          <g clipPath="url(#clip0_4225_18350)">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.1652 6.45579C11.975 6.30786 11.7409 6.22754 11.5 6.22754C11.2591 6.22754 11.025 6.30786 10.8348 6.45579L6.2935 9.98746C5.88617 10.3054 6.11042 10.9581 6.62663 10.9581H7.16667V15.2915C7.16667 15.5788 7.2808 15.8543 7.48397 16.0575C7.68713 16.2607 7.96268 16.3748 8.25 16.3748H10.9583V12.5831C10.9583 12.4395 11.0154 12.3017 11.117 12.2001C11.2186 12.0985 11.3563 12.0415 11.5 12.0415C11.6437 12.0415 11.7814 12.0985 11.883 12.2001C11.9846 12.3017 12.0417 12.4395 12.0417 12.5831V16.3748H14.75C15.0373 16.3748 15.3129 16.2607 15.516 16.0575C15.7192 15.8543 15.8333 15.5788 15.8333 15.2915V10.9581H16.3734C16.889 10.9581 17.1144 10.3054 16.7065 9.988L12.1652 6.45579Z" fill="white"/>
          </g>
        <defs>
          <clipPath id="clip0_4225_18350">
            <rect width="13" height="13" fill="white" transform="translate(5 5)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

const SelectAddAddressIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="11.5" r="11.5" fill="#E1E0E0" fillOpacity="0.4"/>
      <path d="M11.5217 6L11.5217 18" stroke="#7D7D7D" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M6 11.7393H17" stroke="#7D7D7D" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

const HomeOptionsContainer = ({
  isSelectMenuOpen,
  userProperties
}) => {
  const { setOpen } = useClaimHomeModalError()

  let propertyOptions

  const greenBgHoverColor = 'rgba(39,174,96,0.25)'
  const addAddressPaddingStyles = userProperties.length > 0 ? 'pb-[6px]' : 'py-[6px]'

  const handleOpen = () => setOpen(true)
  
  const handleSelectOptionMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const homeOptionContainer = e.target as HTMLDivElement
    const homeOptionInnerContainer = homeOptionContainer.querySelector('.homeOption') as HTMLDivElement
    const addAddressInnerContainer = homeOptionContainer.querySelector('.addAddress') as HTMLDivElement

    if (homeOptionContainer && homeOptionInnerContainer) {
      homeOptionInnerContainer.style.backgroundColor = greenBgHoverColor
      return
    }
    if (homeOptionContainer && addAddressInnerContainer) {
      addAddressInnerContainer.style.backgroundColor = greenBgHoverColor
      return
    }
  }

  const handleSelectOptionMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const homeOptionContainer = e.target as HTMLDivElement
    const homeOptionInnerContainer = homeOptionContainer.querySelector('.homeOption') as HTMLDivElement
    const addAddressInnerContainer = homeOptionContainer.querySelector('.addAddress') as HTMLDivElement

    if (homeOptionContainer && homeOptionInnerContainer) {
      homeOptionInnerContainer.style.backgroundColor = 'white'
      return
    }
    if (homeOptionContainer && addAddressInnerContainer) {
      addAddressInnerContainer.style.backgroundColor = 'white'
      return
    }
  }

  if (userProperties) {
    propertyOptions = userProperties.map((property, index) => {
      return (
        <div
          key={`${property.id}-dashboard-home-options-${index}`}
          className="homeOptionContainer px-[6px] pt-[6px] pointer-events-auto"
          onMouseEnter={(e) => handleSelectOptionMouseEnter(e)}
          onMouseLeave={(e) => handleSelectOptionMouseLeave(e)}
        >
          <div className={"homeOption flex gap-[9px] py-[7px] px-[6px] rounded-[7px] pointer-events-none"}>
            <SelectOptionHomeIcon/>
            <span>{property.fullAddress}</span>
          </div>
        </div>
      )
    })
  }

  return (
    <div
      className={(isSelectMenuOpen ? "block" : "hidden") + " absolute z-10 flex flex-col gap-2 font-jakarta-sans font-semibold text-pending-green w-[calc(100%_+_50px)] top-[40px] rounded-[9px] bg-white border border-[#8B8B8B] pointer-events-none"}
    >
      {propertyOptions}
      <div
        className={addAddressPaddingStyles + " homeOptionContainer px-[6px] pointer-events-auto"}
        onMouseEnter={(e) => handleSelectOptionMouseEnter(e)}
        onMouseLeave={(e) => handleSelectOptionMouseLeave(e)}
        onClick={handleOpen}
      >
        <div className="addAddress flex gap-[9px] py-[7px] px-[6px] rounded-[7px] overflow-hidden pointer-events-none">
          <SelectAddAddressIcon/>
          <span>Add address</span>
        </div>
      </div>
    </div>
  )
}

export default HomeOptionsContainer
/* 
  DashboardPageProvider needed because DashboardLayout is under layouts and Dashboard.tsx is under pages 
  - Needed a way to pass state from layout to the page
*/ 
import {
  ReactNode,
  createContext,
  useContext,
  useState
} from 'react'

interface DashboardPageProps {
  currentPage: string
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>
  dashboardPages: {
    HOME: string,
    COMPS: string,
    OFFERS: string
  }
}

const DashboardPageContext = createContext<DashboardPageProps>({} as DashboardPageProps)

export const useDashboardPage = () => {
  return useContext(DashboardPageContext)
}

export const DashboardPageProvider = ({ children } : { children: ReactNode }) => {
  const [currentPage, setCurrentPage] = useState('Home')
  const dashboardPages = {
    HOME: 'Home',
    COMPS: 'Comps',
    OFFERS: 'Offers'
  }

  const valueObj = {
    currentPage,
    setCurrentPage,
    dashboardPages
  }

  return (
    <DashboardPageContext.Provider value={valueObj}>
      {children}
    </DashboardPageContext.Provider>
  )
}
/* LEFT OVERLAY
    Height and width of overlays are hard-coded:
    - change height and width if carousel card height and width changes
*/

const LeftOverlay = ({ currentCarouselIndex }) => {
  const hiddenLeftOverlayStyles = currentCarouselIndex !== 0 ? "" : "invisible"

  return (
    <div className={hiddenLeftOverlayStyles ? "hidden" : " dashboardCarouselLeftOverlay absolute left-0 top-0 h-[300px] w-[30px] ease-linear delay-1000 transition z-10"}> 
    </div>
  )
}

export default LeftOverlay
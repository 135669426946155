import { useEffect } from "react";

interface CodeInputsProps {
  error: boolean;
  userInputtedCode: string[];
  setUserInputtedCode: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  SMS_INPUT_ID_ONE: string;
  SMS_INPUT_ID_TWO: string;
  SMS_INPUT_ID_THREE: string;
  SMS_INPUT_ID_FOUR: string;
  SMS_INPUT_ID_FIVE: string;
  SMS_INPUT_ID_SIX: string;
}

const SeparatorDash = ({ ...props } : JSX.IntrinsicElements['svg']) => {
  return (
    <svg {...props} viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H18" stroke="#222222" strokeWidth="1.72999"/>
    </svg>
  )
}

const CodeInputs = ({
  error,
  userInputtedCode,
  setUserInputtedCode,
  onChangeHandler,
  onKeyDownHandler,
  SMS_INPUT_ID_ONE,
  SMS_INPUT_ID_TWO,
  SMS_INPUT_ID_THREE,
  SMS_INPUT_ID_FOUR,
  SMS_INPUT_ID_FIVE,
  SMS_INPUT_ID_SIX,
}: CodeInputsProps) => {

  const codeInputStyles = `relative w-[52px] h-16 text-center border font-inter text-[25px] font-medium ${
    error ? 'bg-[#E5C0C0] !border-error-red' : 'bg-none'
  } 
  focus:outline-none focus:border-[#0675B9] focus:shadow-[0px_0px_2.59px_0.86px_rgba(6,117,185,0.24)]`;

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain').trim();
    const codeDigits = pastedText.split('').slice(0, 6);
    const updatedCode = [...userInputtedCode];

    codeDigits.forEach((digit, i) => {
      updatedCode[i] = digit;
    });
    setUserInputtedCode(updatedCode);
  };

  // If any of the inputs are about to be focused -- apply unique focus styles
  const handleInputFocusCapture = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const firstInput = document.querySelector('#sms-num-1') as HTMLInputElement
    const thirdInput = document.querySelector('#sms-num-3') as HTMLInputElement
    const fourthInput = document.querySelector('#sms-num-4') as HTMLInputElement
    const sixthInput = document.querySelector('#sms-num-6') as HTMLInputElement

    if (e.target.id === SMS_INPUT_ID_ONE) {
      firstInput.style.borderRight = '1px solid #0675B9'
    }
    if (e.target.id === SMS_INPUT_ID_TWO) {
      firstInput.style.borderRight = '1px solid #0675B9'
      thirdInput.style.borderLeft = '1px solid #0675B9'
    }
    if (e.target.id === SMS_INPUT_ID_THREE) {
      thirdInput.style.borderLeft = '1px solid #0675B9'
    }
    if (e.target.id === SMS_INPUT_ID_FOUR) {
      fourthInput.style.borderRight = '1px solid #0675B9'
    }
    if (e.target.id === SMS_INPUT_ID_FIVE) {
      fourthInput.style.borderRight = '1px solid #0675B9'
      sixthInput.style.borderLeft = '1px solid #0675B9'
    }
    if (e.target.id === SMS_INPUT_ID_SIX) {
      sixthInput.style.borderLeft = '1px solid #0675B9'
    }
  }
  
  // Reset inputs back to prior styles
  const handleInputBlurCapture = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const firstInput = document.querySelector('#sms-num-1') as HTMLInputElement
    const thirdInput = document.querySelector('#sms-num-3') as HTMLInputElement
    const fourthInput = document.querySelector('#sms-num-4') as HTMLInputElement
    const sixthInput = document.querySelector('#sms-num-6') as HTMLInputElement

    if (firstInput && thirdInput) {
      firstInput.style.borderRight = '1px solid black'
      thirdInput.style.borderLeft = '1px solid black'
      fourthInput.style.borderRight = '1px solid black'
      sixthInput.style.borderLeft = '1px solid black'
    }
  }

  // After first render - focus the first code input
  useEffect(() => {
    const firstInput = document.querySelector('#sms-num-1') as HTMLInputElement
    firstInput?.focus()
  }, [])

  return (
    <div className="flex" onPaste={handlePaste}>
      <div className='min-w-[156px]'>
        <input
          id={SMS_INPUT_ID_ONE}
          className={`${codeInputStyles} rounded-l-lg`}
          value={userInputtedCode[0]}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
        <input
          id={SMS_INPUT_ID_TWO}
          className={`${codeInputStyles} border-x-0`}
          value={userInputtedCode[1]}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
        <input
          id={SMS_INPUT_ID_THREE}
          className={`${codeInputStyles} rounded-r-lg`}
          value={userInputtedCode[2]}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
      </div>
      <div className="flex mx-[9px] items-center">
        <SeparatorDash className='w-[18px] h-[2px]'/>
      </div>
      <div className='min-w-[156px]'>
        <input
          id={SMS_INPUT_ID_FOUR}
          className={`${codeInputStyles} rounded-l-lg`}
          value={userInputtedCode[3]}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
        <input
          id={SMS_INPUT_ID_FIVE}
          className={`${codeInputStyles} border-x-0`}
          value={userInputtedCode[4]}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
        <input
          id={SMS_INPUT_ID_SIX}
          className={`${codeInputStyles} rounded-r-lg`}
          value={userInputtedCode[5]}
          onKeyUp={onKeyDownHandler} // prevents last digit from not being erased when pressing 'Backspace'
          onChange={onChangeHandler}
          onFocusCapture={(e) => handleInputFocusCapture(e)}
          onBlurCapture={(e) => handleInputBlurCapture(e)}
          maxLength={1}
        />
      </div>
    </div>
  );
};

export default CodeInputs;

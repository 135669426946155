import { useState } from 'react';
import Heading from './heading/Heading';
import SubHeading from './subheading/SubHeading';
import SignUpForm from './sign-up-form/SignUpForm';
import VerificationContent from './verification-content/VerificationContent';
import LoginCTA from './login-cta/LoginCTA';

const SIGN_UP_HEADING = 'Sign up';
const VERIFY_ACCOUNT_HEADING = 'Verify Account';

const SIGN_UP_SUBHEADING = 'Hello, create an account.';
const VERIFY_ACCOUNT_SUBHEADING = [
  // 'We will send a verification code to: ',
  'Enter the 6-digit code sent to:  ',
];

export interface User {
  isUserSignedUp: boolean;
  isUserVerified: boolean;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  sendCodeViaSMS: boolean;
}

const ClerkRegistrationContent = () => {
  const [hasCodeSent, setHasCodeSent] = useState(false);

  const [user, setUser] = useState<User>({
    isUserSignedUp: false,
    //this might not actually be used currently
    isUserVerified: false,
    phoneNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    sendCodeViaSMS: true,
  });

  return (
    <div className="tablet-signup:mr-auto">
      <div>
        <Heading>
          {user.isUserSignedUp && VERIFY_ACCOUNT_HEADING}
          {!user.isUserSignedUp && SIGN_UP_HEADING}
        </Heading>
        <SubHeading>
          {!user.isUserSignedUp && SIGN_UP_SUBHEADING}
          {user.isUserSignedUp && (
            <>
              {VERIFY_ACCOUNT_SUBHEADING[0]}
              <span className="font-bold">
                {user.phoneNumber}
                {/* {user.sendCodeViaSMS === true ? user.phoneNumber : user.email} */}
              </span>
            </>
          )}
          {/* {user.isUserSignedUp && !user.isUserVerified && (
            <>
              {!hasCodeSent
                ? VERIFY_ACCOUNT_SUBHEADING[0]
                : VERIFY_ACCOUNT_SUBHEADING[1]}
              <span className="font-bold">
                {user.sendCodeViaSMS === true ? user.phoneNumber : user.email}
              </span>
            </>
          )} */}
        </SubHeading>
      </div>
      <div className="mt-[34px] mb-6">
        {!user.isUserSignedUp && <SignUpForm user={user} setUser={setUser} />}
        {user.isUserSignedUp && (
          <VerificationContent
            hasCodeSent={hasCodeSent}
            setHasCodeSent={setHasCodeSent}
            user={user}
            setUser={setUser}
          />
        )}
      </div>
      {!user.isUserSignedUp && <LoginCTA />}
    </div>
  );
};

export default ClerkRegistrationContent;

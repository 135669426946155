import interiorHomeImg from '../../../assets/images/signupInteriorHome.webp'

const ImageDisplay = () => {
  return (
    <div className="flex justify-center items-center bg-[#F7F6F2] tablet-signup:hidden">
      <img
        className="h-full w-auto object-cover"
        alt="Large two-story beach house with green front lawn"
        src={interiorHomeImg}
      />
    </div>
  );
};

export default ImageDisplay;

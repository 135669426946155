import { useEffect, useState } from "react"

interface TimeframeNavProps {
  currentInterval: string
  setCurrentInterval: React.Dispatch<React.SetStateAction<string>>
}

const defaultIntervals = [
  'INTERVAL_1D',
  'INTERVAL_1W',
  'INTERVAL_1M',
  'INTERVAL_3M',
  'INTERVAL_1Y'
]

const MobileCarrotDownIcon = () => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.30747 8.58008L13.4538 13.1701L18.6002 8.58008L20.1811 10.0001L13.4538 16.0001L6.72656 10.0001L8.30747 8.58008Z" fill="#054D17"/>
    </svg>
  )
}

const TimeframeNav = ({
  currentInterval,
  setCurrentInterval
} : TimeframeNavProps) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const navOpenStyles = isMobileNavOpen ? 'shadow-[0px_6px_24px_rgba(0,0,0,0.2)] bg-white' : ''

  const handleDesktopNavBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const intervalText = (e.target as HTMLElement)?.textContent
    if (intervalText) setCurrentInterval(`INTERVAL_${intervalText}`)
  }

  // If user clicks outside of select menu options container, close menu
  useEffect(() => {
    const root = document.querySelector('#root')

    const handleMobileNavMenuClick = (e: Event) => {
      const isElementTimeframeOption = (e.target as HTMLElement)?.classList.contains('mobileTimeframeOption')

      // Update current interval based on user selection
      if (isElementTimeframeOption) {
        const timeframeOption = (e.target as HTMLElement)?.textContent
        setCurrentInterval(`INTERVAL_${timeframeOption}`)
      } else setIsMobileNavOpen(false)
    }

    if (isMobileNavOpen && root) root.addEventListener('click', handleMobileNavMenuClick)
    else root?.removeEventListener('click', handleMobileNavMenuClick)
    return () => root?.removeEventListener('click', handleMobileNavMenuClick)
  }, [isMobileNavOpen, setCurrentInterval])
  
  const desktopIntervalButtons = defaultIntervals.map(interval => {
    const defaultButtonStyles = 'flex items-center text-[20px] font-light py-[1px] '
    const selectedButtonStyles = currentInterval ? 
      currentInterval === interval ? 'font-bold !text-[16px] bg-pending-green font-normal text-white px-3 rounded-[8px]': ''
    : ''
    const shorthandIntervalName = interval.split('_')[1]

    return (
      <button
        key={`${interval}-button`}
        className={defaultButtonStyles + selectedButtonStyles}
        onClick={(e) => handleDesktopNavBtnClick(e)}
      >
        {shorthandIntervalName}
      </button>
    )
  })

  return (
    <>
      <div className='flex gap-4 mobile-valuation-chart:hidden'>
        {desktopIntervalButtons}
      </div>
      <div className={navOpenStyles + ` relative flex justify-between gap-[10px] max-w-[72px] w-full font-lato text-[#054D17] border-b border-[#054D17] border-dashed py-[10px] pl-[10px] pointer-events-auto cursor-default
        desktop-valuation-chart:hidden`}
        onClick={() => setIsMobileNavOpen(prev => !prev)}
      >
        {/* CHOSEN OPTION CONTAINER */}
        <div className='max-w-max pointer-events-none'>
          {currentInterval.split('_')[1]} {/* currentInterval format is currently: "INTERVAL_1W" */}
        </div>
        <div className='h-full pointer-events-none'>
          <MobileCarrotDownIcon />
        </div>

        {/* ALL TIMEFRAME OPTIONS CONTAINER */}
        {isMobileNavOpen && (
          <div className='absolute top-[45px] left-0 w-full z-10 bg-white shadow-[0px_6px_24px_rgba(0,0,0,0.2)] pointer-events-none'> {/* top-[45px] = estimated height of 'CHOSEN OPTION CONTAINER' */}
            <span className='mobileTimeframeOption block py-[12.5px] px-[10px] w-full pointer-events-auto hover:bg-pending-green hover:text-white'>1D</span>
            <span className='mobileTimeframeOption block py-[12.5px] px-[10px] w-full pointer-events-auto hover:bg-pending-green hover:text-white'>1W</span>
            <span className='mobileTimeframeOption block py-[12.5px] px-[10px] w-full pointer-events-auto hover:bg-pending-green hover:text-white'>1M</span>
            <span className='mobileTimeframeOption block py-[12.5px] px-[10px] w-full pointer-events-auto hover:bg-pending-green hover:text-white'>3M</span>
            <span className='mobileTimeframeOption block py-[12.5px] px-[10px] w-full pointer-events-auto hover:bg-pending-green hover:text-white'>1Y</span>
          </div>
        )}
      </div>
    </>
  )
}

export default TimeframeNav
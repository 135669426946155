interface TermsAndConditionsAcknowledgementProps {
  handleOpen: () => void;
}

const TermsAndConditionsAcknowledgement = ({
  handleOpen,
}: TermsAndConditionsAcknowledgementProps) => {
  return (
    <p className="font-lato font-bold text-[13px] text-[#767676]">
      By clicking continue, you agree to our
      <br></br>
      <span
        className="underline text-[#1A3A32] cursor-pointer"
        onClick={handleOpen}
      >
        Terms and Conditions
      </span>
    </p>
  );
};

export default TermsAndConditionsAcknowledgement;

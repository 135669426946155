interface CodeInputsProps {
  error: boolean;
  userInputtedCode: string[];
  setUserInputtedCode: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  SMS_INPUT_ID_ONE: string;
  SMS_INPUT_ID_TWO: string;
  SMS_INPUT_ID_THREE: string;
  SMS_INPUT_ID_FOUR: string;
  SMS_INPUT_ID_FIVE: string;
  SMS_INPUT_ID_SIX: string;
}
//shadow-[inset_rgba(0,_0,_0,_0.9)_0px_0px_2px_0px,_rgba(0,_0,_0,_0.2)_0px_30px_100px_-24px]

const CodeInputs = ({
  error,
  userInputtedCode,
  setUserInputtedCode,
  onChangeHandler,
  SMS_INPUT_ID_ONE,
  SMS_INPUT_ID_TWO,
  SMS_INPUT_ID_THREE,
  SMS_INPUT_ID_FOUR,
  SMS_INPUT_ID_FIVE,
  SMS_INPUT_ID_SIX,
}: CodeInputsProps) => {
  const codeInputStyles = `font-inter text-[25px] font-medium ${
    error ? 'bg-[#E5C0C0]' : 'bg-none'
  } 
  w-[63px] h-16 text-center border`;

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain').trim();
    const codeDigits = pastedText.split('').slice(0, 6);
    const updatedCode = [...userInputtedCode];

    codeDigits.forEach((digit, i) => {
      updatedCode[i] = digit;
    });
    setUserInputtedCode(updatedCode);
  };

  return (
    <div className="flex" onPaste={handlePaste}>
      <input
        id={SMS_INPUT_ID_ONE}
        className={`${codeInputStyles} rounded-l-lg`}
        value={userInputtedCode[0]}
        onChange={onChangeHandler}
        maxLength={1}
      />
      <input
        id={SMS_INPUT_ID_TWO}
        className={`${codeInputStyles}`}
        value={userInputtedCode[1]}
        onChange={onChangeHandler}
        maxLength={1}
      />
      <input
        id={SMS_INPUT_ID_THREE}
        className={`${codeInputStyles} rounded-r-lg mr-4`}
        value={userInputtedCode[2]}
        onChange={onChangeHandler}
        maxLength={1}
      />
      <input
        id={SMS_INPUT_ID_FOUR}
        className={`${codeInputStyles} rounded-l-lg`}
        value={userInputtedCode[3]}
        onChange={onChangeHandler}
        maxLength={1}
      />
      <input
        id={SMS_INPUT_ID_FIVE}
        className={`${codeInputStyles}`}
        value={userInputtedCode[4]}
        onChange={onChangeHandler}
        maxLength={1}
      />
      <input
        id={SMS_INPUT_ID_SIX}
        className={`${codeInputStyles} rounded-r-lg`}
        value={userInputtedCode[5]}
        onChange={onChangeHandler}
        maxLength={1}
      />
    </div>
  );
};

export default CodeInputs;

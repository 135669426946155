const ContinueButton = ({ handleContinueClick }) => {
  return (
    <button
      className="w-full bg-pending-green font-lato text-white font-bold py-[14px] px-[14px] rounded-[8px] shadow-[-1px_3px_5px_1px_rgba(0,0,0,0.32)]
        mobile-dashboard:max-w-full"
      onClick={handleContinueClick}
    >
      Continue to Registration
    </button>
  )
}

export default ContinueButton
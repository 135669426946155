const DashboardMenuLabel = ({
  children,
  id,
  label
}) => {
  return (
    <label
      className="flex align-center h-[49px] font-bold text-2xl text-pending-green
        mobile-dashboard:text-xl"
      id={id}
      aria-label={label}
    >
      <span className="my-auto text-nowrap whitespace-nowrap">
        {children}
      </span>
    </label>
  )
}

export default DashboardMenuLabel
const fetchPropertyId = async (address: string) => {
  try {
    /*
      Truncate ", United States" to promote better match
      1. BE checks db first
      2. If db does not have address, ping ReAPI
    */
    if (address.includes(', United States')) {
      address = address.split(', United States')[0]
    }
    const response = await fetch(`https://pending-backend-1.onrender.com/address_to_property_id/${address}`)
    const data = await response.json()
    return data
  } catch (err) {
    console.log(err)
    return null
  }
}

export default fetchPropertyId
const BackButton = ({
  stepNumber,
  handleBackButtonClick
}) => {
  const buttonStyles = stepNumber === 1 
  ? "max-w-[70px] w-full py-[14px] rounded-[8px] pl-10 mobile-dashboard:max-w-full mobile-dashboard:pl-0 shadow-[-1px_3px_5px_1px_rgba(0,0,0,0.32)]"
  : "bg-pending-green font-lato font-bold text-white max-w-full w-full py-[14px] rounded-[8px] px-[14px] mobile-dashboard:bg-transparent mobile-dashboard:text-pending-green mobile-dashboard:shadow-none shadow-[-1px_3px_5px_1px_rgba(0,0,0,0.32)]"

  return (
    <button
      className={buttonStyles}
      onClick={handleBackButtonClick}
    >
      Back
    </button>
  )
}

export default BackButton
interface ErrorMessageProps {
  error: boolean;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const errorStyles = `font-inter font-semibold text-[12px] text-[#D70000] ${
    !error && 'invisible'
  }`;
  return <p className={errorStyles}>Invalid or Incorrect OTP. Try Again</p>;
};

export default ErrorMessage;

import React from 'react'

const EmptyState = () => {
  return (
    <div className="flex justify-center border border-[#8B8B8B] rounded-[12px] py-[30px]">
      <p className="text-[#8B8B8B] font-semibold">Your influential comps will appear here.</p>
    </div>
  )
}

export default EmptyState
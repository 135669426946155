import HeroSection from "./hero-section/HeroSection"
import ValuePropsSection from "./value-props-section/ValuePropsSection"
import FaqsSection from "./faqs-section/FaqsSection"

const HowItWorks = () => {
  return (
    <div className="w-full font-lato mt-[-50px]">
      {/* mt-[-50px] to cancel out mt-[50px] being applied from layout */}
      <HeroSection />
      <ValuePropsSection />
      <FaqsSection />
    </div>
  )
}

export default HowItWorks

import { Outlet } from "react-router-dom"

const RightArrowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.43 5.92993L20.5 11.9999L14.43 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.5 12H20.33" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const footerLinksCopy = [
  {
    heading: 'Company',
    links: [
      {
        linkTitle: 'About',
        link: '/about'
      },
      {
        linkTitle: 'Email us',
        link: 'mailto:hello@trypending.com'
      }
    ]
  },
  {
    heading: 'Social',
    links: [
      {
        linkTitle: 'LinkedIn',
        link: 'https://www.linkedin.com/company/trypending/'
      }
    ]
  },
  {
    heading: 'Legal',
    links: [
      {
        linkTitle: 'Privacy Policy',
        link: '/privacy-policy'
      },
      {
        linkTitle: 'Terms of Use',
        link: '/terms-of-use'
      },
    ]
  },
]

const GlobalFooter = () => {
  const footerLinks = footerLinksCopy.map(section => {
    const listElements = section.links.map(currentLink => 
      <li
        key={`${section.heading}-${currentLink.linkTitle}-footer`}
        className='mobile-footer:border-b mobile-footer:border-b-[#343C3A] mobile-footer:pb-[14px]'>
        <a
          href={currentLink.link}
          target='_blank'
          rel='noreferrer'
        >
          {currentLink.linkTitle}
        </a>
      </li>
    )
    return (
      <div
        key={`${section.heading}-section-footer`}
        className='font-lato font-medium max-w-[150px] w-full
          mobile-footer:max-w-full'
      >
        <p className='text-[18px] mb-[21px]'>
          {section.heading}
        </p>
        <ul className='text-[#BABBBA] flex flex-col gap-[21px]'>
          {listElements}
        </ul>
      </div>
    )
  })

  return (
    <>
      <Outlet/>
      <footer className='py-20 pl-[147px] pr-[165px] bg-[#14201D] text-white
        mobile-footer:px-8 mobile-footer:pt-[61px] mobile-footer:pb-[157px]'
      >
        <div className='flex justify-between gap-16 w-full mb-12 max-w-[1440px]
          mobile-footer:flex-col'
        >

          {/* PENDING TEXT */}
          <p className='font-dm-serif-text text-[24px] text-center'>
            Pending
            <span className='hidden font-lato font-medium text-[14px] text-[#BABBBA]
              mobile-footer:block mobile-footer:mt-1'
            >
              All rights reserved.
            </span>
          </p>

          {/* LINKS */}
          <div className='flex gap-4 w-full justify-center
            mobile-footer:flex-col mobile-footer:gap-[34px]'
          >
            {footerLinks}
          </div>

          {/* CONNECT WITH US */}
          <div className='flex flex-col gap-[21px] font-lato w-full max-w-[297px] items-center'>
            <p className='font-medium text-[18px] mr-auto'>
              Connect with us
            </p>
            <p className='font-semibold text-[14px] text-[#BABBBA]'>
              Stay up to date on news, platform updates, and happenings.
            </p>
            <div className='relative w-full max-w-[297px]'>
              <input
                className='bg-[#283431] py-[14px] pl-[13px] w-full rounded-[7px] border border-[#A5A19D]'
                type='text'
                placeholder='Enter your email'
              />
              <div className='absolute right-0 top-[25%] mr-[9px] cursor-pointer'>
                <RightArrowIcon/>
              </div>
            </div>
          </div>
          
        </div>
        <div>
          {/* COPYRIGHT TEXT */}
          <span className='font-lato text-[#BABBBA] text-[14px]
            mobile-footer:hidden'
          >
            © 2024 Pending.  All rights reserved.
          </span>
        </div>
      </footer>
    </>
  )
}

export default GlobalFooter
const StepThreeOptions = ({
  stepThreeOption,
  setStepThreeOption,
  loadingStyles
}) => {
  const IMMEDIATELY = 'Immediately'
  const ONE_TO_THREE_MONTHS = '1 - 3 months'
  const THREE_TO_SIX_MONTHS = '3 - 6 months'
  const SIX_TO_TWELVE_MONTHS = '6 - 12 months'
  const TWELVE_PLUS_MONTHS = '12+ months'
  const IF_THE_PRICE_IS_RIGHT = 'If the price is right'

  return (
    <div className={loadingStyles + ' text-pending-green font-lato max-w-[388px] grid grid-cols-2 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 mt-[18px] mb-10'}>
      <div>
        <button
          className={`${stepThreeOption === IMMEDIATELY && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(IMMEDIATELY)}
        >
          {IMMEDIATELY}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === ONE_TO_THREE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(ONE_TO_THREE_MONTHS)}
        >
          {ONE_TO_THREE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === THREE_TO_SIX_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(THREE_TO_SIX_MONTHS)}
        >
          {THREE_TO_SIX_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === SIX_TO_TWELVE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(SIX_TO_TWELVE_MONTHS)}
        >
          {SIX_TO_TWELVE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === TWELVE_PLUS_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(TWELVE_PLUS_MONTHS)}
        >
          {TWELVE_PLUS_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === IF_THE_PRICE_IS_RIGHT && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(IF_THE_PRICE_IS_RIGHT)}
        >
          {IF_THE_PRICE_IS_RIGHT}
        </button>
      </div>
    </div>
  )
}

export default StepThreeOptions
const HeroSection = () => {
  return (
    <div className="px-16 bg-[#FFFCF9] mt-[-50px] pt-[142px] pb-[255px]
      mobile-about-us:px-5 mobile-about-us:pt-20 mobile-about-us:pb-[54px]"
    >
      <h1 className='text-[95px] text-[#222222]
        mobile-about-us:text-[56px]'
      >
        About Us
      </h1>
      <p className='text-[34px] font max-w-[680px] text-[#474747]
        mobile-about-us:text-[22px]'
      >
        We believe that if you give people better tools, they can make more confident decisions.
      </p>
    </div>
  )
}

export default HeroSection
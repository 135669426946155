import { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3';
import TimeframeNav from "./timeframe-nav/TimeframeNav"
import DeltaUpArrowIcon from "./delta-up-arrow-icon/DeltaUpArrowIcon"
import DeltaDownArrowIcon from './delta-down-arrow-icon/DeltaDownArrowIcon';
import BlurredEmptyState from './blurred-empty-state/BlurredEmptyState';
import Tooltip, { TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const ALL_INTERVALS = {
  INTERVAL_1D: 'INTERVAL_1D',
  INTERVAL_1W: 'INTERVAL_1W',
  INTERVAL_1M: 'INTERVAL_1M',
  INTERVAL_3M: 'INTERVAL_3M',
  INTERVAL_1Y: 'INTERVAL_1Y',
}

const UnderlineSVG = () => {
  return (
    <svg className='mt-[-4px]' width="131" height="1" viewBox="0 0 131 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.5" x2="131" y2="0.5" stroke="#737373" strokeDasharray="1.5 1.5"/>
    </svg>
  )
}

const HtmlTooltip = styled(({ className, ...props }) => ( // TODO: When ValuationChart.js is converted to TypeScript -- update to {...} : TooltipProps
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fffcf9',
    color: '#6B7773',
    maxWidth: 390,
    width: '100%',
    minWidth: 0,
    fontSize: 15,
    border: '1px solid #dadde9',
    padding: '19px 20px 19px 15px',
    lineHeight: 'auto',
    boxShadow: '0px 0px 7.1px rgba(175, 175, 175, 0.25)'
  },
}));

const ValuationChart = ({
  rawPropertyValue = 0
}) => {
  const [propertyValue, setPropertyValue] = useState(null)
  const [valuationPrice, setValuationPrice] = useState('0')
  const [deltaValue, setDeltaValue] = useState('0')
  const [currentInterval, setCurrentInterval] = useState(ALL_INTERVALS.INTERVAL_1W)
  const d3ContainerRef = useRef(null)
  const dataIntervalRef = useRef(null)

  const deltaValueNum = parseInt(deltaValue.replace('$',''))
  const deltaStyles = 'flex items-center justify-center gap-[4px] h-full max-w-fit max-h-[40px] rounded-full py-[10.5px] px-[16.5px] mt-[7px]'
  const deltaPositiveStyles = 'bg-[#06b53199] text-[#054D17]'
  const deltaNegativeStyles = 'bg-[#E68A84] text-[#760909]'

  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  
  const formatDateWithHours = (date) => {
    const formattedDate = formatDate(date);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${formattedDate} ${hours}:${minutes}`;
  };

  const randomAdjust = (number) => {
    const randomFactor =
      Math.random() * RANDOM_ADJUSTMENT_FACTOR * 2 - RANDOM_ADJUSTMENT_FACTOR;
    return number * (1 + randomFactor);
  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const RANDOM_ADJUSTMENT_FACTOR = 0.01;

  const RESOLUTION_MINUTES = {
    INTERVAL_1D: 49.9,
    INTERVAL_1W: 60,
    INTERVAL_1M: 360,
    INTERVAL_3M: 1440,
    INTERVAL_1Y: 1440,
  };

  const LENGTH_DAYS = {
    INTERVAL_1D: 1,
    INTERVAL_1W: 7,
    INTERVAL_1M: 30,
    INTERVAL_3M: 90,
    INTERVAL_1Y: 365,
  };
  
  const DATE_FORMAT = {
    INTERVAL_1D: formatDateWithHours,
    INTERVAL_1W: formatDateWithHours,
    INTERVAL_1M: formatDateWithHours,
    INTERVAL_3M: formatDate,
    INTERVAL_1Y: formatDate,
  };
  
  const MINUTES_IN_DAY = 1440;

  const createFakeData = (startVal, amplitude) => {
    startVal = randomAdjust(startVal);
    amplitude = randomAdjust(amplitude);
    const n_days = LENGTH_DAYS[currentInterval]; // interval state
    const n_mins = RESOLUTION_MINUTES[currentInterval]; // interval state

    let now = new Date();
    const startDate = new Date(now);
    startDate.setDate(now.getDate() - n_days);

    const n = (n_days * MINUTES_IN_DAY) / n_mins;

    const result = [];
    let currentDate = startDate;
    let currentVal = startVal;

    for (let i = 0; i < n; i++) {
      // TODO: Add check logic - check if the currentDate is not in the future
      // if (currentDate > now) {
      //   console.log('currentDate is greater than NOW')
      //   currentDate = new Date(now)
      // }
      result.push({ date: currentDate, value: Math.round(currentVal) });
      currentDate = new Date(currentDate);
      currentDate.setMinutes(currentDate.getMinutes() + n_mins);
      
      currentVal = randomAdjust(
        startVal + amplitude * Math.cos((2.5 * Math.PI * i) / n - 0.5 * Math.PI)
      );
    }
    return result;
  };

  // On initial load, populate propertyValue if raw property value exists
  useEffect(() => {
    if (rawPropertyValue && rawPropertyValue !== 0) {
      setPropertyValue(rawPropertyValue)
    }
  }, [rawPropertyValue])

  useEffect(() => {
    if (currentInterval && propertyValue && d3ContainerRef && d3ContainerRef.current) {
      const injectVis = (element) => {
        const container = d3.select(element);

        // TODO: Event listener - find a way to remove event listener from injectVis
        let mouseX = null;
        let mouseY = null;
        document.addEventListener('mousemove', (event) => {
          mouseX = event.clientX;
          mouseY = event.clientY;
        });
    
        // Create svg, tooltip text, tooltip, and chart 'g'
        const svg = container.append('svg');
        svg
          .attr('id', 'vis-svg')
          .style('width', '100%')
          .style('height', '100%')
          .append('linearGradient')
            .attr('id', 'colorGradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', 0)
            .attr('y1', '0%')
            .attr('x2', 0)
            .attr('y2', '100%')
            .selectAll('stop')
            .data([
              {
                offset: `0%`,
                color: 'black',
              },
              {
                offset: `1%`,
                color: 'black',
              },
              {
                offset: `100%`,
                color: 'white',
              },
            ])
            .join((enter) =>
              enter
                .append('stop')
                .attr('offset', (d) => d.offset)
                .attr('stop-color', (d) => d.color)
            );
        
        const ttText = container.append('div').attr('id', 'tooltip-text');
        const tooltip = svg.append('g').attr('display', 'none');
        const chart = svg.append('g');
            
        tooltip
          .append('circle')
          .attr('id', 'tooltip-circle');
        tooltip
          .append('line')
          .attr('id', 'tooltip-line');
        
        let data = createFakeData(propertyValue, 1000000)
    
        const addData = () => {
          const last = data[data.length - 1];
          const newDate = new Date(last.date);
          newDate.setMinutes(
            newDate.getMinutes() + RESOLUTION_MINUTES[currentInterval]
          );
          const newValue = randomAdjust(last.value);
          data.push({
            date: newDate,
            value: newValue,
          });
          data.shift();
          drawChart(1000);
        };
    
        let width = Math.max(container.node().getBoundingClientRect().width,200);
        let height = container.node().getBoundingClientRect().height
    
        const lineCoord = (line, x) => {
          const getPos = (len) => {
            const point = line.node().getPointAtLength(len);
            return [point.x, point.y];
          };
          let curlen = 0;
          while (getPos(curlen)[0] < x && curlen < width * 2) {
            curlen += 5;
          }
          return curlen > width * 2 ? [width, height] : getPos(curlen);
        };
    
        let tooltipValue = null;
    
        const showTooltip = (pos, line, xScale, yScale) => {
          const svgSelector = document.querySelector('#vis-svg');
          const svgPoint = new DOMPoint(pos[0], pos[1]).matrixTransform(
            svgSelector.getScreenCTM().inverse()
          );
          
          const coord = lineCoord(line, svgPoint.x);
          const tooltipDate = new Date(xScale.invert(coord[0]));
          tooltipValue = yScale.invert(coord[1]);
          
          tooltip
            .select('circle')
            .attr('fill', 'black')
            .attr('cx', coord[0] - 10)
            .attr('cy', coord[1])
            .attr('r', 10)
          tooltip
            .select('line')
            .attr('x1', coord[0] - 10)
            .attr('x2', coord[0] - 10)
            .attr('y1', height)
            .attr('y2', coord[1])
            .attr('stroke', 'black')
            .attr('stroke-width', 2);
          ttText
            .html(DATE_FORMAT[currentInterval](tooltipDate))
            .style('left', `${pos[0] + 10 - window.scrollX}px`)
            .style('top', `${pos[1] - 10 + window.scrollY}px`)
            .style('display', 'block');
          tooltip
            .style('display', 'block');

          // Whenever user hovers mouse on chart, will update valuation price
          const referenceValue =
            tooltipValue == null ? data[data.length - 1].value : tooltipValue;
          setValuationPrice(currencyFormatter.format(referenceValue));
        };
    
        const hideTooltip = () => {
          tooltip.style('display', 'none');
          tooltipValue = null;
          ttText.style('display', 'none');
        };
  
        const drawChart = (transition) => {       
          const referenceValue =
            tooltipValue == null ? data[data.length - 1].value : tooltipValue;
          setValuationPrice(currencyFormatter.format(referenceValue));
    
          const deltaValue = referenceValue - data[0].value;
          if (deltaValue < 0) {
            setDeltaValue(currencyFormatter.format(deltaValue));
          } else {
            setDeltaValue(currencyFormatter.format(deltaValue));
          }
    
          const xScale = d3
            .scaleLinear()
            .domain(d3.extent(data, (d) => d.date))
            .range([0, width]);
          const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.value)])
            .range([height, 25]);
    
          const lineGenerator = d3
            .line()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.value));
          let lineChart = chart.select('#line-chart');
          if (lineChart.empty()) {
            lineChart = chart.append('path');
          }
          lineChart
            .datum(data)
            .transition()
            .duration(transition)
            .attr('fill', 'none')
            .attr('stroke', 'black')
            .attr('stroke-width', 3)
            .attr('stroke-linejoin', 'round')
            .attr('id', 'line-chart')
            .attr('d', lineGenerator);
    
          const areaGenerator = d3
            .area()
            .y0(yScale(0))
            .curve(d3.curveLinear)
            .x((d) => xScale(d.date))
            .y1((d) => yScale(d.value));
          let areaChart = chart.select('#area-chart');
          if (areaChart.empty()) {
            areaChart = chart
              .append('path')
              .on('mouseover', () =>
                showTooltip([mouseX, mouseY], lineChart, xScale, yScale)
              )
              .on('mousemove', () =>
                showTooltip([mouseX, mouseY], lineChart, xScale, yScale)
              )
              .on('mouseout', hideTooltip);
          }
          if (tooltipValue != null) {
            showTooltip([mouseX, mouseY], lineChart, xScale, yScale);
          }
          areaChart
            .datum(data)
            .transition()
            .duration(transition)
            .attr('fill', 'url(#colorGradient)')
            .attr('opacity', '0.5')
            .attr('stroke', 'none')
            .attr('stroke-width', 0)
            .attr('id', 'area-chart')
            .attr('d', areaGenerator);
        };
        
        const handleResize = () =>  {
          width = Math.max(container.node().getBoundingClientRect().width, 200);
          height = container.node().getBoundingClientRect().height
          data = createFakeData(propertyValue, 1000000);
          drawChart(0);
        }
        window.addEventListener('resize', handleResize); // TODO: decouple event listener from injectVis function
    
        drawChart(0);
        /* 
          TODO: set interval ref so we can use it in useEffect for cleanup
          ------------------------------
          CURRENT STATE - setInterval runs every 5 minutes
        */

        dataIntervalRef.current = setInterval(() => {
          const tooltipLine = document.querySelector('#tooltip-line')
          const tooltipCircle = document.querySelector('#tooltip-circle')
          const tooltipText = document.querySelector('#tooltip-text')

          if (tooltipLine && tooltipCircle && tooltipText) {
            tooltipLine.style.visibility = 'hidden'
            tooltipCircle.style.visibility = 'hidden'
            tooltipText.style.visibility = 'hidden'
  
            addData()

            setTimeout(() => {
              tooltipLine.style.visibility = 'visible'
              tooltipCircle.style.visibility = 'visible'
              tooltipText.style.visibility = 'visible'
            }, 1300)
          } else {
            clearInterval(dataIntervalRef.current)
          }
        }, 50000);
      }

      // Clear current svg
      d3ContainerRef.current.innerHTML = ''

      // Clear current interval
      clearInterval(dataIntervalRef.current)
      
      const d3ContainerVariable = d3ContainerRef.current
      d3ContainerVariable.innerHTML = ''
      injectVis(d3ContainerVariable);

      return () => {
        clearInterval(dataIntervalRef.current)
        injectVis(d3ContainerVariable)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInterval, propertyValue])


  return (
    <div
      className="flex flex-col w-full h-full border border-[#222222] rounded-[7px] font-spectral overflow-hidden"
    >

      {/* CHART TITLE + TIMEFRAME NAVIGATION */}
      <div className='flex justify-between px-[30px] py-[18px]
        mobile-valuation-chart:px-6 mobile-valuation-chart:pb-0 mobile-valuation-chart:pt-3'
      >
        <HtmlTooltip
          tabIndex={0}
          enterTouchDelay={0}
          title={
            <p className="flex flex-col gap-4">
              <span>
                <b className="text-pending-green">The Pending Value </b> 
                {' is based on what we currently know about this home and nearby market.'}
              </span>
              <span>
                The Pending Value is not a formal appraisal or substitute for the in-person expertise of a professional appraiser.
              </span>
              <span>
                Have feedback on the Pending Value? <span className='underline'>Let us know</span>.
              </span>
            </p>
          }
        >
          <div className='flex flex-col justify-center cursor-default'>
            <h1 className='text-[20px]'>
              Pending Value
            </h1>
            <UnderlineSVG/>
          </div>
        </HtmlTooltip>
        <TimeframeNav
          ALL_INTERVALS={ALL_INTERVALS}
          currentInterval={currentInterval}
          setCurrentInterval={setCurrentInterval}
        />
      </div>

      {/* LINE BREAK */}
      <hr className='w-[98%] mx-auto text-[#B0B0B0] mobile-valuation-chart:hidden'/>
      {propertyValue && (
        <>
          {/* VALUATION PRICE + DELTA */}
          <div className='px-[30px] py-[15px] mobile-valuation-chart:px-6'>
            <span className='text-[32px]'>
              {valuationPrice}
            </span>
            <div className={`${deltaStyles} 
              ${(deltaValueNum && deltaValueNum > 0) ? deltaPositiveStyles : deltaNegativeStyles}`
            }>
              {deltaValueNum > 0 && <DeltaUpArrowIcon />}
              {deltaValueNum < 0 && <DeltaDownArrowIcon />}
              <span className='font-bold'>
                {deltaValue}
              </span>
            </div>
          </div>

          <div className='mt-auto h-full' ref={d3ContainerRef}>
          </div>
        </>
      )}
      {!propertyValue && (
        <BlurredEmptyState />
      )}
    </div>
  )
}

export default ValuationChart
const StepOneOptions = ({
  stepOneOption,
  setStepOneOption,
  loadingStyles
}) => {
  const ONE_TO_THREE_MONTHS = '1 - 3 months'
  const THREE_TO_SIX_MONTHS = '3 - 6 months'
  const SIX_TO_TWELVE_MONTHS = '6 - 12 months'
  const TWELVE_PLUS_MONTHS = '12+ months'
  const IF_THE_PRICE_IS_RIGHT = 'If the price is right'

  return (
    <div className={loadingStyles + ' text-pending-green font-lato max-w-[388px] grid grid-cols-2 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 mt-[18px] mb-10'}>
      <div>
        <button
          className={`${stepOneOption === ONE_TO_THREE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepOneOption(ONE_TO_THREE_MONTHS)}
        >
          {ONE_TO_THREE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepOneOption === THREE_TO_SIX_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepOneOption(THREE_TO_SIX_MONTHS)}
        >
          {THREE_TO_SIX_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepOneOption === SIX_TO_TWELVE_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepOneOption(SIX_TO_TWELVE_MONTHS)}
        >
          {SIX_TO_TWELVE_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepOneOption === TWELVE_PLUS_MONTHS && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepOneOption(TWELVE_PLUS_MONTHS)}
        >
          {TWELVE_PLUS_MONTHS}
        </button>
      </div>
      <div>
        <button
          className={`${stepOneOption === IF_THE_PRICE_IS_RIGHT && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepOneOption(IF_THE_PRICE_IS_RIGHT)}
        >
          {IF_THE_PRICE_IS_RIGHT}
        </button>
      </div>
    </div>
  )
}

export default StepOneOptions
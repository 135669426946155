import testimonialStars from "../../../../../assets/images/testimonialStars.svg"

const StepTwoTestimonialVisual = () => {
  return (
    <div className='relative grid grid-cols-[minmax(0px,_617px)] grid-rows-[minmax(0px,_201px)] bg-white rounded-[25px] m-10 bottom-[4rem]'>
      <div className='px-[35px] py-[45px]'>
        <p className='font-lato text-[#6B7773] text-[22px] font-[600] leading-[33px]'>
          "It's like a <span className='text-black font-[700]'>one-stop-shop</span> for my home intelligence and market data"
        </p>
        <div className='mt-[10px]'>
          <img
            src={testimonialStars}
            alt='Five yellow stars'
          />
        </div>
      </div>
    </div> 
  )
}

export default StepTwoTestimonialVisual
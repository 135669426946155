const LoginCTA = () => {
  return (
    <p className="font-lato font-bold text-[#979797]">
      Joined us before?
      <a href="/login" className="text-[#1A3A32]">
        {' '}
        Log in
      </a>{' '}
      {/* TODO: add /login once new login page has been implemented */}
    </p>
  );
};

export default LoginCTA;

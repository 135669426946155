/* RIGHT OVERLAY
    Height and width of overlays are hard-coded:
    - change height and width if carousel card height and width changes
*/

const RightOverlay = ({ currentCarouselIndex, activeProperties }) => {
  const hiddenRightOverlayStyles = currentCarouselIndex !== activeProperties.length - 1 ? "" : "invisible"

  return (
    <div className={hiddenRightOverlayStyles ? "hidden" : " dashboardCarouselRightOverlay absolute right-0 top-0 h-[300px] w-[22px] ease-linear delay-1000 transition z-10"}> 
    </div>
  )
}

export default RightOverlay
import { useEffect, useState } from "react"
import DashboardMenuLabel from "../common/dashboard-menu-label/DashboardMenuLabel"
import DashboardMenu from "../common/dashboard-menu/DashboardMenu"
import fetchPropertyComps from "../../../utils/api/fetchPropertyComps"
import CompsCard from "./comps-card/CompsCard"
import EmptyState from "./empty-state/EmptyState"
import PaginationButtons from "./pagination-buttons/PaginationButtons"

interface SingleCompObject {
  'Address': string
  'Bathrooms': number
  'Bedrooms': number
  'Distance': number
  'Property Type': string
  'Sale Date': string
  'Sold Price': string
  'Sqft': string

}

const Comps = ({ userProperties }) => {
  const [currentPropertyValue, setCurrentPropertyValue] = useState(0) // needed for DashboardMenu logic to work
  const [currentCompsAddress, setCurrentCompsAddress] = useState('')
  const [compsData, setCompsData] = useState<SingleCompObject[] | []>([])
  const [currentPage, setCurrentPage] = useState(1)

  let compsCards: (JSX.Element | null)[] | null = null

  const handleForwardButtonClick = () => setCurrentPage(prev => prev + 1)
  
  const handlePreviousButtonClick = () => setCurrentPage(prev => prev - 1)

  // Fetch comps and store in local state
  useEffect(() => {
    if (currentCompsAddress && currentCompsAddress !== '') {
      const storeCompsData = async () => {
        const compsData = await fetchPropertyComps(currentCompsAddress)
        if (compsData) {
          const filteredComps = compsData.filter(comp => comp['Sold Price'] !== '0')
          setCompsData(filteredComps)
        }
      }
      storeCompsData()
    }
  }, [currentCompsAddress])

  if (compsData.length > 0) {
    compsCards = compsData.map((comp, index): JSX.Element | null => {
      if (currentPage === 1  && index < currentPage * 4) {
        return (
          <CompsCard
            key={`${comp['Address']}-${index}`}
            comp={comp}
          />
        )
      } 
      if (currentPage === 2 && index >= 4 && index < currentPage * 4) {
        return (
          <CompsCard
            key={`${comp['Address']}-${index}`}
            comp={comp}
          />
        )
      }
      return null 
    })
  }

  return (
    <section className="flex flex-col items-center w-full h-full mt-[60px] mb-[91px] font-lato px-16">
      <div className="max-w-[1146px] w-full h-full">
        <div className="relative flex gap-5 h-max max-w-[470px] w-full">
          <DashboardMenuLabel
            id="your-influential-comps-label"
            label="Your Influential Comps"
          >
            Your Influential Comps
          </DashboardMenuLabel>
          <DashboardMenu
            isProperties={true}
            userProperties={userProperties}
            setCurrentPropertyValue={setCurrentPropertyValue}
            setCurrentCompsAddress={setCurrentCompsAddress}
          />
        </div>
        <p className="text-[#6B7773] text-[18px] mb-[26px] mt-2">
          See which homes are most similar to yours.
        </p>
        {compsData.length === 0 && (
          <EmptyState />
        )}
        {compsData.length > 0 && (
          <div>
            <div className="flex flex-col gap-4">
              {compsCards}
            </div>
            <PaginationButtons
              currentPage={currentPage}
              handleForwardButtonClick={handleForwardButtonClick}
              handlePreviousButtonClick={handlePreviousButtonClick}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Comps
const ENDPOINT = 'https://pending-backend-1.onrender.com/user/';

interface FormattedUser {
  clerkuserid: string | null;
  userid: number | null;
  emailaddress: string | null;
  firstname: string | null;
  lastname: string | null;
  phonenumber: string | null;
  role: 'buyer' | 'seller' | 'buy_sell' | null;
  sessionid: string | null;
}

const updateUser = async (formattedUser: FormattedUser) => {
  try {
    console.log('attempting to update user...');
    const userEndpoint = ENDPOINT + `${formattedUser.userid}`;
    const body = JSON.stringify({
      clerkuserid: formattedUser.clerkuserid,
      emailaddress: formattedUser.emailaddress,
      firstname: formattedUser.firstname,
      lastname: formattedUser.lastname,
      phonenumber: formattedUser.phonenumber,
      role: formattedUser.role,
      sessionid: formattedUser.sessionid,
    });

    const response = await fetch(userEndpoint, {
      method: 'PUT',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    //TODO: handle unique responses
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export default updateUser;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { PropertyDataProvider } from './context/propertyDataContext';
import { UserDataProvider } from './context/UserDataProvider';
import { DashboardPageProvider } from './context/DashboardPageProvider';
import { ClerkProvider } from '@clerk/clerk-react';
import { ClaimHomeModalErrorProvider } from './context/ClaimHomeModalErrorProvider';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key for Clerk Auth');
}

ReactDOM.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <DashboardPageProvider>
        <ClaimHomeModalErrorProvider>
          <UserDataProvider>
            <PropertyDataProvider>
              <Router>
                <App />
              </Router>
            </PropertyDataProvider>
          </UserDataProvider>
        </ClaimHomeModalErrorProvider>
      </DashboardPageProvider>
    </ClerkProvider>
    <CssBaseline />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

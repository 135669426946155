import React from 'react';
import { User } from '../RegistrationContent';
import { useSignUp } from '@clerk/clerk-react';

type SignUpUser = Omit<User, 'sendCodeViaSMS' | 'isUserVerified'>;

interface SignUpFormProps {
  user: SignUpUser;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ user, setUser }) => {
  const { isLoaded, signUp } = useSignUp();

  const inputStyles =
    'font-lato text-[18px] rounded-lg border border-[#333333] px-4 py-2 placeholder:text-[#A7A7A7] placeholder:font-lato placeholder:text-[18px]';

  const submitFormHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isLoaded) {
      console.log('not loaded. Figure this out!!');
      return;
    }

    try {
      await signUp.create({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
      });

      await signUp?.preparePhoneNumberVerification();

      setUser((prevUser) => ({
        ...prevUser,
        isUserSignedUp: true,
      }));
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      if (err.status === 422) alert(err.errors[0].message);
    }
  };

  return (
    <form id="sign-up" onSubmit={(e) => submitFormHandler(e)}>
      {/* TODO: change grid rows to 4 when adding mobile back */}
      <div className="grid grid-rows-3 gap-[14px] max-w-[248px]">
        <input
          required
          className={inputStyles}
          type="text"
          name="firstName"
          placeholder="First name*"
          value={user.firstName}
          onChange={(e) =>
            setUser((prevUser) => ({ ...prevUser, firstName: e.target.value }))
          }
        />
        <input
          required
          className={inputStyles}
          type="text"
          name="lastName"
          placeholder="Last name*"
          value={user.lastName}
          onChange={(e) =>
            setUser((prevUser) => ({ ...prevUser, lastName: e.target.value }))
          }
        />
        <input
          required
          className={inputStyles}
          type="text"
          name="email"
          placeholder="Email*"
          value={user.email}
          onChange={(e) =>
            setUser((prevUser) => ({ ...prevUser, email: e.target.value }))
          }
        />
        {/* TODO: Removing mobile option until sms OTP on sign in is resolved */}
        <input
          required
          className={inputStyles}
          type="tel"
          name="phoneNumber"
          placeholder="Mobile*"
          minLength={10}
          maxLength={12}
          value={user.phoneNumber}
          onChange={(e) => {
            let formattedNumber = e.target.value;

            if (
              !formattedNumber.startsWith('+1') &&
              formattedNumber.length === 10
            ) {
              formattedNumber = '+1' + formattedNumber;
            }
            setUser((prevUser) => ({
              ...prevUser,
              phoneNumber: formattedNumber,
            }));
          }}
        />
      </div>
      <div className="mt-6">
        <button className="font-jakarta-sans text-white font-semibold px-[44.5px] py-[14px] rounded-lg bg-[#1A3A32]">
          Next
        </button>
      </div>
    </form>
  );
};

export default SignUpForm;

const CompsCard = ({ comp }) => {
  return (
    <div
      className='grid grid-cols-[0.6fr_0.1fr_0.25fr_0.2fr_0.18fr_0.05fr_0.05fr_0.1fr] gap-9 w-full font-roboto font-medium border border-[#8B8B8B] rounded-[12px] py-4 px-6'
    >
      <div>
        <p className="text-[#6B7773]">
          Address
        </p>
        <div>
          <p className="text-pending-green">
            {comp['Address']}
          </p>
        </div>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Distance
        </p>
        <p className="text-pending-green">
          {comp['Distance']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Property Type
        </p>
        <p className="text-pending-green">
          {comp['Property Type']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Sold Price
        </p>
        <p className="text-pending-green">
          {comp['Sold Price']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Sold Date
        </p>
        <p className="text-pending-green">
          {comp['Sale Date']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Bed
        </p>
        <p className="text-pending-green">
          {comp['Bedrooms']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Bath
        </p>
        <p className="text-pending-green">
          {comp['Bathrooms']}
        </p>
      </div>
      <div>
        <p className="font-roboto font-medium text-[#6B7773]">
          Sqft
        </p>
        <p className="text-pending-green">
          {comp['Sqft']}
        </p>
      </div>
    </div>
  )
}

export default CompsCard
const StepThreeOptions = ({
  stepThreeOption,
  setStepThreeOption,
  loadingStyles
}) => {
  const ONE_MIL_TO_THREE_MIL = '$1M - $3M'
  const THREE_MIL_TO_FIVE_MIL = '$3M - $5M'
  const FIVE_MIL_TO_TEN_MIL = '$5M - $10M'
  const TEN_MIL_TO_FIFTEEN_MIL = '$10M - $15M'
  const TWENTY_PLUS_MIL = '$20M+'

  return (
    <div className={loadingStyles + ' text-pending-green font-lato max-w-[388px] grid grid-cols-2 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 mt-[18px] mb-10'}>
      <div>
        <button
          className={`${stepThreeOption === ONE_MIL_TO_THREE_MIL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => {
            setStepThreeOption(ONE_MIL_TO_THREE_MIL)
          }}
        >
          {ONE_MIL_TO_THREE_MIL}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === THREE_MIL_TO_FIVE_MIL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(THREE_MIL_TO_FIVE_MIL)}
        >
          {THREE_MIL_TO_FIVE_MIL}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === FIVE_MIL_TO_TEN_MIL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(FIVE_MIL_TO_TEN_MIL)}
        >
          {FIVE_MIL_TO_TEN_MIL}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === TEN_MIL_TO_FIFTEEN_MIL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(TEN_MIL_TO_FIFTEEN_MIL)}
        >
          {TEN_MIL_TO_FIFTEEN_MIL}
        </button>
      </div>
      <div>
        <button
          className={`${stepThreeOption === TWENTY_PLUS_MIL && 'border-black bg-[#DADADA]'} h-full w-full text-lg border-2 border-[#D9D9D9] rounded-[8px] text-center placeholder-black transition-all`}
          onClick={() => setStepThreeOption(TWENTY_PLUS_MIL)}
        >
          {TWENTY_PLUS_MIL}
        </button>
      </div>
    </div>
  )
}

export default StepThreeOptions
import RegistrationView from './registration-view/RegistrationView';
import ImageDisplay from './image-display/ImageDisplay';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const SignUp = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="grid grid-cols-2 min-h-screen w-screen tablet-signup:flex">
      <RegistrationView />
      <ImageDisplay />
    </div>
  );
};

export default SignUp;

import PendingLogo from "../../../../components/customComponents/pendingLogo/PendingLogo"
import StepOneOptions from "./step-one-options/StepOneOptions"
import StepTwoOptions from "./step-two-options/StepTwoOptions"
import StepThreeOptions from "./step-three-options/StepThreeOptions"
import StepFourOptions from "./step-four-options/StepFourOptions"
import StepFiveInput from "./step-five-input/StepFiveInput"
import NextButton from "./next-button/NextButton"
import ProgressBar from "./progress-bar/ProgressBar"
import personImageOne from "../../../../assets/images/onboardingPersonImage6.png"
import personImageTwo from "../../../../assets/images/onboardingPersonImage3.png"
import personImageThree from '../../../../assets/images/onboardingPersonImage4.png'
import personImageFour from '../../../../assets/images/onboardingPersonImage5.png'
import personImageFive from '../../../../assets/images/onboardingPersonImage7.png'

const StepContent = ({
  stepNumber,
  stepOneOption,
  stepTwoOption,
  stepThreeOption,
  stepFourOption,
  address,
  setAddress,
  setStepOneOption,
  setStepTwoOption,
  setStepThreeOption,
  setStepFourOption,
  isButtonDisabled,
  handleButtonClick,
  toggleSubmitButtonDisable,
  loadingStyles,
  firstLoadStyles
}) => {

  return (
    <div className={firstLoadStyles + ' flex flex-col gap-[91px] p-[85px] h-full tablet-onboarding:min-h-screen tablet-onboarding:w-[39rem] tablet-onboarding:items-center mobile-onboarding:px-[27px]'}>
      
      {/* LOGO */}
      <div className='min-w-[126px] min-h-[34px] tablet-onboarding:hidden'>
        <PendingLogo />
      </div>

      <div>
        {/* PERSON ICON */}
        <div className={loadingStyles + ' flex min-w-[103px] min-h-[103px] max-w-[103px] max-h-[103px] mb-4'}>
          {stepNumber === 1 && <img src={personImageOne} alt='Adult woman smiling with a light beige background'/>}
          {stepNumber === 2 && <img src={personImageTwo} alt='Adult woman smiling with a light beige background'/>}
          {stepNumber === 3 && <img src={personImageThree} alt='Middle-aged man smiling with a dark tan background'/>}
          {stepNumber === 4 && <img src={personImageFour} alt='Older man smiling with a light gray background'/>}
          {stepNumber === 5 && <img src={personImageFive} alt='Older man smiling with a light gray background'/>}
        </div>

        {/* HEADING */}
        <h1 className={loadingStyles + ' text-pending-green font-lato font-bold text-[36px] leading-[140%] tracking-[-1px] max-w-[440px] mobile-onboarding:text-[32px]'}>
          {stepNumber === 1 && <>As an owner, when do you hope to sell?</>}
          {stepNumber === 2 && <>As a buyer, where are you looking?</>}
          {stepNumber === 3 && <>As a buyer, when are you looking to buy?</>}
          {stepNumber === 4 && <>As a buyer, what's your price range?</>}
          {stepNumber === 5 && <>What's the address of the home you may want to sell?</>}
        </h1>

        {/* STEP 1: "As an owner, when do you hope to sell?" button options */}
        {stepNumber === 1 && (
          <StepOneOptions
            stepOneOption={stepOneOption}
            setStepOneOption={setStepOneOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 2: "As a buyer, where are you looking?" button options */}
        {stepNumber === 2 && (
          <StepTwoOptions
            stepTwoOption={stepTwoOption}
            setStepTwoOption={setStepTwoOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 3: "As a buyer, when are you looking to buy?" button options */}
        {stepNumber === 3 && (
          <StepThreeOptions
            stepThreeOption={stepThreeOption}
            setStepThreeOption={setStepThreeOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 4: "As a buyer, what's your price range?" button options */}
        {stepNumber === 4 && (
          <StepFourOptions
            stepFourOption={stepFourOption}
            setStepFourOption={setStepFourOption}
            loadingStyles={loadingStyles}
          />
        )}

        {/* STEP 5: "What's the address of the home you may want to sell?" address input */}
        {stepNumber === 5 && (
          <StepFiveInput
            address={address}
            setAddress={setAddress}
            toggleSubmitButtonDisable={toggleSubmitButtonDisable}
            loadingStyles={loadingStyles}
          />
        )}

        {/* NEXT BUTTON + PROGRESS BAR */}
        <div className='flex justify-between gap-2 max-w-[448px]'>
          <NextButton
            handleButtonClick={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
          />
          <ProgressBar stepNumber={stepNumber}/>
        </div> 
      </div>
    </div>
  )
}

export default StepContent
import { useState } from 'react';
import UserAddressInput from '../../../components/customComponents/userAddressInput/UserAddressInput';
import HomeHeroImageDesktop from '../../../assets/images/homeHeroDesktopImageV2.png'
import HomeHeroImageMobile from '../../../assets/images/homeHeroMobileImageV2.png'
import { useNavigate } from 'react-router-dom';
import { usePropertyData } from '../../../context/propertyDataContext';

const HeroContent = () => {
  const [address, setAddress] = useState<string | undefined>('');
  const { propertyLat, propertyLong } = usePropertyData()

  const navigate = useNavigate();

  const handleSearchClick = (address: string | undefined) => {
    if (address && propertyLat && propertyLong) {
      navigate(`/property-view?address=${encodeURIComponent(address)}&lat=${propertyLat}&lng=${propertyLong}`)
    }
  }

  return (
    <div className="flex w-full justify-center
      mobile-home:flex-col mobile-home:items-center mobile-home:pb-20"
    >
      <div className="flex flex-col pl-12 pr-20 mt-[38px]
        mobile-home:mt-0 mobile-home:px-4"
      >
        <h1 className="mb-6 max-w-[680px] text-6xl font-lato text-[72px] text-pending-green
          tablet-home:text-[54px]"
        >
          Buy, Sell, and Discover Homes, Privately.
        </h1>
        <p className="mb-[59px] text-[28px] font-lato text-[#474747] max-w-[540px] leading-tight
          tablet-home:text-[24px] mobile-home:mb-[34px]"
        >
          The simple way to explore transactions.
        </p>
        <div className="mb-[79px] w-full max-w-[540px]
        tablet-home:max-w-full">
          <UserAddressInput
            address={address}
            setAddress={setAddress}
            searchButtonExists={true}
            searchButtonClickHandler={handleSearchClick}
            height='68px'
          />
        </div>
      </div>
      <div className="max-w-[720px] w-full
        mobile-home:px-4"
      >
        <img
          src={HomeHeroImageDesktop}
          alt='Location and valuation of an off-market home.'
          className="mobile-home:hidden relative object-cover w-full h-auto top-[-80px]"
        />
        <img
          src={HomeHeroImageMobile}
          alt='Location and valuation of an off-market home.'
          className="mobile-home:block hidden relative object-cover w-full h-auto right-7"
        />
      </div>
    </div>
  );
};

export default HeroContent;

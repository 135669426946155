const Content = () => {
  return (
    <div className="flex flex-col items-center text-[#474747] px-[70px] pt-[151px] pb-[269px]
      mobile-about-us:px-5 mobile-about-us:pt-[70px] mobile-about-us:pb-[113px]"
    >
      <div className="max-w-[1028px]">
        <h2 className="text-[44px] font-semibold mb-8 max-w-[1028px]
          mobile-about-us:text-[32px] mobile-about-us:mb-5"
        >
          Transforming real estate.
        </h2>
        <div className="flex flex-col gap-8 text-[22px] max-w-[1028px]
          mobile-about-us:text-[20px] mobile-about-us:gap-7"
        >
          <p>We have worked first-hand with high net worth individuals, families, and their financial advisors who have struggled to really unlock the value of their property. Whether it’s assessing market value, or exploring potential transactions… outlining a real estate strategy has been far too difficult and complicated.</p>
          <p>We built Pending because we believe better tools lead to more confident decisions and better outcomes.</p>
          <p>Our team is comprised of luxury real estate advisors, data scientists, and software engineers. We are a hugely well connected and fully-integrated team, who have advised and transacted in some of the world most high end property markets.</p>
          <p>The customer is at the center of our company. We are obsessed with listening, engaging, and solving the problems of our customers, who entrust us with their homes, which are often their largest asset. Speed is crucial in business, so we make every effort to gather feedback and continuously enhance our product.</p>
          <p>Pending is backed by both institutional and angel investors. We are grateful for the support of Silicon Valley and Operator Angels.</p>
        </div>
      </div>
    </div>
  )
}

export default Content
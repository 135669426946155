import updateUser from './updateUser';

const postLoginEvent = async (user, sessionId) => {
  const requestBody = {
    clerkuserid: user.clerkuserid,
    role: user.role,
    userid: user.userid,
    sessionid: sessionId,
  };

  const formattedUser = {
    clerkuserid: user.clerkuserid,
    userid: user.userid,
    emailaddress: user.emailaddress,
    firstname: user.firstname,
    lastname: user.lastname,
    phonenumber: user.phonenumber,
    role: user.role,
    sessionid: sessionId,
  };

  if (requestBody.userid && sessionId) {
    try {
      updateUser(formattedUser);
      const ENDPOINT = `https://pending-backend-1.onrender.com/login/`;
      const response = await fetch(ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.status);
      const data = await response.json();
      // console.log(data);
      // console.log('SUCCESS! User Login Recorded!: ', data);
    } catch (err) {
      console.error(err);
    }
  }
};

export default postLoginEvent;

const LocationOptionsContainer = ({
  isSelectMenuOpen,
  allAvailableLocations
}) => {
  let locationOptions

  const greenBgHoverColor = 'rgba(39,174,96,0.25)'
  
  const handleSelectOptionMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const homeOptionContainer = e.target as HTMLDivElement
    const homeOptionInnerContainer = homeOptionContainer.querySelector('.locationOption') as HTMLDivElement

    if (homeOptionContainer && homeOptionInnerContainer) {
      homeOptionInnerContainer.style.backgroundColor = greenBgHoverColor
    }
  }

  const handleSelectOptionMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const homeOptionContainer = e.target as HTMLDivElement
    const homeOptionInnerContainer = homeOptionContainer.querySelector('.locationOption') as HTMLDivElement

    if (homeOptionContainer && homeOptionInnerContainer) {
      homeOptionInnerContainer.style.backgroundColor = 'white'
    }
  }

  if (allAvailableLocations) {
    locationOptions = allAvailableLocations.map((location, index) => {
      if (index < allAvailableLocations.length - 1) {
        return (
          <div
            key={`${location}-dashboard-location-options`}
            className="locationOptionContainer px-[6px] pt-[6px] pointer-events-auto"
            onMouseEnter={(e) => handleSelectOptionMouseEnter(e)}
            onMouseLeave={(e) => handleSelectOptionMouseLeave(e)}
          >
            <div className="locationOption flex gap-[9px] py-[7px] px-[6px] rounded-[7px] pointer-events-none">
              <span>{location}</span>
            </div>
          </div>
        ) 
      } else return (
        <div
            key={`${location}-dashboard-location-options`}
            className="locationOptionContainer px-[6px] py-[6px] pointer-events-auto"
            onMouseEnter={(e) => handleSelectOptionMouseEnter(e)}
            onMouseLeave={(e) => handleSelectOptionMouseLeave(e)}
          >
            <div className="locationOption flex gap-[9px] py-[7px] px-[6px] rounded-[7px] pointer-events-none">
              <span>{location}</span>
            </div>
          </div>
      )
    })
  }

  return (
    <div
      className={(isSelectMenuOpen ? "block" : "hidden") + 
      " absolute z-40 flex flex-col gap-2 font-jakarta-sans font-semibold text-pending-green w-full top-[46px] rounded-[9px] bg-white border border-[#8B8B8B] pointer-events-none "
      }
    >
      {locationOptions}
    </div>
  )
}

export default LocationOptionsContainer
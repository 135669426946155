const fetchPropertyValue = async (propertyId: number) => {
  try {
    const response = await fetch(`https://pending-backend-1.onrender.com/property_value/${Number(propertyId)}`)
    const data = await response.json()
    return data
  } catch (err) {
    console.log(err)
    return null
  }
}

export default fetchPropertyValue
import { Outlet } from "react-router-dom"

const SplitScreenLayout = () => {
  return (
    <main className='grid grid-cols-2 min-h-screen w-screen tablet-onboarding:flex tablet-onboarding:justify-center'>
      <Outlet/>
    </main>
  )
}

export default SplitScreenLayout
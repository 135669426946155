import StepOneToggleVisual from "../../homeowner-view/step-visuals/step-one-toggle-visual/StepOneToggleVisual"
import ToggleVisual from "../../../../components/onboarding/ToggleVisual"
import TestimonialVisual from "../../../../components/onboarding/TestimonialVisual"
import StepThreeListingsVisual from "./step-three-listings-visual/StepThreeListingsVisual"
import propertyImage from "../../../../assets/images/onboardingImage2.png"

const StepVisuals = ({ stepNumber, loadingStyles }) => {
  return (
    <div
      className={loadingStyles + ' flex justify-center items-center bg-[#F7F6F2] tablet-onboarding:hidden'}
    >
      {stepNumber === 1 && (
        <StepOneToggleVisual/>
      )}
      {stepNumber === 2 && (
        <ToggleVisual
          withPendingText="Don't compete for your dream home. Make an offer before it's listed. We'll deliver your non-binding offer, even if the house isn't listed for sale."
          withoutPendingText="Without Pending, the private market is offline, fragmented, and broken."
          withPendingBulletPoints={{
            pointOne: 'Find your dream home',
            pointTwo: 'Submit an offer',
            pointThree: 'Full-time, dedicated advisor'
          }}
        />
      )}
      {stepNumber === 3 && (
        <TestimonialVisual/>
      )}
      {stepNumber === 4 && (
        <StepThreeListingsVisual/>
      )}
      {stepNumber === 5 && (
        <img
          src={propertyImage}
          alt='Luxurious property overlooking the mountains'
          className='h-full object-cover'
        />
      )}
    </div>
  )
}

export default StepVisuals
import { styled } from "@mui/material"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"

const VerificationIcon = ({ ...props } : JSX.IntrinsicElements['svg']) => {
  return (
    <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C13.7614 21 16.2614 19.8807 18.0711 18.0711C19.8807 16.2614 21 13.7614 21 11C21 8.2386 19.8807 5.7386 18.0711 3.92893C16.2614 2.11929 13.7614 1 11 1C8.2386 1 5.7386 2.11929 3.92893 3.92893C2.11929 5.7386 1 8.2386 1 11C1 13.7614 2.11929 16.2614 3.92893 18.0711C5.7386 19.8807 8.2386 21 11 21Z" fill="#1A3A32" stroke="#DFF2E4" strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M7 11L10 14L16 8" fill="#1A3A32"/>
      <path d="M7 11L10 14L16 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const LocationIcon = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99293 0.913086C2.68838 0.913086 0 3.3762 0 6.40377C0 11.2722 5.39253 15.1038 5.62212 15.2646L5.99297 15.5243L6.36358 15.2646C6.59316 15.1038 11.9859 11.2722 11.9859 6.40377C11.9859 3.37616 9.29752 0.913086 5.99293 0.913086ZM5.99249 14.0944C4.81137 13.1632 1.22105 10.033 1.22105 6.40377C1.22105 3.993 3.3614 2.0318 5.99293 2.0318C8.62398 2.0318 10.7648 3.99304 10.7648 6.40377C10.7648 10.0252 7.1731 13.1618 5.99249 14.0944Z" fill="#333333"/>
      <path d="M6.09935 9.13203C4.45985 9.13203 3.12598 7.91266 3.12598 6.41379C3.12598 4.91484 4.45981 3.69531 6.09935 3.69531C7.73885 3.69531 9.07268 4.9148 9.07268 6.41379C9.07268 7.91266 7.73885 9.13203 6.09935 9.13203ZM6.09935 4.58685C4.9975 4.58685 4.10106 5.40644 4.10106 6.41383C4.10106 7.4211 4.9975 8.24058 6.09935 8.24058C7.20115 8.24058 8.0976 7.4211 8.0976 6.41383C8.0976 5.4064 7.2012 4.58685 6.09935 4.58685Z" fill="#333333"/>
    </svg>
  )
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#6B7773',
    width: 'max-content',
    maxWidth: '299px',
    padding: '16px',
    border: "1px solid #E7E7E7",
    borderRadius: "8px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    fontSize: "medium",
    "&::before": {
        backgroundColor: 'white',
        border: "1px solid #E7E7E7"
      }
  },
}));

const CarouselCard = ({
  handleModalOpen,
  property,
  index,
  lastCardIndex
}) => {
  const firstCardExtraPadding = index === 0 ? 'ml-4' : ''
  const lastCardExtraPadding = index === lastCardIndex ? ' mr-4' : ''

  return (
    <>
      {/* MOBILE */}
      <div className={firstCardExtraPadding + lastCardExtraPadding + " hidden grid-rows-[1fr,_0.7fr] grid-cols-[minmax(271px,_271px)] max-h-[218px] snap-center bg-white rounded-[10px] shadow-[0_4px_30px_0px_rgba(0,0,0,0.08)] mobile-dashboard:grid"}
      >

        {/* TOP HALF */}
        <div className="relative w-inherit max-h-[141px] rounded-[10px] rounded-b-none overflow-hidden">
          <img
            src={property.image}
            className='h-inherit w-inherit min-h-full min-w-full object-cover'
            alt=''
          />
          <CustomTooltip title={
            <div className="text-[15px]">
              <span className="text-pending-green font-bold">Open to offers </span>
              <span className="font-normal">Homeowner is actively reviewing purchase offers</span>
            </div>
            } 
            arrow
          >
            <div className="absolute top-0 z-30 mt-2 mx-3 bg-[#DFF2E4] font-roboto font-medium text-[10px] text-[#27AE60] py-[6px] px-3 rounded-[12px] cursor-default">
              <p className="font-roboto text-[10px] font-medium">
                Open to offers
              </p>
            </div>
          </CustomTooltip>
          <div className="absolute z-20 top-0 flex w-full h-full justify-center items-center backdrop-blur-[24px]">
            <p
              className="font-jakarta-sans text-white text-[12px] underline underline-offset-2 decoration-1 cursor-pointer"
              onClick={handleModalOpen}
            >
              Locked
            </p>
          </div>
        </div>

        {/* BOTTOM HALF */}
        <div className="flex flex-col gap-1 px-3 py-[10px]">

          {/* AFFECTIONATE TITLE + ICON */}
          <div className="flex gap-1 items-center">
            <p className="font-roboto font-medium text-[#333333]">
              {property.affectionateTitle}
            </p>
            <CustomTooltip
              title={
                <div className="text-[15px]">
                  <span className="text-pending-green font-bold">Verified </span>
                  <span className="font-normal">The homeowner has a verified account on the Pending platform.</span>
                </div>
              }
              arrow
              >
              <>
                <VerificationIcon
                  width='16'
                  height='16'
                />
              </>
            </CustomTooltip>
          </div>
          
          <div className='flex gap-4'>

            {/* PROPERTY LOCATION DETAILS */}
            <div className="flex flex-col justify-center gap-[9px] mt-auto">
              <div className="flex gap-1">
                <div className="flex w-[9px] h-3">
                  <LocationIcon />
                </div>
                <p className="text-[12px]">
                  {property.location}
                </p>
              </div>

              {/* TWO GRAY CENSOR DIVS */}
              <div className="flex gap-3">
                <div className="bg-[#EAE9E9] w-full max-w-[56px] h-[11px] rounded-[50px]"></div>
                <div className="bg-[#EAE9E9] w-full max-w-[56px] h-[11px] rounded-[50px]"></div>
              </div>
            </div>
            
            {/* PRICE GUIDE */}
            <div className="font-robot leading-tight ml-auto mt-auto">
              <p className="text-[14px] font-bold">${property.guidePrice}</p>
              <p className="text-[10px] font-light text-right">/guide price</p>
            </div>
          </div>

        </div>
      </div>

      {/* DESKTOP */}
      <div
        className="grid grid-cols-[0.8fr_1fr] bg-white shadow-[0_4px_30px_0px_rgba(0,0,0,0.08)] rounded-[20px] py-6 px-8 gap-6 mr-8
          mobile-dashboard:hidden"
      >
        <div className="relative w-[200px] max-h-[209px] rounded-[10px] overflow-hidden">
          <img
            src={property.image}
            className='h-inherit w-inherit min-h-full min-w-full object-cover'
            alt=''
          />

          {/* BOTTOM OVERLAY */}
          <div className="absolute h-1/2 w-full bottom-0 bg-gradient-to-t from-[#333333] to-transparent"></div>

          <div className="absolute z-20 top-0 flex w-full h-full justify-center items-center backdrop-blur-[24px]">
            <p
              className="font-jakarta-sans text-white underline underline-offset-2 decoration-1 cursor-pointer"
              onClick={handleModalOpen}
            >
              Locked
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-[330px]">

          <div>
            {/* PROPERTY TITLE */}
            <div className="flex gap-[7px] items-center">
              <p className="font-roboto text-[24px] font-medium text-[#333333]">
                {property.affectionateTitle}
              </p>
              <CustomTooltip
                title={
                  <div className="text-[15px]">
                    <span className="text-pending-green font-bold">Verified </span>
                    <span className="font-normal">The homeowner has a verified account on the Pending platform.</span>
                  </div>
                }
                arrow
              >
                <>
                  <VerificationIcon
                    width='24'
                    height='24'
                  />
                </>
              </CustomTooltip>
            </div>

            {/* PROPERTY LOCATION DETAILS */}
            <div className="flex items-center">
              <div className="flex mr-[5px]">
                <LocationIcon />
              </div>
              {/* GRAY CENSOR DIV */}
              <div className="bg-[#EAE9E9] w-full max-w-[91px] h-[11px] rounded-[50px]"></div>
              <p>, {property.location}</p>
            </div>
          </div>

          {/* TWO GRAY CENSOR DIVS */}
          <div className="flex flex-col gap-3 mt-auto">
            <div className="bg-[#EAE9E9] w-full max-w-[201px] h-[11px] rounded-[50px]"></div>
            <div className="bg-[#EAE9E9] w-full max-w-[133px] h-[11px] rounded-[50px]"></div>
          </div>

          {/* OPEN TO OFFERS BANNER */}
          <CustomTooltip title={
            <div className="text-[15px]">
              <span className="text-pending-green font-bold">Open to offers </span>
              <span className="font-normal">Homeowner is actively reviewing purchase offers</span>
            </div>
            } 
            arrow
          >
            <div className="max-w-[114px] text-center py-2 bg-[#DFF2E4] text-[#27AE60] rounded-[10px] cursor-default">
              <p className="font-roboto text-[10px] font-medium">
                Open to offers
              </p>
            </div>
          </CustomTooltip>

          <div className="font-roboto mt-auto">
            <p className="inline-block font-bold text-[24px]">${property.guidePrice}</p>
            <span> /guide price</span>
          </div>
        </div>

      </div>
    </>
  )
}

export default CarouselCard
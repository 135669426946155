const createPropertyValue = async (propertyId) => {
  try {
    const response = await fetch(`https://pending-backend-1.onrender.com/create_property_value/${propertyId}`, { method: 'POST' })
    const data = await response.json()
    return data
  } catch (err) {
    console.log(err)
  }
}

export default createPropertyValue
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoading from '../../../hooks/useLoading';
import StepContent from './step-content/StepContent';
import StepVisuals from './step-visuals/StepVisuals';
import { useUserData } from '../../../context/UserDataProvider';
import fetchPropertyId from '../../../utils/api/fetchPropertyId';
import fetchDbUser from '../../../utils/api/fetchDbUser';
import postUserProperty from '../../../utils/api/postUserProperty';
import { useClaimHomeModalError } from '../../../context/ClaimHomeModalErrorProvider';

//TODO: add ts defs
const BuyAndSellView = ({ setUserOnboarded }) => {
  const { loadingStyles, firstLoadStyles, setLoading } = useLoading();
  const navigate = useNavigate();

  // Shared states between all steps
  const [stepNumber, setStepNumber] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Step 1 state
  const [stepOneOption, setStepOneOption] = useState<string | null>(null);

  // Step 2 state
  const [stepTwoOption, setStepTwoOption] = useState<string | null>(null);

  // Step 3 state
  const [stepThreeOption, setStepThreeOption] = useState<string | null>(null);

  // Step 4 state
  const [stepFourOption, setStepFourOption] = useState<string | null>(null);

  // Step 5 state
  const [address, setAddress] = useState<undefined | string>(undefined);

  const { setUserObject, userObject, updateUserObjectProperties } = useUserData();
  const { setError, setOpen } = useClaimHomeModalError();

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (stepNumber === 1) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }

    if (stepNumber === 2) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }

    if (stepNumber === 3) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }

    if (stepNumber === 4) {
      setStepNumber((prev) => prev + 1);
      setLoading(true);
      setIsButtonDisabled(true);
    }

    if (stepNumber === 5) {
      setUserOnboarded(true);
      setUserObject((prev) => ({ ...prev, isOnboarded: true }));

      // POST the property address to BE
      const associatePropertyToUser = async (address) => {
        const clerkId = userObject.clerkUserId

        if (clerkId && address) {
          const propertyId = await fetchPropertyId(address)
          
          // Check if propertyId exists
          if (propertyId) {
            // TODO: Remove fetchDbUser -- use userObject userId instead
            const dbUser = await fetchDbUser(clerkId)
            await postUserProperty(propertyId, dbUser.id)
            await updateUserObjectProperties(dbUser.id)
          } 
          // If propertyId not found - set dashboard modal error UI and open state
          else {
            setError(true)
            setOpen(true)
          }
        }
      }
      associatePropertyToUser(address)
    }
  };

  const toggleSubmitButtonDisable = () => {
    // Step 1 validation
    if (stepNumber === 1) {
      // Ensure an option has been chosen
      if (stepOneOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    // Step 2 validation
    if (stepNumber === 2) {
      // Ensure an option has been chosen
      if (stepTwoOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    // Step 3 validation
    if (stepNumber === 3) {
      // Ensure an option has been chosen
      if (stepThreeOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    // Step 4 validation
    if (stepNumber === 4) {
      // Ensure an option has been chosen
      if (stepFourOption) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }

    // Step 5 validation
    if (stepNumber === 5) {
      console.log('address: ', address)
      // TODO: Ensure address is valid
      if (address) setIsButtonDisabled(false);
      else setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    toggleSubmitButtonDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOneOption, stepTwoOption, stepThreeOption, stepFourOption, address]);

  return (
    <>
      <StepContent
        stepNumber={stepNumber}
        stepOneOption={stepOneOption}
        stepTwoOption={stepTwoOption}
        stepThreeOption={stepThreeOption}
        stepFourOption={stepFourOption}
        address={address}
        setAddress={setAddress}
        setStepOneOption={setStepOneOption}
        setStepTwoOption={setStepTwoOption}
        setStepThreeOption={setStepThreeOption}
        setStepFourOption={setStepFourOption}
        isButtonDisabled={isButtonDisabled}
        handleButtonClick={handleButtonClick}
        toggleSubmitButtonDisable={toggleSubmitButtonDisable}
        loadingStyles={loadingStyles}
        firstLoadStyles={firstLoadStyles}
      />
      <StepVisuals stepNumber={stepNumber} loadingStyles={loadingStyles} />
    </>
  );
};

export default BuyAndSellView;
function updateUserObjectProperties(id: any) {
  throw new Error('Function not implemented.');
}


import { useEffect, useState } from 'react';
import Home from './home/Home';
import Comps from './comps/Comps';
import { useUserData } from '../../context/UserDataProvider';
import { useDashboardPage } from '../../context/DashboardPageProvider';
import { useSession } from '@clerk/clerk-react';
import postLoginEvent from '../../utils/api/postLoginEvent';
import fetchPropertyValue from '../../utils/api/fetchPropertyValue';
import addPropertyToPropertyTable from '../../utils/api/addPropertyToPropertyTable';
import createPropertyValue from '../../utils/api/createPropertyValue';

interface MenuPropertyObject {
  id: number
  fullAddress: string
  shortAddress: string
  propertyValue: null | number
}

const Dashboard = () => {
  const { userObject } = useUserData();
  const { currentPage, dashboardPages } = useDashboardPage();
  const [userProperties, setUserProperties] = useState<
  | MenuPropertyObject[]
  | []
  >([]);
  const { session } = useSession();
  

  /*
    Check if user has properties:
    - IF FOUND -- use property ids to fetch property info
  */
  useEffect(() => {
    if (
      userObject &&
      userObject.properties &&
      userObject.properties.length > 0
    ) {

      const populateLocalUserProperties = async () => {

      // Use property ids to fetch propertyValues
      if (userObject && userObject.properties) {

        let menuProperties: any[] = []
        for (let i = 0; i < userObject.properties.length; i++) {
          const currentPropertyId = userObject.properties[i].propertyid
          const propertyValueObject = await fetchPropertyValue(currentPropertyId)

          let menuPropertyObject: MenuPropertyObject = {
            id: currentPropertyId,
            fullAddress: propertyValueObject.address,
            shortAddress: propertyValueObject.address.split(',')[0],
            propertyValue: null
          }

          // check if estimated value exists
          if (propertyValueObject.estimatedvalue) {
            menuProperties.push({
              ...menuPropertyObject,
              propertyValue: propertyValueObject.estimatedvalue
            })
            continue
          }

          // check if AVM value exists
          if (propertyValueObject.automatedvaluationmodelvalue) {
            menuProperties.push({
              ...menuPropertyObject,
              propertyValue: propertyValueObject.automatedvaluationmodelvalue
            })
            continue
          }

          // check if tax assessed value exists
          if (propertyValueObject.taxassessedvalue) {
            menuProperties.push({
              ...menuPropertyObject,
              propertyValue: propertyValueObject.taxassessedvalue
            })
            continue
          }
          console.log('property value does not exist!')
          /*
            If no property value exists:
            1. Ping the create_property/{property_id} <-- this pings reapi to add a property to the Property table
            2. Ping the create_property_value/{property_id} <-- this pings reapi to add a property value to the PropertyValue table
            3. Use fetchPropertyValue again to get address and property value
            4. Create second menuObject to push
          */
          await addPropertyToPropertyTable(currentPropertyId)
          await createPropertyValue(currentPropertyId)
          const newPropertyValueData = await fetchPropertyValue(currentPropertyId)

          let menuPropertyObjectTwo = {
            id: currentPropertyId,
            fullAddress: newPropertyValueData.address,
            shortAddress: newPropertyValueData.address.split(',')[0],
            propertyValue: null
          }

          // TODO: refactor
          // check if estimated value exists
          if (newPropertyValueData.estimatedvalue) {
            menuProperties.push({
              ...menuPropertyObjectTwo,
              propertyValue: propertyValueObject.estimatedvalue
            })
            continue
          }

          // check if AVM value exists
          if (newPropertyValueData.automatedvaluationmodelvalue) {
            menuProperties.push({
              ...menuPropertyObjectTwo,
              propertyValue: propertyValueObject.automatedvaluationmodelvalue
            })
            continue
          }

          // check if tax assessed value exists
          if (newPropertyValueData.taxassessedvalue) {
            menuProperties.push({
              ...menuPropertyObjectTwo,
              propertyValue: propertyValueObject.taxassessedvalue
            })
            continue
          } 
          
          // if no property value data exists - set property to null
          menuProperties.push(menuPropertyObject)
        }

        // After loop - set local userProperties state
        setUserProperties(() => {
          return menuProperties
        })
      }}
      populateLocalUserProperties()
    }
  }, [userObject.properties]);

  useEffect(() => {
    if (
      userObject.sessionId !== null &&
      session?.id !== null &&
      userObject.sessionId !== session?.id
    )
      console.log('new session!!');
    const user = {
      clerkuserid: userObject.clerkUserId,
      emailaddress: userObject.emailAddress,
      firstname: userObject.firstName,
      lastname: userObject.lastName,
      phonenumber: userObject.phoneNumber,
      role: userObject.role,
      userid: userObject.userId,
      dbsessionid: userObject.sessionId,
      clerksessionid: session?.id,
    };
    postLoginEvent(user, session?.id);
  }, [userObject, session]);

  return (
    <div className="flex-auto flex flex-col items-center">
      {userObject.role ? (
        // HOME
        currentPage === dashboardPages.HOME ? (
          <Home userProperties={userProperties} />
        ) : 
        // COMPS
        currentPage === dashboardPages.COMPS ? (
          <Comps userProperties={userProperties} />
        ) : (
        // OFFERS
        currentPage === 'Offers' && (
          <p className="mt-[96px] text-pending-green font-lato font-bold text-[24px]">
            Coming Soon
          </p>
        ))) : (
        ''
      )}
    </div>
  );
};

export default Dashboard;

import propertyImageTwo from '../../../../assets/images/onboardingImage2.png'
import StepOneToggleVisual from './step-one-toggle-visual/StepOneToggleVisual'

const StepVisuals = ({ stepNumber, loadingStyles }) => {
  return (
    <div
      className={loadingStyles + ' flex justify-center items-center bg-[#F7F6F2] tablet-onboarding:hidden'}
    >
      {stepNumber === 1 && (
        <StepOneToggleVisual/>
      )}
      {stepNumber === 2 && (
        <img
          src={propertyImageTwo}
          alt='Hamptons property overlooking the ocean from the bedroom point of view'
          className='h-full object-cover'
        />
      )}
    </div>
  )
}

export default StepVisuals
/* 
  ClaimHomeModalErrorProvider needed because allowing being able to setError at the end of onboarding (seller, buy_sell) if user inputs an address where propertyId cannot be found
  - Needed a way to set modal state from end of onboarding if propertyId isn't found
*/ 
import {
  ReactNode,
  createContext,
  useContext,
  useState
} from 'react'

interface ClaimHomeModalErrorProps {
  error: boolean
  open: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ClaimHomeModalErrorContext = createContext<ClaimHomeModalErrorProps>({} as ClaimHomeModalErrorProps)

export const useClaimHomeModalError = () => {
  return useContext(ClaimHomeModalErrorContext)
}
// TODO: rename provider
export const ClaimHomeModalErrorProvider = ({ children } : { children: ReactNode}) => {
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)

  const valueObj = {
    error,
    open,
    setError,
    setOpen
  }

  return (
    <ClaimHomeModalErrorContext.Provider value={valueObj}>
      {children}
    </ClaimHomeModalErrorContext.Provider>
  )
}
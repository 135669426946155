const MobileMapBackButton = ({
  isMobileMapActive,
  setIsMobileMapActive
}) => {
  const mobileMapStyles = isMobileMapActive ? " tablet-property-view:opacity-100" : " "
  return (
    <div
      className={"opacity-0 w-0 h-0 tablet-property-view:absolute tablet-property-view:flex tablet-property-view:justify-center tablet-property-view:items-center tablet-property-view:w-10 tablet-property-view:h-10 tablet-property-view:bg-pending-green tablet-property-view:z-50 tablet-property-view:rounded-full tablet-property-view:mt-[17px] tablet-property-view:ml-5 tablet-property-view:cursor-pointer " + mobileMapStyles}
      onClick={() => setIsMobileMapActive(false)}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2356 16.6897L17.6063 23.9311C18.1235 24.4483 18.8994 24.4483 19.4166 23.9311C19.9339 23.4139 19.9339 22.638 19.4166 22.1208L13.0804 15.6552L19.4166 9.18973C19.9339 8.67249 19.9339 7.89663 19.4166 7.37939C19.158 7.12077 18.8994 6.99146 18.5114 6.99146C18.1235 6.99146 17.8649 7.12077 17.6063 7.37939L10.2356 14.6208C9.71835 15.2673 9.71835 16.0432 10.2356 16.6897C10.2356 16.5604 10.2356 16.5604 10.2356 16.6897Z" fill="white"/>
      </svg>
    </div>
  )
}

export default MobileMapBackButton
import Modal from "@mui/material/Modal"
import ExitIcon from "../../../../../../components/customComponents/exitIcon/ExitIcon"
import Content from "./content/Content"
import RightHandVisuals from "./right-hand-visuals/RightHandVisuals"
import { useState } from "react"

const FinishOnboardingModal = ({ modalOpen, handleModalClose }) => {
  const [stepNumber, setStepNumber] = useState(1)

  const handleContinueClick = () => setStepNumber(2)
  const handleBackButtonClick = () => {
    setStepNumber(1)
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby='Finish onboarding'
      aria-describedby='Continue to complete onboarding by setting up a meeting with our concierge team'
    >
      <div className="flex justify-center w-full pt-[42px] pointer-events-none focus:outline-none">
        <div className="relative grid grid-cols-2 bg-[#FFFCF9] max-w-[977px] max-h-[610px] min-h-full mx-[20px] rounded-[20px] overflow-hidden pointer-events-auto
          mobile-dashboard:grid-cols-1"
        >
          
          {/* EXIT "X" BUTTON */}
          <button
            className="absolute top-0 right-0 rounded-[30px] mt-6 mr-6
            mobile-dashboard:"
            onClick={handleModalClose}
          >
            <ExitIcon
              className='w-[35px] h-[35px] mobile-dashboard:h-6 mobile-dashboard:w-6'
            />
          </button>

          {/* CONTENT */}
          <Content
            stepNumber={stepNumber}
            handleContinueClick={handleContinueClick}
            handleBackButtonClick={handleBackButtonClick}
          />

          {/* VISUAL */}
          <RightHandVisuals
            stepNumber={stepNumber}
          />

        </div>
      </div>
    </Modal>
  )
}

export default FinishOnboardingModal
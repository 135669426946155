const postUserProperty = async (propertyId, userId) => {
  if (propertyId && userId) {
    try {
      const requestBody = {
        "buyerstatus": "inactive", // default
        "propertyid": propertyId,
        "sellerstatus": "invisible", // default
        "userid": userId
      }
      const ENDPOINT = `https://pending-backend-1.onrender.com/user_property/`
      const response = await fetch(ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      const data = await response.json()
      console.log('SUCCESS! POST property request response: ', data)
    } catch (err) {
      console.error(err)
    }
  }
}

export default postUserProperty
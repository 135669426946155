import CalendlyEmbed from "../calendly-embed/CalendlyEmbed"

const RightHandVisuals = ({ stepNumber }) => {
  const visualsCopy = [
    "Full property listings",
    "Dedicated advisor",
    "Curated homes",
    "Tailored travel experiences"
  ]

  const visualListItems = visualsCopy.map(copy => {
    return (
      <div key={`${copy}-modal-visual-item`}>
        <span className="inline-block w-[17px] h-[15px] bg-[#C6F9D4] rounded-full mr-[15.5px]"></span>
        <p className="inline-block font-lato text-[18px] font-semibold text-[#1A3A32]">{copy}</p>
      </div>
    )
  })
  return (
    <div className="flex justify-center items-center bg-[#F7F6F2]
      mobile-dashboard:hidden">
      <div className={stepNumber === 1 ? "opacity-100 flex flex-col gap-4 bg-white max-w-[321px] w-full max-h-[250px] h-full pl-[37px] pr-[31px] pt-[47px] pb-[61px] rounded-[25px]" :
        "opacity-0 w-0 h-0"
      }>
        {visualListItems}
      </div>
      <div className={stepNumber === 2 ? "opacity-100 h-max w-max" : "opacity-0 h-0 w-0"}>
        <CalendlyEmbed />
      </div>
    </div>
  )
}

export default RightHandVisuals
const fetchPropertyComps = async (currentCompsAddress) => {
  if (currentCompsAddress && currentCompsAddress !== '') {
    try {
      const response = await fetch(`https://pending-backend-1.onrender.com/property_comps/${currentCompsAddress}`)
      const data = await response.json()
      return JSON.parse(data)
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

export default fetchPropertyComps
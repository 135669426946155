import { Modal } from "@mui/material"
import HomeValueProp from "./homeValueProp/HomeValueProp"
import UserAddressInput from "../../../../../components/customComponents/userAddressInput/UserAddressInput"
import { useUserData } from "../../../../../context/UserDataProvider"
import { useClaimHomeModalError } from "../../../../../context/ClaimHomeModalErrorProvider"
import { useState } from "react"
import fetchPropertyId from "../../../../../utils/api/fetchPropertyId"
import postUserProperty from "../../../../../utils/api/postUserProperty"
import ExitIcon from "../../../../../components/customComponents/exitIcon/ExitIcon"

const ClaimHomeModal = () => {
  const [finalAddress, setFinalAddress] = useState<string | undefined>('')
  const [disableContinueBtn, setDisableContinueBtn] = useState(true)
  const { userObject, updateUserObjectProperties } = useUserData()
  const { error, open, setError, setOpen } = useClaimHomeModalError()
  
  const propTitleOne = 'Real-time updates'
  const propTitleTwo = 'See offers'
  const propDescriptionOne = 'Never miss an update when your home value changes'
  const propDescriptionTwo = 'Review your offers and explore selling without unwanted phone calls or sales pressure.'

  const handleClose = () => {
    setOpen(false)
    setDisableContinueBtn(true)
    setError(false)
    setFinalAddress('')
  }

  const handleAddressInputFocus = () => setDisableContinueBtn(true)
  const handleAddressInputBlur = () => setDisableContinueBtn(false)

  // Add property to userObject properties
  const handleContinueClick = async () => {
    const clerkId = userObject.clerkUserId
    const userId = userObject.userId

    // Check if finalAddress is empty
    if (finalAddress === '') setDisableContinueBtn(true)
    
    // POST the property address to BE
    // TODO: set error state if propertyId not found
    if (clerkId && finalAddress !== undefined && finalAddress !== '') {
      const propertyId = await fetchPropertyId(finalAddress)

      if (propertyId) {
        handleClose()
        await postUserProperty(propertyId, userId)
        await updateUserObjectProperties(userId) // refetches updated properties tied to user
        setFinalAddress('') // reset UserAddressInput
      }
      else {
        setError(true)
        setDisableContinueBtn(true)
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='Claim your home'
      aria-describedby='Confirm that your home address is correct'
    >
      <div className='relative top-[2%] flex justify-center mx-4 outline-none pointer-events-none'> {/* pointer-events-none: to allow modal to be closed by clicking outside of the modal */}
        <div className='relative max-w-[700px] max-h-[600px] w-full h-full bg-white py-[59px] rounded-[20px] flex justify-center pointer-events-auto 
          mobile-dashboard:justify-stretch mobile-dashboard:pt-[60px] mobile-dashboard:pb-6 mobile-dashboard:max-w-full'
        >
          <div
            className='absolute top-0 right-0 mt-6 mr-6 cursor-pointer'
            onClick={handleClose}
          >
            <ExitIcon
              className='w-8 h-8 mobile-dashboard:w-6 mobile-dashboard:h-6'
            />
          </div>
          <div className='flex flex-col items-center max-w-[392px]
            mobile-dashboard:items-start mobile-dashboard:px-6 mobile-dashboard:max-w-full mobile-dashboard:w-full'
          > 
            <h2 className='text-[#1A3A32] font-poppins font-bold text-[32px] mb-[59px]
              mobile-dashboard:text-2xl mobile-dashboard:mb-10'
            >
              Claim your home
            </h2>
            <div className='flex flex-col gap-[20px]'>
              <HomeValueProp
                propTitle={propTitleOne}
                propDescription={propDescriptionOne}
              />
              <HomeValueProp
                propTitle={propTitleTwo}
                propDescription={propDescriptionTwo}
              />
            </div>
            <div className='relative w-full mt-[27px] mb-[38px]'>
              <UserAddressInput
                address={finalAddress}
                setAddress={setFinalAddress}
                onFocusHandler={handleAddressInputFocus}
                onBlurHandler={handleAddressInputBlur}
              />
              {/* ERROR COPY */}
              {error && (
                <p className='absolute text-[14px] text-red-600'>
                  Sorry, that address not found. Please enter another address.
                </p>
              )}
            </div>
            <div className='flex flex-col w-full items-center'>
              <button
                disabled={disableContinueBtn}
                className='font-jakarta-sans font-semibold bg-[#1A3A32] rounded-[8px] text-white max-w-[314px] w-full py-[14px] disabled:bg-[#8B9B97] shadow-[-1px_3px_5px_1px_rgba(0,0,0,0.32)]
                  mobile-dashboard:max-w-full'
                onClick={handleContinueClick}
              >
                Continue
              </button>
              <button
                className='font-jakarta-sans font-semibold rounded-[30px] max-w-[314px] w-full py-[14px]
                  mobile-dashboard:max-w-full'
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ClaimHomeModal
import { useState, useEffect } from 'react';
import CodeInputs from './code-inputs/CodeInputs';
import ErrorMessage from './error-message/ErrorMessage';
import VerifyButton from './verify-button/VerifyButton';
import ResendCodeButton from './resend-code-button/ResendCodeButton';
import { useSignIn } from '@clerk/clerk-react';

import { User } from '../LogInContent';

interface VerificationContentProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const VerificationContent = ({ user, setUser }: VerificationContentProps) => {
  const [userInputtedCode, setUserInputtedCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [userCode, setUserCode] = useState('');
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isLoaded, signIn } = useSignIn();

  const SMS_INPUT_ID_ONE = 'sms-num-1';
  const SMS_INPUT_ID_TWO = 'sms-num-2';
  const SMS_INPUT_ID_THREE = 'sms-num-3';
  const SMS_INPUT_ID_FOUR = 'sms-num-4';
  const SMS_INPUT_ID_FIVE = 'sms-num-5';
  const SMS_INPUT_ID_SIX = 'sms-num-6';

  const checkAllInputsPopulated = () => !userInputtedCode.includes('');

  const enableVerifyButton = () => setIsVerifyButtonDisabled(false);

  const disableVerifyButton = () => setIsVerifyButtonDisabled(true);

  const focusNextInput = (e) => {
    // Focus next input if current value is not empty string
    if (e.target.value !== '') {
      if (e.target.id === SMS_INPUT_ID_ONE) {
        const secondInput = document.getElementById(SMS_INPUT_ID_TWO);
        secondInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_TWO) {
        const thirdInput = document.getElementById(SMS_INPUT_ID_THREE);
        thirdInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_THREE) {
        const fourthInput = document.getElementById(SMS_INPUT_ID_FOUR);
        fourthInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_FOUR) {
        const fifthInput = document.getElementById(SMS_INPUT_ID_FIVE);
        fifthInput?.focus();
      }
      if (e.target.id === SMS_INPUT_ID_FIVE) {
        const sixthInput = document.getElementById(SMS_INPUT_ID_SIX);
        sixthInput?.focus();
      }
    }
  };

  const focusPrevInput = (e) => {
    // Focus prev input if current value is erased with backspace
    if (e.key === 'Backspace' && e.target) {
      let priorInputNum: number = 0

      for (let i = 0; i < userInputtedCode.length; i++) {
        if (userInputtedCode[i] === '') {
          priorInputNum = i
          break
        }
        if (i === userInputtedCode.length - 1) {
          priorInputNum = i
        }
      }

      if (priorInputNum !== 0) {
        const input = document.querySelector(`#sms-num-${priorInputNum}`) as HTMLInputElement
        input?.focus()
      }
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target && e.target.id && (e.target.value || e.target.value === '')) {
      // SMS set code 1
      if (e.target.id === SMS_INPUT_ID_ONE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[0] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        // focusPrevInput(e)
        return;
      }

      // SMS set code 2
      if (e.target.id === SMS_INPUT_ID_TWO) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[1] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 3
      if (e.target.id === SMS_INPUT_ID_THREE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[2] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 4
      if (e.target.id === SMS_INPUT_ID_FOUR) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[3] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 5
      if (e.target.id === SMS_INPUT_ID_FIVE) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[4] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }

      // SMS set code 6
      if (e.target.id === SMS_INPUT_ID_SIX) {
        setUserInputtedCode((prevArr) => {
          const newArr = [...prevArr];
          newArr[5] = e.target.value;
          return newArr;
        });
        focusNextInput(e);
        return;
      }
    }
  };

  // Checks if all inputs are populated - if so, enable the verify button
  useEffect(() => {
    const isTrue = checkAllInputsPopulated();
    if (isTrue) enableVerifyButton();
    else {
      setHasError(false)
      disableVerifyButton();
    }
  }, [userInputtedCode]);

  // Concatenate userInputtedCode array into a single string
  useEffect(() => {
    setUserCode(userInputtedCode.join(''));
  }, [userInputtedCode]);

  //TODO: make sendCode a reusable clerk function
  const resendCode = async () => {
    if (!isLoaded) {
      console.log('loading...');
      return;
    }

    try {
      //send code via mobile
      if (user.sendCodeViaSMS) {
        const { supportedFirstFactors } = await signIn!.create({
          identifier: user.phoneNumber,
        });

        const firstPhoneFactor: any = supportedFirstFactors.find((factor) => {
          return factor.strategy === 'phone_code';
        });

        if (!firstPhoneFactor) {
          throw new Error('No phone factor found');
        }

        const { phoneNumberId } = firstPhoneFactor;

        await signIn!.prepareFirstFactor({
          strategy: 'phone_code',
          phoneNumberId,
        });
      }
      //send code via email
      else if (!user.sendCodeViaSMS) {
        const { supportedFirstFactors } = await signIn!.create({
          identifier: user.email,
        });

        const firstEmailFactor: any = supportedFirstFactors.find((factor) => {
          return factor.strategy === 'email_code';
        });

        if (!firstEmailFactor) {
          throw new Error('No phone factor found');
        }

        const { emailAddressId } = firstEmailFactor;

        await signIn!.prepareFirstFactor({
          strategy: 'email_code',
          emailAddressId,
        });
      }

      setUser((prevUser) => ({
        ...prevUser,
        isUserLoggedIn: true,
      }));
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
    }
  };

  return (
    <div>
      <CodeInputs
        error={hasError}
        userInputtedCode={userInputtedCode}
        setUserInputtedCode={setUserInputtedCode}
        onChangeHandler={onChangeHandler}
        onKeyDownHandler={focusPrevInput}
        SMS_INPUT_ID_ONE={SMS_INPUT_ID_ONE}
        SMS_INPUT_ID_TWO={SMS_INPUT_ID_TWO}
        SMS_INPUT_ID_THREE={SMS_INPUT_ID_THREE}
        SMS_INPUT_ID_FOUR={SMS_INPUT_ID_FOUR}
        SMS_INPUT_ID_FIVE={SMS_INPUT_ID_FIVE}
        SMS_INPUT_ID_SIX={SMS_INPUT_ID_SIX}
      />
      <div className="my-[14px]">
        <ErrorMessage error={hasError} />
      </div>
      <div className="grid grid-rows-2 gap-[21px] max-w-[312px]
        mobile-signup:max-w-full"
      >
        <>
          <VerifyButton
            userCode={userCode}
            isVerifyButtonDisabled={isVerifyButtonDisabled}
            loading={isLoading}
            setLoading={setIsLoading}
            setHasError={setHasError}
            user={user}
            setUser={setUser}
          />
          <ResendCodeButton resendCode={resendCode} />
        </>
      </div>
    </div>
  );
};

export default VerificationContent;

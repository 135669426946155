interface ProgressBarProps {
  stepNumber: number
}

const ProgressBar = ({ stepNumber } : ProgressBarProps) => {
  return (
    <div className='flex items-end justify-end gap-2 w-full'>
      <div className={`w-2 h-2 rounded-full ${stepNumber === 1 ? 'bg-pending-green' : 'bg-[#E4E4E4]'} transition-all`}></div>
      <div className={`w-2 h-2 rounded-full ${stepNumber === 2 ? 'bg-pending-green' : 'bg-[#E4E4E4]'} transition-all`}></div>
      <div className={`w-2 h-2 rounded-full ${stepNumber === 3 ? 'bg-pending-green' : 'bg-[#E4E4E4]'} transition-all`}></div>
      <div className={`w-2 h-2 rounded-full ${stepNumber === 4 ? 'bg-pending-green' : 'bg-[#E4E4E4]'} transition-all`}></div>
      <div className={`w-2 h-2 rounded-full ${stepNumber === 5 ? 'bg-pending-green' : 'bg-[#E4E4E4]'} transition-all`}></div>
    </div>
  )
}

export default ProgressBar
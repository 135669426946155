import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useUser, useSession } from '@clerk/clerk-react';
import fetchDbUserProperties from '../utils/api/fetchDbUserProperties';
import fetchDbUser from '../utils/api/fetchDbUser';

interface UserDataObject {
  clerkUserId: string | null;
  userId: number | null;
  emailAddress: string | null;
  firstName: string | null;
  lastName: string | null;
  properties:
    | {
        userid: number;
        propertyid: number;
        buyerstatus: string;
        sellerstatus: string; // TODO: set enumerated types
      }[]
    | null;
  role: 'seller' | 'buyer' | 'buy_sell' | null;
  phoneNumber: string | null;
  isOnboarded: boolean;
  accountCreated: boolean;
  sessionId: string | null;
}

interface ValueObjectProps {
  userObject: UserDataObject;
  setUserObject: React.Dispatch<React.SetStateAction<UserDataObject>>;
  updateUserObjectProperties: (userId: any) => Promise<void>;
}

const UserDataContext = createContext<ValueObjectProps>({} as ValueObjectProps);

export const useUserData = () => {
  return useContext(UserDataContext);
};

export const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const [userObject, setUserObject] = useState<UserDataObject>({
    clerkUserId: null,
    userId: null,
    emailAddress: null,
    firstName: null,
    lastName: null,
    properties: null,
    role: null,
    phoneNumber: null,
    isOnboarded: false,
    accountCreated: false,
    sessionId: null,
  });

  const { isSignedIn, user } = useUser();
  const { session } = useSession();

  //NOTE TO SELF: this triggers as soon as user hits /onboarding
  // - user if not found in DB yet until after /account-created
  // this code doesn't run again once account is created....yet
  const updateUserObjectProperties = async (userId) => {
    const data = await fetchDbUserProperties(userId);
    console.log('newly fetched properties: ', data);
    if (!data.detail)
      setUserObject((prev) => ({
        ...prev,
        properties: data,
      }));
  };

  useEffect(() => {
    const fetchUser = async () => {
      //should only run when accountCreated is true or if the user already exists in db on login
      if (isSignedIn) {
        const dBUser = await fetchDbUser(user.id);
        if (dBUser.id && session) {
          // console.log(session.id);
          const userProperties = await fetchDbUserProperties(dBUser.id);
          setUserObject((prev) => ({
            ...prev,
            clerkUserId: dBUser.clerkuserid,
            emailAddress: dBUser.emailaddress,
            firstName: dBUser.firstname,
            userId: dBUser.id,
            lastName: dBUser.lastname,
            phoneNumber: dBUser.phonenumber,
            role: dBUser.role,
            sessionId: dBUser.sessionid,
            properties:
              userProperties.detail === 'No properties found for the user'
                ? []
                : userProperties,
          }));
        }
      }
    };
    fetchUser();
  }, [isSignedIn, user?.id]);

  const valueObject = {
    userObject,
    setUserObject,
    updateUserObjectProperties,
  };

  return (
    <UserDataContext.Provider value={valueObject}>
      {children}
    </UserDataContext.Provider>
  );
};

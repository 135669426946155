import StaticLoader from '../../../../../../components/customComponents/staticLoader/StaticLoader';
import { User } from '../../RegistrationContent';
import { useNavigate } from 'react-router-dom';
import { useSignUp, useUser, useSession } from '@clerk/clerk-react';
import { useUserData } from '../../../../../../context/UserDataProvider';
import createUser from '../../../../../../utils/api/createUser';

type UserInfo = Omit<User, 'fullName' | 'address'>;

interface VerifyButtonProps {
  userCode: string;
  isVerifyButtonDisabled: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidResponse?: React.Dispatch<React.SetStateAction<boolean | null>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserInfo;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const VerifyButton = ({
  userCode,
  isVerifyButtonDisabled,
  loading,
  setLoading,
  setHasError,
  user,
  setUser,
}: VerifyButtonProps) => {
  const { isLoaded, signUp, setActive } = useSignUp();

  const { userObject, setUserObject } = useUserData();

  const navigate = useNavigate();

  const VERIFY_TEXT = 'Verify';
  const VERIFY_LOADING_TEXT = 'Verifying...';

  const verifyCode = async () => {
    setLoading(true);
    if (!isLoaded) {
      return;
    }

    try {
      let completeSignUp;
      //determine if sign-up should be attempted through email or phone verification
      if (user.sendCodeViaSMS) {
        completeSignUp = await signUp.attemptPhoneNumberVerification({
          code: userCode,
        });
      }

      // else {
      //   completeSignUp = await signUp.attemptEmailAddressVerification({
      //     code: userCode,
      //   });
      // }

      if (completeSignUp.status === 'complete') {
        setUser((prevUser) => ({
          ...prevUser,
          isUserVerified: true,
          isUserSignedUp: true,
        }));

        //TODO: add typing here
        const formattedUser = {
          clerkuserid: signUp.createdUserId,
          emailaddress: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          phonenumber: user.phoneNumber,
          role: null,
          sessionid: completeSignUp.createdSessionId,
        };

        await createUser(formattedUser);

        await setActive({ session: completeSignUp.createdSessionId });

        navigate('/onboarding');
      }
      if (completeSignUp.status !== 'complete') {
        console.log(JSON.stringify(completeSignUp, null, 2));
        return;
      }
    } catch (err: any) {
      console.error(JSON.stringify(err));
      setLoading(false);
      setHasError(true);
      return;
    }
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      await verifyCode();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button
      className="flex justify-center align-center gap-[5px] text-white font-jakarta-sans font-semibold bg-[#1A3A32] rounded-lg py-[14px] disabled:opacity-50"
      onClick={handleClick}
      disabled={isVerifyButtonDisabled}
    >
      {loading && (
        <>
          <span className="h-full">
            <StaticLoader />
          </span>
          <span>{VERIFY_LOADING_TEXT}</span>
        </>
      )}
      {!loading && VERIFY_TEXT}
    </button>
  );
};

export default VerifyButton;

import { useEffect } from "react"

const CalendlyEmbed = ({
  minWidth = 432,
  height = 459,
  url = "https://calendly.com/trypending/30min?hide_event_type_details=1&hide_gdpr_banner=1",
  ...props
}) => {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head?.appendChild(script)
  }, [])

  return (
    <div
      className="calendly-inline-widget"
      data-url={url}
      style={{ minWidth, height }}
      {...props}
    ></div>
  )
}

export default CalendlyEmbed
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import WhiteRightArrowSVG from '../../../assets/icons/whiteRightArrow.svg'

interface ClaimHomeButtonProps {
  address: string | undefined
  setAddress: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ClaimHomeButton = ({ address } : ClaimHomeButtonProps) => {
  const [finalAddress, setFinalAddress] = useState<string | undefined>('')
  const navigate = useNavigate()

  // OLD: On initial render, populate input with address from PropertyView
  // TODO: NEW - update state in global state that holds user's inputted address
  useEffect(() => {
    if (address) setFinalAddress(address)
  }, [address])

  return (
    <>
      <button
        className='flex justify-between items-center text-left text-white bg-[#1A3A32] rounded-[10px] px-[30px] pt-[23px] pb-[23px]'
        onClick={() => navigate('/sign-up')}
      >
        <span className='block'>
          <p className='font-jakarta-sans font-semibold text-[24px]'>
            Own this home?
          </p> 
          <p className='font-lato font-light text-[15px] mt-[-5px] ml-[2px]'>
            Claim your ownership
          </p>
        </span>
        <img
          className='w-[15px] h-[15px]'
          src={WhiteRightArrowSVG}
          alt=''
        />
      </button>
    </>
  )
}

export default ClaimHomeButton
import React from 'react';
import Navbar from './navbar/Navbar';
import { Outlet } from 'react-router-dom';

const HomeLayout = () => {
  return (
    <>
      <Navbar />
      <main className="flex flex-auto py-[50px] min-h-[800px] bg-[#FFFCF9] mobile-home:pb-0">
        <Outlet />
      </main>
    </>
  );
};

export default HomeLayout;

import valuePropImageOne from "../../../assets/images/howItWorksImage1.png"
import valuePropImageTwo from "../../../assets/images/howItWorksImage2.png"
import valuePropImageThree from "../../../assets/images/howItWorksImage3.png"
import valuePropImageFour from "../../../assets/images/howItWorksImage4.png"

const copyAndImages = [
  {
    valuePropHeading: "Track home values in real-time.",
    valuePropParagraph: "See dollar accurate value changes to your residential portfolio.",
    image: valuePropImageOne,
    alt: "Valuation chart graphic that shows a home value increasing by $75k in the last week."
  },
  {
    valuePropHeading: "Discreet offers from vetted buyers.",
    valuePropParagraph: "Homeowners can opt to receive purchase offers from Pending’s network of pre-vetted buyers.",
    image: valuePropImageTwo,
    alt: "Graphic showing an 'Open to purchase' toggle toggled to 'Yes'."
  },
  {
    valuePropHeading: "Private home tours.",
    valuePropParagraph: "If a buyer’s indicative offer interests an owner, we will contact buyer to arrange the viewing.",
    image: valuePropImageThree,
    alt: "Graphic of modal pop-up showing a new tour request from a buyer whose identity and net worth are verified and their indicative offer is $6M to $6.2M."
  },
  {
    valuePropHeading: "Brokerage services.",
    valuePropParagraph: "Our brokerage platform helps facilitate transactions, providing users with an in-house licensed advisor as a single point of contact.",
    image: valuePropImageFour,
    alt: "Graphic of stages of a transaction - Step 1. Offer and Acceptance, Step 2. Purchase and Sale, Step 3. Closing."
  }
]

const ValuePropsSection = () => {
  const valuePropCards = copyAndImages.map(obj => {
    return (
      <div
        key={`${obj.valuePropHeading}-how-it-works-value-props`}
        className="grid grid-cols-[0.62fr_1fr] gap-[120px] max-w-[1150px]
          mobile-how-it-works:flex mobile-how-it-works:flex-col mobile-how-it-works:gap-[34px]"
      >
        <div className="text-[#474747]">
          <h3 className="font-semibold text-[44px] mb-[14px] leading-[120%]
            mobile-how-it-works:text-[32px] mobile-how-it-works:max-w-[290px]"
          >
            {obj.valuePropHeading}
          </h3>
          <p className="text-[22px]
            mobile-how-it-works:text-[20px]"
          >
            {obj.valuePropParagraph}
          </p>
        </div>
        <div>
          <img
            src={obj.image}
            alt={obj.alt}
          />
        </div>
      </div>
    )
  })

  return (
      <div className="flex flex-col gap-[148px] items-center pt-[197px] pr-[120px] pl-[79px] pb-[107px]
        mobile-how-it-works:px-5 mobile-how-it-works:pt-[70px] mobile-how-it-works:pb-[113px]"
      >
        {valuePropCards}
      </div>
  )
}

export default ValuePropsSection
import WhiteRightArrowSVG from '../../../assets/icons/whiteRightArrow.svg';
import { useEffect, useState } from 'react';

interface SubmitOfferButtonProps {
  address: string | undefined;
}

const SubmitOfferButton = ({ address }: SubmitOfferButtonProps) => {
  const [addressParams, setAddressParams] = useState<string | null>(null);

  useEffect(() => {
    if (address) setAddressParams(encodeURIComponent(address));
  }, [address]);

  return (
    <a
      className="flex justify-between items-center text-left text-white bg-[#1A3A32] rounded-[10px] px-[30px] pt-[23px] pb-[23px]"
      href={`mailto:advisor@trypending.com?subject=Offer%20Submission&body=${addressParams}`}
    >
      <span className="block">
        <p className="font-jakarta-sans font-semibold text-[24px]">
          Submit offer
        </p>
        <p className="font-lato font-light text-[15px] mt-[-5px] ml-[2px]">
          For interested buyers
        </p>
      </span>
      <img className="w-[15px] h-[15px]" src={WhiteRightArrowSVG} alt="" />
    </a>
  );
};

export default SubmitOfferButton;

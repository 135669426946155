const TestimonialStars = () => {
return (
  <svg width="163" height="26" viewBox="0 0 163 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 20.8958L21.843 26L19.629 16.38L27 9.90737L17.2935 9.07263L13.5 0L9.7065 9.07263L0 9.90737L7.371 16.38L5.157 26L13.5 20.8958Z" fill="#FFD643"/>
    <path d="M47.5 20.8958L55.843 26L53.629 16.38L61 9.90737L51.2935 9.07263L47.5 0L43.7065 9.07263L34 9.90737L41.371 16.38L39.157 26L47.5 20.8958Z" fill="#FFD643"/>
    <path d="M81.5 20.8958L89.843 26L87.629 16.38L95 9.90737L85.2935 9.07263L81.5 0L77.7065 9.07263L68 9.90737L75.371 16.38L73.157 26L81.5 20.8958Z" fill="#FFD643"/>
    <path d="M115.5 20.8958L123.843 26L121.629 16.38L129 9.90737L119.294 9.07263L115.5 0L111.707 9.07263L102 9.90737L109.371 16.38L107.157 26L115.5 20.8958Z" fill="#FFD643"/>
    <path d="M149.5 20.8958L157.843 26L155.629 16.38L163 9.90737L153.294 9.07263L149.5 0L145.707 9.07263L136 9.90737L143.371 16.38L141.157 26L149.5 20.8958Z" fill="#FFD643"/>
  </svg>
)
}

const TestimonialVisual = () => {
  return (
    <div className='relative grid grid-cols-[minmax(0px,_617px)] grid-rows-[minmax(0px,_201px)] bg-white rounded-[25px] m-10 bottom-[4rem]'>
      <div className='px-[35px] py-[45px]'>
        <p className='font-lato text-[#6B7773] text-[22px] font-[600] leading-[33px]'>
          "It's like a <span className='text-black font-[700]'>one-stop-shop</span> for my home intelligence and market data"
        </p>
        <div className='mt-[10px]'>
          <TestimonialStars/>
        </div>
      </div>
    </div> 
  )
}

export default TestimonialVisual
import { useEffect } from 'react';
import UserAddressInput from '../../../../../components/customComponents/userAddressInput/UserAddressInput';

interface StepTwoFormProps {
  toggleSubmitButtonDisable: () => void;
  loadingStyles: string;
  address: string | undefined;
  setAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const StepTwoForm = ({
  toggleSubmitButtonDisable,
  loadingStyles,
  address,
  setAddress,
}: StepTwoFormProps) => {
  // console.log(address)
  useEffect(() => {
    if (address !== undefined) toggleSubmitButtonDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <div
      className={
        loadingStyles +
        ' font-lato grid grid-cols-1 grid-rows-[minmax(0px,_42px)_minmax(0px,_42px)] gap-x-[18px] gap-y-6 max-w-[388px] mb-10 mt-[42px] w-full tablet-onboarding:grid-cols-1 tablet-onboarding:grid-rows-[repeat(4,_minmax(0px,_42px))]'
      }
    >
      <div className="relative w-full">
        <UserAddressInput
          address={address}
          setAddress={setAddress}
          placeholderText="Enter property address"
          isAddressAutofillOn={true}
        />
      </div>
    </div>
  );
};

export default StepTwoForm;
